import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import LocationMap from './LocationMap';
import * as ETVConstants from '../ETVConstants';
import { InputLabel } from '@mui/material';
import { ResetTv, RestartAlt, Upload } from '@mui/icons-material';

class LayoutDesigner extends React.Component {

    constructor(props) {
      super(props);
      this.onMouseOut = this.onMouseOut.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      this.onMouseMove = this.onMouseMove.bind(this);
      this.setLocationGPS = this.setLocationGPS.bind(this);
      this.loadBG = this.loadBG.bind(this);
      this.readerBGImageLoaded = this.readerBGImageLoaded.bind(this);
      this.bgImageLoaded = this.bgImageLoaded.bind(this);
    }

    state = {
      border: 20,
      items:[],
      canvas:undefined,
      ctx:undefined,
      cWidth:600,
      cHeight:450,
      canvasResolution:1.33334,
      needRedraw: true,
      raster_size: 10,
      startPos: undefined,
      endPos: undefined,
      currentMousePos: undefined,
      channelLayout: "4_3",
      curChannel:0,
      curEmpty:0,
      curStreaming:0,
      curElevFrag:0,
      curTimeFrag1Lines:0,
      curTimeFrag2Lines:0,
      curTimeFrag3Lines:0,
      qr_code:'',
      curQRCodeFrag:0,
      curArrowFrag:0,
      curDoorFrag:0,
      nof_lines:3,
      curFloorNrFrag:0,
      curNextStopFrag:0,
      curFloorDirFrag:0,
      curFloorDirNoScrollingFrag:0,
      curWeatherFrag:0,
      curWeather5Frag:0,
      curFloorPicFrag:0,
      curTripCounterFrag:0,
      curStaticTxtFrag:0,
      static_txt_line_1:'',
      finalImage:undefined,
      finalConfigFile:undefined,
      isSubmitting:false,
      channel_type:'content',
      streaming_ip:'',
      streaming_backup:'',
      streaming_ip_supervision:'',
      clock_format:'hh:mm a',
      clock_date_format:'1',
      widget_text_color:'',
      widget_background:'full_transparent',
      clock_locale:'en',
      weather_city_lat:'',
      weather_city_lgt:'',
      weather_city_name:'',
      error_message:undefined,
      bg_color: "#000000",
      isLandscape: true,
      debug: false,
      canvas_bg:undefined,
      canvas_img:undefined,
    }

    componentDidMount() {
      const canvas = this.refs.canvas;
      const ctx = canvas.getContext("2d")
      //const img = this.refs.image
      this.setState({ctx:ctx, canvas:canvas});
      this.drawCanvasContent();
    }

    componentDidUpdate() {
      if(this.state.needRedraw) {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        //const img = this.refs.image;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.setState({ctx:ctx, canvas:canvas});
        this.drawCanvasContent();
      }
      // wait until final image is ready
      if(this.state.finalImage && !this.state.isSubmitting) {
        //console.log("submitting layout");
        this.submitLayout();
      }
    }

    // check if the rectangle pos0 -> pos1 intersects with other rectangles
    intersectWithOthers( pos0, pos1) {
      if(!this.state.items || this.state.items.length===0) return false;
      //console.log("checking intersetc.....");
      for(var i=0; i<this.state.items.length; i++) {
        var item = this.state.items[i];
        var channelPoints = this.getChannelPoints(item);
        for(var k=0; k<4; k++) {
          if (this.isInside(pos0,pos1,channelPoints[k])) return true;
        }
      }
      return false;
    }

    isInside( pos0, pos1, point) {
      //console.log("xxxxxxxxxxx point="+point);
      return (point.x > pos0.x) && (point.x < pos1.x) && (point.y > pos0.y) && (point.y < pos1.y);
    }

    checkConsistencyChannel( channel) {
      return (channel.startPos.x < channel.endPos.x) && (channel.startPos.y < channel.endPos.y);
    }

    createChannel( name, startPos, endPos, parameter) {
      //console.log("createChannel, name="+name);
      if(startPos.x > endPos.x) {
        var temp = startPos;
        startPos = endPos;
        endPos = temp;
      }

      if(!this.intersectWithOthers(startPos,endPos)) {
          var o = {};
          o.name = name;
          if (o.name.startsWith("content")) {
            var curChannel = this.state.curChannel;
            o.name = o.name+curChannel;
            o.id = "Channel"+curChannel+"Fragment";
            o.channelColor = this.getChannelColor(curChannel);
            curChannel++;
            this.setState({curChannel:curChannel});
            if(curChannel>4) {
              this.setState({channel_type:''});
            }

          }else if(o.name.startsWith("empty")) {
            var curEmpty = this.state.curEmpty;
            /// state has not broadcastet, therefore use parameter field!!!
            o.id = "EmptyChannel_"+parameter;
            if(this.state.debug) {
              o.channelColor = "#FF44FF";
              o.name = o.id;
            }else{
              o.channelColor = "#000000";
            }
            curEmpty++;
            this.setState({curEmpty:curEmpty});

          }else if(o.name.startsWith("streaming")){
            var curStreaming = this.state.curStreaming;
            if(!this.state.streaming_ip) {
              this.showErrorMessage("please enter the streaming address");
              return undefined;
            }
            o.name = o.name+" ("+this.state.streaming_ip+")";
            o.id = "StreamingFragment_"+curStreaming;
            o.channelColor = "#b32758";
            curStreaming++;
            o.streaming_ip = this.state.streaming_ip;
            o.streaming_backup = this.state.streaming_backup;
            o.streaming_ip_supervision = this.state.streaming_ip_supervision;
            o.parameter = parameter;
            this.setState({curStreaming:curStreaming});

          }else if(o.name.startsWith("elevator_landscape")){
            //console.log("creating elevator frame");
            var curElevFrag = this.state.curElevFrag;
            o.name = o.name+" "+curElevFrag;
            o.id = "ElevatorArrowFragmentLandscape_"+curElevFrag;
            o.channelColor = "#ccdd33";
            curElevFrag++;
            this.setState({curElevFrag:curElevFrag});
            if(curElevFrag>1) {
              this.setState({channel_type:'content'});
            }

          }else if(o.name.startsWith("elevator_portrait")){
            //console.log("creating elevator frame");
            var curElevFrag2 = this.state.curElevFrag;
            o.name = o.name+" "+curElevFrag2;
            o.id = "ElevatorArrowFragmentPortrait_"+curElevFrag2;
            o.channelColor = "#ccdd33";
            curElevFrag2++;
            this.setState({curElevFrag:curElevFrag2});
            if(curElevFrag2>1) {
              this.setState({channel_type:'content'});
            }

          }else if(o.name.startsWith("elevator_small")){
            //console.log("creating elevator small fragement");
            var curElevFrag3 = this.state.curElevFrag;
            o.name = o.name+" "+curElevFrag3;
            o.id = "ElevatorArrowFragmentSmall_"+curElevFrag3;
            o.channelColor = "#ccdd33";
            curElevFrag3++;
            this.setState({curElevFrag:curElevFrag3});
            if(curElevFrag3>1) {
              this.setState({channel_type:'content'});
            }

          }else if(o.name.startsWith("qrcode")){
            var curQR = this.state.qr_code;
            if(!this.state.qr_code) {
              this.showErrorMessage("please enter a qr code in the text field");
              return undefined;
            }
            var curQRCodeFrag = this.state.curQRCodeFrag;
            o.id = "QRCodeFragment_"+curQRCodeFrag;
            o.qr_code = curQR;
            o.channelColor = "#535353";
            o.widget_text_color = this.state.widget_text_color;
            o.widget_background = this.state.widget_background;
            curQRCodeFrag++;
            this.setState({curQRCodeFrag:curQRCodeFrag});

          }else if(o.name.startsWith("arrow")){
            var curArrowFrag1 = this.state.curArrowFrag;
            o.id = "ArrowFragment_"+curArrowFrag1;
            o.channelColor = "#442299";
            o.widget_background = this.state.widget_background;
            curArrowFrag1++;
            this.setState({curArrowFrag:curArrowFrag1});

          }else if(o.name.startsWith("door")){
            var curDoorFrag1 = this.state.curDoorFrag;
            o.id = "DoorFragment_"+curDoorFrag1;
            o.channelColor = "#992299";
            o.widget_background = this.state.widget_background;
            curDoorFrag1++;
            this.setState({curDoorFrag:curDoorFrag1});

          }else if(o.name.startsWith("floornumber")){
            var curFloorNrFrag1 = this.state.curFloorNrFrag;
            o.id = "FloorNumberFragment_"+curFloorNrFrag1;
            o.channelColor = "#992299";
            o.widget_background = this.state.widget_background;
            curFloorNrFrag1++;
            this.setState({curFloorNrFrag:curFloorNrFrag1});

          }else if(o.name.startsWith("next_stop")){
            var curNextStopFrag1 = this.state.curNextStopFrag;
            o.id = "NextStopFragment_"+curNextStopFrag1;
            o.channelColor = "#229999";
            o.widget_background = this.state.widget_background;
            curNextStopFrag1++;
            this.setState({curNextStopFrag:curNextStopFrag1});

          }else if(o.name.startsWith("floor_directory_noscrolling")){
            var curFloorDirNoScrollingFrag1 = this.state.curFloorDirNoScrollingFrag;
            o.id = "FloorDirectoryNoScrollingFragment_"+curFloorDirNoScrollingFrag1;
            o.channelColor = "#660033";
            o.nof_lines = this.state.nof_lines;
            o.widget_text_color = this.state.widget_text_color;
            o.widget_background = this.state.widget_background;
            curFloorDirNoScrollingFrag1++;
            this.setState({curFloorDirNoScrollingFrag:curFloorDirNoScrollingFrag1});

          }else if(o.name.startsWith("floor_directory")){
            var curFloorDirFrag1 = this.state.curFloorDirFrag;
            o.id = "FloorDirectoryFragment_"+curFloorDirFrag1;
            o.widget_text_color = this.state.widget_text_color;
            o.nof_lines = this.state.nof_lines;
            o.channelColor = "#66FF33";
            o.widget_background = this.state.widget_background;
            curFloorDirFrag1++;
            this.setState({curFloorDirFrag:curFloorDirFrag1});

          }else if(o.name.startsWith("time1")){
            var curTimeFrag1Line = this.state.curTimeFrag1Lines;
            o.id = "TimeFragment1Lines_"+curTimeFrag1Line;
            o.channelColor = "#777777";
            o.clock_format = this.state.clock_format;
            o.clock_date_format = this.state.clock_date_format;
            o.widget_text_color = this.state.widget_text_color;
            o.widget_background = this.state.widget_background;
            o.clock_locale = this.state.clock_locale;
            curTimeFrag1Line++;
            this.setState({curTimeFrag1Lines:curTimeFrag1Line});

          }else if(o.name.startsWith("time2")){
            var curTimeFrag2Lines = this.state.curTimeFrag2Lines;
            o.id = "TimeFragment2Lines_"+curTimeFrag2Lines;
            o.channelColor = "#777777";
            o.clock_format = this.state.clock_format;
            o.clock_date_format = this.state.clock_date_format;
            o.widget_text_color = this.state.widget_text_color;
            o.widget_background = this.state.widget_background;
            o.clock_locale = this.state.clock_locale;
            curTimeFrag2Lines++;
            this.setState({curTimeFrag2Lines:curTimeFrag2Lines});

          }else if(o.name.startsWith("time3")){
            var curTimeFrag3Lines = this.state.curTimeFrag3Lines;
            o.id = "TimeFragment3Lines_"+curTimeFrag3Lines;
            o.channelColor = "#777777";
            o.clock_format = this.state.clock_format;
            o.clock_date_format = this.state.clock_date_format;
            o.widget_text_color = this.state.widget_text_color;
            o.widget_background = this.state.widget_background;
            o.clock_locale = this.state.clock_locale;
            curTimeFrag3Lines++;
            this.setState({curTimeFrag3Lines:curTimeFrag3Lines});

          }else if(o.name.startsWith("weather5")) {
            var curWeather5Frag = this.state.curWeather5Frag;
            o.id = "Weather5Fragment_"+curWeather5Frag;
            o.channelColor = "#f5b042";
            o.widget_background = this.state.widget_background;
            o.weather_city_lat = this.state.weather_city_lat;
            o.weather_city_lgt = this.state.weather_city_lgt;
            o.weather_city_name = this.state.weather_city_name;
            o.widget_text_color = this.state.widget_text_color;
            curWeather5Frag++;
            this.setState({curWeather5Frag:curWeather5Frag});

          }else if(o.name.startsWith("weather")) {
            var curWeatherFrag = this.state.curWeatherFrag;
            o.id = "WeatherFragment_"+curWeatherFrag;
            o.channelColor = "#f5b042";
            o.weather_city_lat = this.state.weather_city_lat;
            o.weather_city_lgt = this.state.weather_city_lgt;
            o.weather_city_name = this.state.weather_city_name;
            o.widget_background = this.state.widget_background;
            o.widget_text_color = this.state.widget_text_color;
            curWeatherFrag++;
            this.setState({curWeatherFrag:curWeatherFrag});

          }else if(o.name.startsWith("floorpics")) {
            var curFloorPicFrag = this.state.curFloorPicFrag;
            o.id = "ElevatorFloorPicFragment_"+curFloorPicFrag;
            o.channelColor = "#21d1cb";
            curFloorPicFrag++;
            this.setState({curFloorPicFrag:curFloorPicFrag});

          }else if(o.name.startsWith("tripcounter")) {
            var curTripCounterFrag = this.state.curTripCounterFrag;
            o.id = "ElevatorTripCounterFragment_"+curTripCounterFrag;
            o.channelColor = "#d10fce";
            o.widget_background = this.state.widget_background;
            o.widget_text_color = this.state.widget_text_color;
            curTripCounterFrag++;
            this.setState({curTripCounterFrag:curTripCounterFrag});

          }else if(o.name.startsWith("statictext")) {
            var curStaticTxtFrag = this.state.curStaticTxtFrag;
            o.id = "StaticTextFragment_"+curStaticTxtFrag;
            o.channelColor = "#ffab43";
            o.static_txt_line_1 = this.state.static_txt_line_1;
            o.widget_background = this.state.widget_background;
            o.widget_text_color = this.state.widget_text_color;
            curStaticTxtFrag++;
            this.setState({curStaticTxtFrag:curStaticTxtFrag});
          }
          o.startPos = startPos;
          o.endPos = endPos;
          o.alignLeft = false;
          o.rightOf = null;
          o.alignTop = false;
          o.belowOf = null;
          o.isLastRight = false;
          o.isLastBottom = false;
          return o;
      }else{
        return undefined;
      }
    }

    drawLine( x0, y0, x1, y1) {
      //console.log("drawLine from "+x0+"/"+y0+" to "+x1+"/"+y1);
      this.state.ctx.beginPath();
      this.state.ctx.moveTo(x0, y0);
      this.state.ctx.lineTo(x1, y1);
      this.state.ctx.stroke();
    }

    adjustInsideBorder( pos) {
      if(!this.state.canvas) return pos;
      var border = this.state.border;

      var resX = pos.x;
      var resY = pos.y;
      if(resX<border) {
        resX = border;
      }
      if((resX+border)>this.state.canvas.width) {
        //console.log("outside maxWidth");
        resX = this.state.canvas.width-border;
      }
      if (resY<border) {
        //console.log("y < border");
        resY = border;
      }
      if(resY>this.state.canvas.height-border) {
        //console.log("Y > border")
        resY = this.state.canvas.height-border;
      }
      return {x:resX,y:resY};
    }

    drawRaster() {
      var b0 = this.state.border;
      this.drawRectangle({x:b0,y:b0}, {x:this.state.canvas.width-b0,y:this.state.canvas.height-b0}, 1, '#000000', "");

      this.state.ctx.strokeStyle="#F0F0F0";
      this.state.ctx.lineWidth = '0.5';
      var stepWidth = this.state.raster_size;
      var nofSteps = (this.state.canvas.width-2*this.state.border) / stepWidth;
      for(var i=0; i<nofSteps; i++) {
        this.drawLine(b0+i*stepWidth, b0 , b0+i*stepWidth ,this.state.canvas.height-this.state.border);
      }
      nofSteps = (this.state.canvas.height-2*this.state.border) / stepWidth;
      for(i=0; i<nofSteps; i++) {
        this.drawLine(b0, b0+i*stepWidth, this.state.canvas.width-this.state.border , b0+i*stepWidth );
      }

      // drawXLines, yLins for help
      this.state.ctx.strokeStyle="#00FF00";
      this.state.ctx.lineWidth = '0.6';

      var xLines = this.getXLines(this.state.items);
      for(i=1; i<xLines.length-1; i++) {
        this.drawLine(xLines[i],this.state.border,xLines[i],this.state.canvas.height-this.state.border );
      }
      var yLines = this.getYLines(this.state.items);
      for(i=1; i<yLines.length-1; i++) {
        this.drawLine(this.state.border,yLines[i],this.state.canvas.width-this.state.border,yLines[i]);
      }

    }

    roundDecimal(num) {
      return Math.round(num * 100) / 100;
    }

    drawCanvasContent() {

      if(!this.state.ctx) return;
      this.state.ctx.clearRect(0, 0, this.state.canvas.width, this.state.canvas.height);

      //have background?
      if(this.state.canvas_bg) {
        var b0 = this.state.border;
        var image = new Image();
        image.src = this.state.canvas_bg;
        this.state.ctx.drawImage(image,b0,b0,image.width,image.height);
      }
      if(this.state.currentMousePos) {
        this.drawMousePosition(this.state.currentMousePos);
      }
      this.drawRaster();

      // drawRectangle
      if(this.state.startPos && this.state.endPos) {
        var c = this.createChannel(this.state.channel_type+" ", this.state.startPos,this.state.endPos,null);
        if(c) {
          var curItems = this.state.items;
          curItems.push(c);
          this.setState({startPos:undefined,endPos:undefined,items:curItems});
        }else{
          this.setState({endPos:undefined});
        }

      }else if(this.state.startPos) {
        this.drawCircle(this.state.startPos, 6,"#FF0000");
        var adjust = this.adjustMousePos(this.state.currentMousePos);
        var txt = "("+this.roundDecimal(adjust.x - this.state.startPos.x)+" / "+this.roundDecimal(adjust.y-this.state.startPos.y)+")";
        this.drawRectangle(this.state.startPos,adjust,1,'#FF0000',txt);
      }

      // draw channels
      for( var i=0; i<this.state.items.length; i++) {
        var channel = this.state.items[i];
        if(channel.channelName==="empty") {
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"");

        }else if(channel.name.startsWith("qrcode")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"[QR]");

        }else if(channel.name.startsWith("arrow")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"[Arrow]");

        }else if(channel.name.startsWith("door")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"[Door]");

        }else if(channel.name.startsWith("floornumber")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"88");

        }else if(channel.name.startsWith("next_stop")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"[0]");

        }else if(channel.name.startsWith("floor_directory")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"[Dir]");

        }else if(channel.name.startsWith("time1")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,channel.clock_format);

        }else if(channel.name.startsWith("time2")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,channel.clock_format);

        }else if(channel.name.startsWith("time3")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,channel.clock_format);

        }else if(channel.name.startsWith("weather5")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"weather forecast - "+channel.weather_city_name);

        }else if(channel.name.startsWith("weather")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"cur weather - "+channel.weather_city_name);

        }else if(channel.name.startsWith("floorpics")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"floor info");

        }else if(channel.name.startsWith("tripcounter")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"trip counter");

        }else if(channel.name.startsWith("statictext")){
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,"(static text)");

        }else{
          this.fillRectangle(channel.startPos,channel.endPos,channel.channelColor,channel.name);
        }
      }
      this.setState({needRedraw:false});
    }

    distance( p0, p1) {
      var dX = p1.x - p0.x;
      var dY = p1.y - p0.y;
      return Math.sqrt( dX*dX + dY*dY);
    }

    findNearestPoint( point, points) {
       var closestP = points[0];
       var closestD = this.distance(point,points[0]);
       for(var i=1; i<points.length; i++) {
          var dist = this.distance(point,points[i]);
          if(dist<closestD) {
            closestD = dist;
            closestP = points[i];
          }
       }
       return closestP;
    }

    getChannelPoints( channel) {
      // counter clockwise
      var p0 = channel.startPos;
      var p2 = channel.endPos;
      var p1 = {x:p2.x,y:p0.y};
      var p3 = {x:p0.x,y:p2.y};
      return [p0,p1,p2,p3];
    }

    avoidInsideChannelStartPos( coord) {
      if(!this.state.items || this.state.items.length===0) {
        return coord;
      }
      for(var i=0; i<this.state.items.length; i++) {
        var item = this.state.items[i];
        if(this.isInside(item.startPos,item.endPos,coord)) {
          // remark +1 pixel
          // allocated nearest points
          var channelPoints = this.getChannelPoints(item);
          //console.log("ChannelPoints="+channelPoints);
          var closestP = this.findNearestPoint( coord, channelPoints);
          if(closestP===channelPoints[0]) {
              return {x:closestP.x,y:closestP.y};

          }else if(closestP===channelPoints[1]) {
              return {x:closestP.x+1,y:closestP.y};

          }else if(closestP===channelPoints[2]) {
              return {x:closestP.x+1,y:closestP.y};

          }else if(closestP===channelPoints[3]) {
              return {x:closestP.x,y:closestP.y};
          }
          return closestP;
          //coord = {x:item.endPos.x+1,y:item.startPos.y};
        }
      }
      return coord;
    }

    handleCanvasClick = (evt) => {

      if(this.state.channel_type==='') {
          this.showErrorMessage(ETVConstants.trans("please_choose_channel_type"));
          return;
      }

      if(this.state.channel_type==='streaming' && (!this.state.streaming_ip || this.state.streaming_ip==='')) {
        this.showErrorMessage(ETVConstants.trans("please_set_ip_streaming_address"));
        return;
      }
      if(this.state.channel_type==='weather5' || this.state.channel_type==='weather') {
        if(!this.state.weather_city_lat || !this.state.weather_city_lgt) {
            this.showErrorMessage(ETVConstants.trans("please_set_location"));
            return;
        }
      }
      if(this.state.channel_type==='statictext') {
        if(!this.state.static_txt_line_1) {
            this.showErrorMessage(ETVConstants.trans("please_enter_text"));
            return;
        }
      }
      if(this.state.channel_type==='qrcode') {
        if(!this.state.qr_code) {
            this.showErrorMessage(ETVConstants.trans("please_enter_qr_code"));
            return;
        }
      }
      const coord0 = this.getMousePos(this.state.canvas,evt);
      const coord1 = this.adjustMousePos(coord0);
      const coord = this.avoidInsideChannelStartPos(coord1);
      if(!this.state.startPos) {
        this.setState({startPos:coord});
      }else{
        this.setState({endPos:coord});
      }
    }

    getMousePos(canvas, evt) {
        var rect = this.state.canvas.getBoundingClientRect();
        return {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top
        };
    }

    onMouseMove(evt) {
      var rect = this.state.canvas.getBoundingClientRect();
      var x = evt.clientX - rect.left;
      var y = evt.clientY - rect.top;
      var curPos = {x:x,y:y};
      this.setState({currentMousePos:curPos, needRedraw:true});
    }

    roundTo( x, z) {
      var mod = x % z;
      if(mod<=(z/2)) {
        return x-mod;
      }else{
        return x-mod+z;
      }
    }

    findStartPoint( pos) {
      var roundedtoLineX = false;
      var roundedtoLineY = false;

      var testX = Math.max(pos.x,this.state.border);
      testX = Math.min(pos.x,this.state.canvas.width-this.state.border);
      var xLines = this.getXLines(this.state.items);
      for( var i=0; i<xLines.length; i++) {
        if(Math.abs(xLines[i]-testX)<5) {
          testX = xLines[i];
          roundedtoLineX = true;
        }
      }
      var testY = Math.max(pos.y,this.state.border);
      testY = Math.min(pos.y,this.state.canvas.height-this.state.border);
      var yLines = this.getYLines(this.state.items);
      for(i=0; i<yLines.length; i++) {
        if(Math.abs(yLines[i]-testY)<5) {
          testY = yLines[i];
          roundedtoLineY = true;
        }
      }
      if (!roundedtoLineX) {
        testX = this.roundTo(pos.x,this.state.raster_size);
        testX = Math.max(this.state.border,testX);
        testX = Math.min(this.state.canvas.width-this.state.border,testX);
      }
      if(!roundedtoLineY) {
        testY = this.roundTo(pos.y,this.state.raster_size);
        testY = Math.max(this.state.border,testY);
        testY = Math.min(this.state.canvas.height-this.state.border,testY);
      }
      return {x:testX,y:testY};
    }

    //ensures that we keep the ratio of outside border (even mouse goes out)
    ensureRatio(startPos, targetEnd, ratio) {
        var xNew = this.ensureXRange(targetEnd.x);
        if(xNew!==targetEnd.x) {
          var deltaX = xNew - startPos.x;
          var yNew = startPos.y + 1.0/ratio*deltaX;
          var res1 = {x:xNew,y:yNew};
          return res1;
        }
        var yNew1 = this.ensureYRange(targetEnd.y);
        if(yNew1!==targetEnd.y) {
          var deltaY = startPos.y - yNew1;
          var xNew1 = startPos.x - ratio*deltaY;
          var res2 = {x:xNew1,y:yNew1};
          return res2;
        }
        return targetEnd;
    }

    ensureXRange( x) {
      if (x<this.state.border) return this.state.border;
      if (x>(this.state.canvas.width - this.state.border)) {return (this.state.canvas.width-this.state.border);}
      return x;
    }

    ensureYRange( y) {
      if (y<this.state.border) return this.state.border;
      if (y>(this.state.canvas.height - this.state.border)) return (this.state.canvas.height-this.state.border);
      return y;
    }

    adjustMousePos( pos0) {
      var pos = this.adjustInsideBorder(pos0);
      var x = pos.x;
      var y = pos.y;
      if(!this.state.startPos) {
          var startPos = this.findStartPoint(pos0);
          return {x:startPos.x,y:startPos.y};
      }
      var deltaX = 0;
      //console.log(this.state.channelLayout);
      if(this.state.channelLayout==='raster') {
          x = this.roundTo(pos.x,this.state.raster_size);
          y = this.roundTo(pos.y,this.state.raster_size);
          return {x:x,y:y};

      }else if(this.state.channelLayout==='4_3') {
          deltaX = pos.x - this.state.startPos.x;
          y = this.state.startPos.y + deltaX*0.75;
          if(y>this.state.canvas.height-this.state.border) {
            y = this.state.canvas.height-this.state.border;
            x = this.state.startPos.x + (y-this.state.startPos.y)*1.333333;
          }
          return this.ensureRatio(this.state.startPos,{x:x,y:y},1.333);

      }else if(this.state.channelLayout==='3_4') {
          deltaX = pos.x - this.state.startPos.x;
          y = this.state.startPos.y + 1.33333*deltaX;
          if(y>this.state.canvas.height-this.state.border) {
            y = this.state.canvas.height-this.state.border;
            x = this.state.startPos.x + (y-this.state.startPos.y)*0.75;
          }
          return this.ensureRatio(this.state.startPos,{x:x,y:y},0.75);

      }else if(this.state.channelLayout==='16_9') {
          deltaX = pos.x - this.state.startPos.x;
          y = this.state.startPos.y + 0.5625*deltaX;
          if(y>this.state.canvas.height-this.state.border) {
            y = this.state.canvas.height-this.state.border;
            x = this.state.startPos.x + (y-this.state.startPos.y)*1.77777778;
          }
          return this.ensureRatio(this.state.startPos,{x:x,y:y},1.7777778);

      }else if(this.state.channelLayout==='9_16') {
          deltaX = pos.x - this.state.startPos.x;
          y = this.state.startPos.y + 1.77777778*deltaX;
          if(y>this.state.canvas.height-this.state.border) {
            y = this.state.canvas.height-this.state.border;
            x = this.state.startPos.x + (y-this.state.startPos.y)*0.5625;
          }
          return this.ensureRatio(this.state.startPos,{x:x,y:y},0.5625);

      }else if(this.state.channelLayout==='banner') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/7.8)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
              y = this.state.canvas.height-this.state.border;
              x = this.state.startPos.x + (y-this.state.startPos.y)*7.8;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},7.8);

      }else if(this.state.channelLayout==='leaderboard') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/8.0888889)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
                y = this.state.canvas.height-this.state.border;
                x = this.state.startPos.x + (y-this.state.startPos.y)*8.0888889;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},8.0888889);

      }else if(this.state.channelLayout==='inline_rectangle') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/1.2)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
                y = this.state.canvas.height-this.state.border;
                x = this.state.startPos.x + (y-this.state.startPos.y)*1.2;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},1.2);

      }else if(this.state.channelLayout==='skyscraper') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/0.2)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
                y = this.state.canvas.height-this.state.border;
                x = this.state.startPos.x + (y-this.state.startPos.y)*0.2;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},0.2);

      }else if(this.state.channelLayout==='half_page_ad') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/0.5)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
                  y = this.state.canvas.height-this.state.border;
                  x = this.state.startPos.x + (y-this.state.startPos.y)*0.5;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},0.5);

      }else if(this.state.channelLayout==='large_leaderboard') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + (1.0/10.77777778)*deltaX;
            if(y>this.state.canvas.height-this.state.border) {
                    y = this.state.canvas.height-this.state.border;
                    x = this.state.startPos.x + (y-this.state.startPos.y)*10.77777778;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},10.7777778);

      }else if(this.state.channelLayout==='1_1') {
            deltaX = pos.x - this.state.startPos.x;
            y = this.state.startPos.y + deltaX;
            if(y>this.state.canvas.height-this.state.border) {
              y = this.state.canvas.height-this.state.border;
              x = this.state.startPos.x + (y-this.state.startPos.y)*1;
            }
            return this.ensureRatio(this.state.startPos,{x:x,y:y},1.0);
      }
      // freeStyle --> get close to xYlines
      var testX = Math.max(x,this.state.border);
      testX = Math.min(x,this.state.canvas.width-this.state.border);
      var xLines = this.getXLines(this.state.items);
      for( var i=0; i<xLines.length; i++) {
        if(Math.abs(xLines[i]-testX)<5) {
          testX = xLines[i];
        }
      }
      var testY = Math.max(y,this.state.border);
      testY = Math.min(y,this.state.canvas.height-this.state.border);
      var yLines = this.getYLines(this.state.items);
      for(i=0; i<yLines.length; i++) {
        if(Math.abs(yLines[i]-testY)<5) {
          testY = yLines[i];
        }
      }
      return {x:this.ensureXRange(testX),y:this.ensureYRange(testY)};
    }

    drawMousePosition( mousePos) {
        var pos = this.adjustMousePos(mousePos);
        this.drawCircle(pos,4,"#222222");
    }

    drawRectangle(pos0, pos1, lineWidth, color, text) {
        var x0 = pos0.x;
        var y0 = pos0.y;
        var x1 = pos1.x;
        var y1 = pos1.y;
        //console.log("drawRec("+x0+"/"+y0+") to ("+x1+"/"+y1+")");
      	var ctx = this.state.ctx;
      	ctx.beginPath();
      	ctx.lineWidth=lineWidth;
      	ctx.strokeStyle=color;
      	ctx.moveTo(x0, y0);
      	ctx.lineTo(x1, y0);
      	ctx.lineTo(x1, y1);
      	ctx.lineTo(x0, y1);
      	ctx.lineTo(x0, y0);
      	ctx.stroke();

        if(!text || text.length===0) return;

        var width = this.state.ctx.measureText(text).width;
        var posX = (x0+x1)/2 - width/2;
        var posY = (y0+y1)/2;
        this.state.ctx.font = "14px Arial";
        this.state.ctx.fillStyle = "#000000";
        this.state.ctx.fillText(text, posX, posY);
    }

    fillRectangle(pos0, pos1, bgColor, text) {
        var x0 = pos0.x;
        var y0 = pos0.y;
        var x1 = pos1.x;
        var y1 = pos1.y;
        var rectWidth = x1 - x0;
        //console.log("drawRec("+x0+"/"+y0+") to ("+x1+"/"+y1+")");
        var ctx = this.state.ctx;
        ctx.fillStyle = bgColor;
        ctx.fillRect(x0,y0,x1-x0,y1-y0);

        if(!text || text.length===0 || text==='empty') return;

        var pointsFont = 20;
        ctx.font = pointsFont+"px Arial";
        var width = ctx.measureText(text).width;
        while(width>rectWidth) {
          pointsFont = pointsFont -2;
          ctx.font = pointsFont+"px Arial";
          width = ctx.measureText(text).width;
        }

        var posX = (x0+x1)/2 - width/2;
        var posY = (y0+y1)/2;
        ctx.fillStyle = "white";
        ctx.fillText(text, posX, posY);
    }

    getChannelColor( channelNr) {
      if(channelNr===0) {
        return "#276fe3";
      }else if(channelNr===1) {
        return "#03a631";
      }else if(channelNr===2) {
        return "#fa4343";
      }else if(channelNr===3) {
          return "#ff0066";
      }else if(channelNr===4) {
            return "#cc66ff";
      }else{
        return "#cc3300";
      }
    }

    rand100() {
      var s = Math.floor(Math.random() * 70);
      if(s.length===1) {
        return "0"+s;
      }
      return s;
    }

    drawCircle( pos, radius, color) {
        var x1 = pos.x;
        var y1 = pos.y;
        var ctx = this.state.ctx;
        ctx.beginPath();
        ctx.lineWidth=1;
        ctx.strokeStyle=color;
        ctx.arc(x1, y1, radius, 0, 2 * Math.PI);
        ctx.stroke();
    }

    handleCanvasResolutionChange = (event) => {
      var border = this.state.border;
      var ratio = event.target.value;
      var y = 500;
      var x = y*ratio;
      var isLandscape = false;
      if(ratio>=1) {
        x = 600;
        y = x/ratio;
        isLandscape = true;
      }
      x = x+2*border;
      y = y+2*border;
      this.clearChannels();
      this.setState({canvasResolution:ratio, isLandscape: isLandscape, cWidth:x, cHeight:y, canvas_img:undefined, canvas_bg:undefined, needRedraw: true});
    }


    onMouseOut() {
    }

    onKeyDown(event) {
      //console.log("KeyDown keyValue="+event.keyCode+" event.key="+event.key);
      //var ch = event.key;
      if(this.state.status===1) {
        //console.log("append char="+ch);
      }else{
        //console.log("ouf of focus, not append char ("+ch+")");
      }
    }

    showWindow(divID, show) {
      //console.log("show window="+divID+" show="+show);
      this.setState({show_overlay:show});
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(msg) {
      this.showWindow('message_div',true);
      this.setState({info_message:msg});
    }

    closeInfoMessage() {
      this.setState({info_message:undefined});
      this.showWindow('message_div',false);
    }

    showErrorMessage(msg) {
      this.showWindow('error_message_div',true);
      this.setState({error_message:msg});
    }

    closeErrorMessage() {
      this.setState({error_message:undefined});
      this.showWindow('error_message_div',false);
    }

    convertCanvasToImageData(canvas) {
      var border = this.state.border;
      var cWidth = canvas.width;
      var cHeight = canvas.height;
      var cropped = this.crop(canvas,{x:border,y:border},{x:cWidth-border,y:cHeight-border});
    	return cropped.toDataURL("image/png");
    }

    crop(can, a, b) {
      // get your canvas and a context for it
      var ctx = can.getContext('2d');
      // get the image data you want to keep.
      var imageData = ctx.getImageData(a.x, a.y, b.x, b.y);
      // create a new cavnas same as clipped size and a context
      var newCan = document.createElement('canvas');
      newCan.width = b.x - a.x;
      newCan.height = b.y - a.y;
      var newCtx = newCan.getContext('2d');
      // put the clipped image on the new canvas.
      newCtx.putImageData(imageData, 0, 0);
      return newCan;
    }

    prepareSubmitLayout(txt) {
      var imageData = this.convertCanvasToImageData(this.state.canvas)
      this.setState({finalImage:imageData,finalConfigFile:txt});
      //console.log("layoutData="+txt);
    }

    submitLayout() {
      //console.log("--------------------->submitLayout inside");
      this.setState({isSubmitting:true});
      this.showInfoMessage("Uploading...")

      var formData = new FormData();
      formData.append("user_id",this.props.user_id);
      formData.append("layout_image",this.convertCanvasToImageData(this.state.canvas));
      formData.append("layout_data",this.state.finalConfigFile);
      formData.append("nof_channels",this.state.curChannel);
      formData.append("screen_ratio",this.state.canvasResolution);
      formData.append("landscape",this.state.isLandscape);

      const config = {
        headers: {	'content-type': 'multipart/form-data'}
      }
      var url = ETVConstants.getServerBase()+"/layout_upload";
      axios.post(url, formData, config)
            .then((response) => {
                  //console.log("==============================> resp="+JSON.stringify(response, null, 2));
                  this.showInfoMessage(ETVConstants.trans("successfully_uploaded"));
                  this.clearChannels();
                  this.setState({finalImage:undefined,finalConfigFile:undefined,isSubmitting:false});
                })
                .catch((error) => {
                   //console.error(error);
                   this.showInfoMessage(ETVConstants.trans("uploading_error"));
                   this.setState({finalImage:undefined,finalConfigFile:undefined,isSubmitting:false});
                });
    }

    stepBack() {
      if(this.state.startPos) {
        this.setState({startPos:undefined,endPos:undefined,needRedraw:true});
        return;
      }
      var curItems = this.state.items;
      var channel = curItems.pop();
      //console.log("---->curChannelName="+channel.name);
      if(channel.name.startsWith("content")) {
        var curCh = this.state.curChannel - 1;
        this.setState({items:curItems, curChannel:curCh, needRedraw:true});

      }else if(channel.id.startsWith("time1")){
        var curTimeFrag1Lines = this.state.curTimeFrag1Line - 1;
        this.setState({items:curItems, curTimeFrag1Lines:curTimeFrag1Lines, needRedraw:true});

      }else if(channel.id.startsWith("time2")){
        var curTime2Lines = this.state.curTimeFrag2Lines - 1;
        this.setState({items:curItems, curTimeFrag2Lines:curTime2Lines, needRedraw:true});

      }else if(channel.id.startsWith("time3")){
        var curTime3Lines = this.state.curTimeFrag3Lines - 1;
        this.setState({items:curItems, curTimeFrag3Lines:curTime3Lines, needRedraw:true});

      }else if(channel.id.startsWith("qrcode")){
        var curFragQr = this.state.curQRCodeFrag - 1;
        this.setState({items:curItems, curQRCodeFrag:curFragQr, needRedraw:true});

      }else if(channel.id.startsWith("arrow")){
        var curFragArrow = this.state.curArrowFrag - 1;
        this.setState({items:curItems, curArrowFrag:curFragArrow, needRedraw:true});

      }else if(channel.id.startsWith("door")){
        var curFragDoor = this.state.curDoorFrag - 1;
        this.setState({items:curItems, curDoorFrag:curFragDoor, needRedraw:true});

      }else if(channel.id.startsWith("floornumber")){
        var curFragFloorNr = this.state.curFloorNrFrag - 1;
        this.setState({items:curItems, curFloorNrFrag:curFragFloorNr, needRedraw:true});

      }else if(channel.id.startsWith("next_stop")){
        var curFragNextStopNr = this.state.curNextStopFrag - 1;
        this.setState({items:curItems, curNextStopFrag:curFragNextStopNr, needRedraw:true});

      }else if(channel.id.startsWith("floor_directory_noscrolling")){
        var curFragFD = this.state.curFloorDirNoScrollingFrag - 1;
        this.setState({items:curItems, curFloorDirNoScrollingFrag:curFragFD, needRedraw:true});

      }else if(channel.id.startsWith("floor_directory")){
        var curFragFD = this.state.curFloorDirFrag - 1;
        this.setState({items:curItems, curFloorDirFrag:curFragFD, needRedraw:true});

      }else if(channel.id.startsWith("weather5")){
        var curFragW5 = this.state.curWeather5Frag - 1;
        this.setState({items:curItems, curWeather5Frag:curFragW5, needRedraw:true});

      }else if(channel.id.startsWith("weather")){
        var curFragW = this.state.curWeatherFrag - 1;
        this.setState({items:curItems, curWeatherFrag:curFragW, needRedraw:true});

      }else if(channel.id.startsWith("floorpics")){
        var curFloorPicFrag = this.state.curFloorPicFrag - 1;
        this.setState({items:curItems, curFloorPicFrag:curFloorPicFrag, needRedraw:true});

      }else if(channel.id.startsWith("tripcounter")){
        var curTripCounterFrag = this.state.curTripCounterFrag - 1;
        this.setState({items:curItems, curTripCounterFrag:curTripCounterFrag, needRedraw:true});

      }else if(channel.id.startsWith("statictext")){
        var curStaticTxtFrag = this.state.curStaticTxtFrag - 1;
        this.setState({items:curItems, curStaticTxtFrag:curStaticTxtFrag, needRedraw:true});

      }else if(channel.id.startsWith("elevator_landscape")){
        var curElevFragL = this.state.curElevFrag - 1;
        this.setState({items:curItems, curElevFrag:curElevFragL, needRedraw:true});

      }else if(channel.id.startsWith("elevator_portrait")){
        var curElevFragP = this.state.curElevFrag - 1;
        this.setState({items:curItems, curElevFrag:curElevFragP, needRedraw:true});

      }else if(channel.id.startsWith("streaming")){
        var curStreamingFrag = this.state.curStreamingFrag - 1;
        this.setState({items:curItems, curStreamingFrag:curStreamingFrag, needRedraw:true});

      }else{
        this.setState({items:curItems, needRedraw:true});
      }
    }

    clearChannels() {
      this.setState({items:[],startPos:undefined,endPos:undefined,nof_lines:3,curChannel:0, curElevFrag:0, curStreamingFrag:0, curTimeFrag1Lines:0, curTimeFrag2Lines:0,curTimeFrag3Lines:0, curQRCodeFrag:0, curArrowFrag:0, curFloorNrFrag:0, curFloorDirFrag:0, curFloorDirNoScrollingFrag:0, curDoorFrag:0, curWeatherFrag:0, curWeather5Frag:0, curFloorPicFrag:0, curTripCounterFrag:0, curStaticTxtFrag:0, needRedraw:true});
    }

    checkChannelsSortingConsistency( channels) {
      //console.log("--------- <CHECK CONSISTENCY> ----------------");
      for(var i=1; i<channels.length; i++) {
        var consistent = this.checkChannelConsistent(channels[i-1],channels[i]);
        if(!consistent) {
          //console.log(channels[i-1].name+" / "+channels[i].name+" NOT CONSISTENT!!!!!!");
          return false;
        }else{
          this.printChannel(channels[i-1]);
          this.printChannel(channels[i]);
        }
      }
      //console.log("--------- </CHECK CONSISTENCY> ----------------");
      return true;
    }

    printChannel( channel) {
      //o.startPos = startPos;
      //o.endPos = endPos;
      //o.alignLeft = false;
      //o.rightOf = null;
      //o.alignTop = false;
      //o.belowOf = null;
      //o.isLastRight = false;
      //o.isLastBottom = false;
      //console.log(channel.name+" x="+channel.startPos.x+" y="+channel.startPos.y+" rightOf="+channel.rightOf);
    }

    // checking if the channel is setup correctly
    channelOK( channel) {
      return channel.startPos.x < channel.endPos.x && channel.startPos.y < channel.endPos.y;
    }

    // checking if the channel0 and channel 1 are consistent with each other
    checkChannelConsistent( channel0, channel1) {
      if (!this.channelOK(channel0)) return false;
      if (!this.channelOK(channel1)) return false;
      if (channel0.startPos.y > channel1.startPos.y) return false;
      if (channel0.startPos.y === channel1.startPos.y && channel0.startPos.x >= channel1.startPos.x) return false;
      return true;
    }

    finalize() {
      var drawBuffer = this.state.items;
    	var generatedChannels = this.createAndSortChannelsWithEmpty(drawBuffer);
      //var newItems = drawBuffer.concat(generatedChannels);
      var newItems = generatedChannels;
      this.sortChannelsY(newItems);
      // make sure the white channels are drawn to
      this.setState({needRedraw:true});
      //console.log("items.size="+newItems.length+" emptyChannels="+generatedChannels.length);
      this.setState({items:newItems,needRedraw:true});

      for(var i=0; i<newItems.length; i++) {
        //console.log("channelNr="+newItems[i].channelName);
      }
    	this.assignTopLevels(newItems);
    	var txt = this.generateConfigFile(newItems);
    	if(this.state.debug) {
        //console.log(txt);
      }
      this.drawCanvasContent();
      this.setState({needRedraw:true});
    	this.prepareSubmitLayout(txt);
    }

    needsElevatorConnection(channels) {
      for(var i=0; i<channels.length; i++) {
        var channelType = channels[i].name;
        if(channelType.startsWith("elevator_landscape")) return true;
        if(channelType.startsWith("elevator_portrait")) return true;
        if(channelType.startsWith("elevator_small")) return true;
        if(channelType.startsWith("floorpics")) return true;
        if(channelType.startsWith("tripcounter")) return true;
        if(channelType.startsWith("arrow")) return true;
        if(channelType.startsWith("door")) return true;
        if(channelType.startsWith("floornumber")) return true;
        if(channelType.startsWith("next_stop")) return true;
        if(channelType.startsWith("floor_directory_noscrolling")) return true;
        if(channelType.startsWith("floor_directory")) return true;
      }
      return false;
    }

    generateConfigFile( channels) {
      var hasElev = this.needsElevatorConnection(channels);
    	var res = "layout.background.color="+this.state.bg_color+"\r\n";
    	res+="layout.orientation="+this.getMode()+"\r\n";
    	res+="has_elevator_connection="+hasElev+"\r\n";
      res+="\r\n";
    	for( var i=0; i<channels.length; i++) {
    		res += this.generateChannelString(i,channels[i]);
    	}
    	return res;
    }

    getMode() {
    	var res = {w:this.state.canvas.width,h:this.state.canvas.height};
    	if (res.w >= res.h) {
    		return "landscape";
    	}
    	return "portrait";
    }

    generateChannelString(curID, channel) {
    	var res = "";
    	res+="layout.channel."+curID+".id="+channel.id+"\r\n";
    	res+="layout.channel."+curID+".margin=0\r\n";

    	if (channel.alignTop) {
    		res+="layout.channel."+curID+".parent_top="+channel.alignTop+"\r\n";
    	}else{
    		res+="layout.channel."+curID+".below="+channel.belowOf+"\r\n";
    	}

    	if( channel.alignLeft) {
    		res+="layout.channel."+curID+".parent_left="+channel.alignLeft+"\r\n";
    	}else{
    		res+="layout.channel."+curID+".right_of="+channel.rightOf+"\r\n";
    	}
    	if (channel.isLastRight) {
    		res+="layout.channel."+curID+".width=match_parent\r\n";
    	}else{
    		var widthInt = Math.min(1.0,(channel.endPos.x - channel.startPos.x) / (this.state.canvas.width-2*this.state.border));
    		res+="layout.channel."+curID+".width="+widthInt+"*W\r\n";
    	}

    	var heightInt = Math.min(1.0,(channel.endPos.y - channel.startPos.y) / (this.state.canvas.height-2*this.state.border));
    	res+="layout.channel."+curID+".height="+heightInt+"*H\r\n";

      if(channel.name.startsWith('streaming')) {
        res+="layout.channel."+curID+".parameter.0="+channel.streaming_ip+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.streaming_backup+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.streaming_ip_supervision+"\r\n";

      }else if(channel.name.startsWith('qrcode')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.qr_code+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('floornumber')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('next_stop')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('floor_directory_noscrolling')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.widget_text_color+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.nof_lines+"\r\n";

      }else if(channel.name.startsWith('floor_directory')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.widget_text_color+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.nof_lines+"\r\n";

      }else if(channel.name.startsWith('arrow') || channel.name.startsWith('door')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";

      }else if(channel.name.startsWith('time1')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.clock_format+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.clock_date_format+"\r\n";
        res+="layout.channel."+curID+".parameter.3="+channel.clock_locale+"\r\n";
        res+="layout.channel."+curID+".parameter.4="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('time2')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.clock_format+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.clock_date_format+"\r\n";
        res+="layout.channel."+curID+".parameter.3="+channel.clock_locale+"\r\n";
        res+="layout.channel."+curID+".parameter.4="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('time3')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.clock_format+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.clock_date_format+"\r\n";
        res+="layout.channel."+curID+".parameter.3="+channel.clock_locale+"\r\n";
        res+="layout.channel."+curID+".parameter.4="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('weather5')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.weather_city_lat+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.weather_city_lgt+"\r\n";
        res+="layout.channel."+curID+".parameter.3="+channel.weather_city_name+"\r\n";

      }else if(channel.name.startsWith('weather')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.weather_city_lat+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.weather_city_lgt+"\r\n";
        res+="layout.channel."+curID+".parameter.3="+channel.weather_city_name+"\r\n";

      }else if(channel.name.startsWith('floorpics')) {
        //no parameter

      }else if(channel.name.startsWith('tripcounter')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.widget_text_color+"\r\n";

      }else if(channel.name.startsWith('statictext')) {
        res+="layout.channel."+curID+".parameter.0="+channel.widget_background+"\r\n";
        res+="layout.channel."+curID+".parameter.1="+channel.static_txt_line_1+"\r\n";
        res+="layout.channel."+curID+".parameter.2="+channel.widget_text_color+"\r\n";
      }
    	res+="\r\n";
    	return res;
    }

    assignTopLevels(channels) {
    	this.sortChannelsY(channels);
    	// assign below
    	var curYID = channels[0].id;
    	var curY = channels[0].startPos.y;
    	var alignTop = true;

    	for(var k=0; k<channels.length; k++) {
    		var channel = channels[k];
    		if (channel.startPos.y !== curY) {
    			alignTop = false;
    			curYID = channels[k-1].id;
    			curY = channel.startPos.y;
    		}
    		if (alignTop) {
    			channel.alignTop = true;
    		}else{
    			channel.belowOf = curYID;
    		}
    	}
    	return channels;
    }

    sortChannelsX( sortable) {
    	sortable.sort(function(a, b) {
          if (a.startPos.x < b.startPos.x) {
    	    	  return -1;
    	    }else if(a.startPos.x > b.startPos.x){
    	    	  return 1;
    	    }else{
    	       // x is equal, compare y
        		if (a.startPos.y<=b.startPos.y) {
        	    	return -1;
        	  }else {
        	    	return 1;
        	  }
          }
    	});
    }

    sortChannelsY( sortable) {
    	sortable.sort(function(a, b) {
    	    if (a.startPos.y < b.startPos.y) {
    	    	return -1;
    	    }else if(a.startPos.y > b.startPos.y){
    	    	return 1;
    	    }else{
    	       // y is equal, sort by x
    		    if (a.startPos.x<=b.startPos.x) {
    	    	    return -1;
    	      }else{
    	    	    return 1;
    	      }
          }
    	});
    }

    // used for the automatic pointer position
    getXLines(channels) {
      var border = this.state.border;
    	var xLines = [];
    	xLines.push(border);
    	for(var i=0; i<channels.length; i++) {
    		var nextXCandidate = channels[i].startPos.x;
    		if (!xLines.includes(nextXCandidate)) {
    			xLines.push(nextXCandidate);
    		}
    		nextXCandidate = channels[i].endPos.x;
    		if (!xLines.includes(nextXCandidate)) {
    			xLines.push(nextXCandidate);
    		}
    	}
    	xLines.push(this.state.canvas.width-border);
    	xLines.sort(function(a, b){return a - b});
    	return xLines;
    }

    // returns the smallest y first
    getYLines( channels) {
      var border = this.state.border;
    	var yLines = [];
    	yLines.push(border);
    	for(var i=0; i<channels.length; i++) {
    		var nextYCandidate = channels[i].startPos.y;
    		if (!yLines.includes(nextYCandidate)) {
    			yLines.push(nextYCandidate);
    		}
    		nextYCandidate = channels[i].endPos.y;
    		if (!yLines.includes(nextYCandidate)) {
    			yLines.push(nextYCandidate);
    		}
    	}
    	yLines.push(this.state.canvas.height-border);
    	yLines.sort(function(a, b){return a - b});
    	return yLines;
    }

    // return true if this channel crosses y0,y1
    intersectChannel(channel,y0,y1) {
      if(channel.endPos.y <= y0) return false;
      if(channel.startPos.y >= y1) return false;
      return true;
    	//var notIntersect = (channel.endPos.y <= y0) || (channel.startPos.y >= y1);
    	//return !notIntersect;
    }


    // channels of intersections
    getXIntersectingChannels(channels, y0, y1) {
    	var resXChannels = [];
    	for(var i=0; i<channels.length; i++) {
    		if (this.intersectChannel(channels[i],y0,y1)) {
    			//var point = {x0:channels[i].x0,x1:channels[i].x1};
    			resXChannels.push(channels[i]);
    		}
    	}
    	this.sortChannelsX(resXChannels);
    	return resXChannels;
    }

    // sorting all channels and create empty channels between
    createAndSortChannelsWithEmpty( channels) {
      var emptyCnt = 0;
    	var res = [];
      var border = this.state.border;
    	var yLines = this.getYLines(channels);
      if(this.state.debug) {
        //console.log(yLines);
      }
      //console.log("Y LINES="+yLines);

    	// have all the YLINES (smallest first)
    	for(var k=0; k<yLines.length-1; k++) {
    		var xIntersectingChannels = this.getXIntersectingChannels(channels, yLines[k], yLines[k+1]);
        if(this.state.debug) {
          //console.log("intersecting channels for y0="+yLines[k]+" y1="+yLines[k+1]+" :"+xIntersectingChannels.length);
        }
    		//assertSortedX(xIntersectingChannels);
    		if (xIntersectingChannels.length===0) {
          // full width, no channel intersects
    			var t0 = {x:border,y:yLines[k]};
    			var t1 = {x:this.state.canvas.width-border,y:yLines[k+1]};
    			var newChannel = this.createChannel("empty",t0,t1,emptyCnt++);
    			newChannel.alignLeft = true;
    			newChannel.isLastRight = true;
    			res.push( newChannel);
    		}else{
          // scan from border through all the channels
    			var prevX = border;
    			var nextRightOfID = null;
          var y0 = yLines[k]; // top y
          var y1 = yLines[k+1]; // lower y of horizontal scan

    			for(var j=0; j<xIntersectingChannels.length; j++) {
            var curX = xIntersectingChannels[j].startPos.x;
    				var t0 = {x:prevX, y:y0};
    				var t1 = {x:curX,y:y1};

            if(prevX < curX) {
              // add an empty channel
              var addChannel = this.createChannel("empty",t0,t1,emptyCnt++);
              if (nextRightOfID==null) {
    						addChannel.alignLeft=true;
    					}else{
    						addChannel.rightOf=nextRightOfID;
    					}
              nextRightOfID = addChannel.id;
              if (xIntersectingChannels[j].rightOf==null) {
                xIntersectingChannels[j].rightOf = nextRightOfID;
              }
              nextRightOfID = xIntersectingChannels[j].id;
    					//console.log("push "+addChannel.id);
    					res.push( addChannel );
    					if(!this.existsChannelInArray(res,xIntersectingChannels[j].id)) {
    						res.push( xIntersectingChannels[j]);
    					}
              prevX = xIntersectingChannels[j].endPos.x;
            }else{
              // no empty channel between
              if(prevX===border) {
                xIntersectingChannels[j].alignLeft=true;
              }else{
                if(xIntersectingChannels[j].rightOf==null) {
                  // only assign if not yet assigned!
                  xIntersectingChannels[j].rightOf = nextRightOfID;
                }
              }
              nextRightOfID = xIntersectingChannels[j].id;
              if(!this.existsChannelInArray(res,xIntersectingChannels[j].id)) {
    						//console.log("push "+xIntersectingChannels[j].id);
    						res.push(xIntersectingChannels[j]);
    					}
              prevX = xIntersectingChannels[j].endPos.x;
            }
    			}
    			// last one (if necessary)
    			var lastX0 = xIntersectingChannels[xIntersectingChannels.length-1].endPos.x;
    			var lastX1 = this.state.canvas.width-border;
    			if(lastX0<lastX1) {
    				var ta0 = {x:lastX0, y:yLines[k]};
    				var ta1 = {x:lastX1, y:yLines[k+1]};
    				var lastChannel = this.createChannel("empty",ta0,ta1,emptyCnt++);
    				lastChannel.rightOf = nextRightOfID;
    				lastChannel.isLastRight = true;
    				//console.log("push "+lastChannel.id);
    				res.push( lastChannel);
    			}else{
    				xIntersectingChannels[xIntersectingChannels.length-1].isLastRight=true;
    			}
    		}
    	}
    	return res;
    }

    existsChannelInArray(channels,channelID) {
    	for( var i=0; i<channels.length; i++) {
    		if (channels[i].id===channelID) return true;
    	}
    	return false;
    }

////////////////////////////////////////////////////////////////////////////////
// GPS Location
////////////////////////////////////////////////////////////////////////////////

    setLocationGPS( lat, lgt) {
      this.setState({weather_city_lat:lat,weather_city_lgt:lgt,window:''});
    }

////////////////////////////////////////////////////////////////////////////////
// manage channel type changes
////////////////////////////////////////////////////////////////////////////////

    handleChannelTypeChange( value) {
      this.setState({widget_background:'full_transparent',widget_text_color:''});
      if(value==='weather') {
          this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='weather5') {
          this.setState({channelLayout:'16_9',channel_type:value});
      }else if(value==='time1') {
        this.setState({channelLayout:'raster',channel_type:value});
      }else if(value==='time2') {
        this.setState({channelLayout:'4_3',channel_type:value});
      }else if(value==='time3') {
        this.setState({channelLayout:'4_3',channel_type:value});
      }else if(value==='arrow') {
        this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='tripcounter') {
        this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='qrcode') {
        this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='floornumber') {
        this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='next_stop') {
        this.setState({channelLayout:'1_1',channel_type:value});
      }else if(value==='door') {
        this.setState({channelLayout:'4_3',channel_type:value});
      }else if(value==='floor_directory_noscrolling') {
        this.setState({channelLayout:'raster',channel_type:value});
      }else if(value==='floor_directory') {
        this.setState({channelLayout:'3_4',channel_type:value});
      }else if(value==='floorpics') {
        this.setState({channelLayout:'4_3',channel_type:value});
      }else if(value==='statictext') {
        this.setState({channelLayout:'raster',channel_type:value});
      }else{
        this.setState({channel_type:value});
      }
    }

////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////

    printArrowType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("arrow_help")}
                </Typography>
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                       <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printDoorType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("door_help")}
                </Typography>
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                       <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printFloorNumberType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("floornumber_help")}
                </Typography>
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                {this.printWidgetTextColorChoices()}
                <br/><br/>
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                       <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printNextStopType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("next_stop_help")}
                </Typography>
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                {this.printWidgetTextColorChoices()}
                <br/><br/>
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                       <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printFloorDirectoryType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("floor_directory_help")}
                </Typography>
                <br/><br/>
                {this.printNofScrollingLines()}
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                {this.printWidgetTextColorChoices()}
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="3_4" control={<Radio />} label={ETVConstants.trans("3_4")} />
                       <FormControlLabel value="9_16" control={<Radio />} label={ETVConstants.trans("9_16")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printNofScrollingLines() {
      return    <FormControl component="fieldset">
                  <FormLabel component="legend">{ETVConstants.trans("nof_lines")}</FormLabel>
                  <Select aria-label="format" name="format1" value={this.state.nof_lines} onChange={(e) => {this.setState({nof_lines:e.target.value})}}>
                    <MenuItem value={1}>1 line</MenuItem>
                    <MenuItem value={2}>2 lines</MenuItem>
                    <MenuItem value={3}>3 lines</MenuItem>
                    <MenuItem value={4}>4 lines</MenuItem>
                    <MenuItem value={5}>5 lines</MenuItem>
                    <MenuItem value={6}>6 lines</MenuItem>
                    <MenuItem value={7}>7 lines</MenuItem>
                    <MenuItem value={8}>8 lines</MenuItem>
                    <MenuItem value={9}>9 lines</MenuItem>
                    <MenuItem value={10}>10 lines</MenuItem>
                  </Select>
                </FormControl>
    }

    printNofLines() {
      return    <FormControl component="fieldset">
                  <FormLabel component="legend">{ETVConstants.trans("nof_lines")}</FormLabel>
                  <Select aria-label="format" name="format1" value={this.state.nof_lines} onChange={(e) => {this.setState({nof_lines:e.target.value})}}>
                    <MenuItem value={1}>1 line</MenuItem>
                    <MenuItem value={2}>2 lines</MenuItem>
                    <MenuItem value={3}>3 lines</MenuItem>
                    <MenuItem value={4}>4 lines</MenuItem>
                  </Select>
                </FormControl>
    }

    printFloorDirectoryNoScrollingType() {
        return(
            <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                    {ETVConstants.trans("floor_directory_help")}
                </Typography>
                <br/><br/>
                {this.printNofLines()}
                <br/><br/>
                {this.printWidgetBGChoices()}
                <br/><br/>
                {this.printWidgetTextColorChoices()}
                <br/><br/>
                <FormControl component="fieldset">
                   <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                   <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                       <FormControlLabel value="3_4" control={<Radio />} label={ETVConstants.trans("3_4")} />
                       <FormControlLabel value="9_16" control={<Radio />} label={ETVConstants.trans("9_16")} />
                       <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                       <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                   </RadioGroup>
                </FormControl>
            </div>
          );
    }

    printQRType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
          <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
              {ETVConstants.trans("widget_qr_code_help")}
          </Typography>
          <TextField style={{marginTop:10}} id="qr_code_url" label={ETVConstants.trans("qr_code")} fullWidth={true} size="small" value={this.state.qr_code} onChange={(e)=>{this.setState({qr_code:e.target.value})}}/><br/><br/>
             <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("1_1")} />
                </RadioGroup>
             </FormControl>
             <br/><br/>
                {this.printWidgetTextColorChoices()}
             <br/><br/>
                {this.printWidgetBGChoices()}
             <br/><br/>
        </div>);
    }

    printStreamingType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
             <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_streaming_help")}
             </Typography>
             <br/>
             <TextField id="streaming_ip" label={ETVConstants.trans("streaming_ip")} fullWidth={true} size="small" value={this.state.streaming_ip} onChange={(e)=>this.setState({streaming_ip:e.target.value})}/><br/><br/>
             <TextField id="streaming_backup" label={ETVConstants.trans("streaming_backup")} fullWidth={true} size="small" value={this.state.streaming_backup} onChange={(e)=>{this.setState({streaming_backup:e.target.value})}}/><br/><br/>
             <TextField id="streaming_ip_supervision" label={ETVConstants.trans("streaming_ip_supervision")} fullWidth={true} size="small" value={this.state.streaming_ip_supervision} onChange={(e)=>{this.setState({streaming_ip_supervision:e.target.value})}}/><br/><br/>
             <FormControl style={{marginTop:10}} component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                  <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                </RadioGroup>
             </FormControl>
        </div>);
    }

    printTripCounterType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_trip_counter_help")}
            </Typography>
            <br/><br/>
            {this.printWidgetBGChoices()}
            <br/><br/>
           <FormControl component="fieldset">
              <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
              <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")}/>
              </RadioGroup>
           </FormControl>
        </div>
      );
    }

    printWidgetBGChoices() {
      return(
        <FormControl component="fieldset">
           <FormLabel component="legend">{ETVConstants.trans("background")}</FormLabel>
           <RadioGroup aria-label="format" name="format1" value={this.state.widget_background} onChange={(e) => {this.setState({widget_background:e.target.value})}}>
             <FormControlLabel value="full_transparent" control={<Radio />} label={ETVConstants.trans("full_transparent")} />
             <FormControlLabel value="half_transparent" control={<Radio />} label={ETVConstants.trans("half_transparent")} />
             <FormControlLabel value="white" control={<Radio />} label={ETVConstants.trans("white")} />
             <FormControlLabel value="black" control={<Radio />} label={ETVConstants.trans("black")} />
             <FormControlLabel value="black_gradient_top_down" control={<Radio />} label={ETVConstants.trans("black_gradient_top_down")} />
             <FormControlLabel value="black_gradient_bottom_up" control={<Radio />} label={ETVConstants.trans("black_gradient_bottom_up")} />
             <FormControlLabel value="border" control={<Radio />} label={ETVConstants.trans("border")} />
           </RadioGroup>
        </FormControl>
      );
    }

    printTimeChoices() {
      return(
        <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("time_format")}</FormLabel>
            <Select aria-label="format" name="format1" value={this.state.clock_format} onChange={(e) => {this.setState({clock_format:e.target.value})}}>
              <MenuItem value="HH:mm">13:55</MenuItem>
              <MenuItem value="hh:mm">1:55</MenuItem>
              <MenuItem value="HH:mm:ss">13:55:59</MenuItem>
              <MenuItem value="hh:mm:ss">01:55:59</MenuItem>
              <MenuItem value="HH:mm:ss a">13:55:59 pm</MenuItem>
              <MenuItem value="hh:mm:ss a">01:55:59 pm</MenuItem>
              <MenuItem value="HH:mm a">13:55 am/pm</MenuItem>
              <MenuItem value="hh:mm a">01:55 am/pm</MenuItem>
              <MenuItem value='dd-MM-yyyy'>23-12-2022</MenuItem>
              <MenuItem value='dd/MM/yyyy'>23/12/2022</MenuItem>
              <MenuItem value='dd.MM.yyyy'>23.12.2022</MenuItem>
              <MenuItem value='MM-dd-yyyy'>12-23-2022</MenuItem>
              <MenuItem value='MM/dd/yyyy'>12/23/2022</MenuItem>
              <MenuItem value='MM.dd.yyyy'>12.23.2022</MenuItem>
              <MenuItem value='1'>localized Date Long (e.g. December 23, 2022)</MenuItem>
              <MenuItem value='2'>localized Date Medium (e.g. Dec 23, 2022)</MenuItem>
              <MenuItem value='3'>localized Date Short (e.g. 12/23/2022)</MenuItem>
              <MenuItem value='EEEE dd-MM-yyyy HH:mm'>Monday, 23-12-2022 12:33</MenuItem>
              <MenuItem value='EEE dd-MMMM-yyyy HH:mm'>Mon, 23-December-2022 12:33</MenuItem>
              <MenuItem value='EEE dd-MMMM-yyyy HH:mma'>Mon, 23-December-2022 12:33PM</MenuItem>
              <MenuItem value='EEEE dd-MMMM-yyyy HH:mm'>Monday, 23-December-2022 12:33</MenuItem>
              <MenuItem value='EEEE dd-MMMM-yyyy HH:mma'>Monday, 23-December-2022 12:33PM</MenuItem>
              <MenuItem value='EEE, MMM dd, HH:mm'>Mon, Dec 23, 12:33</MenuItem>
              <MenuItem value='EEE, MMM dd, HH:mma'>Mon, Dec 23, 12:33PM</MenuItem>
              <MenuItem value='EEE'>Mon</MenuItem>
              <MenuItem value='EEEE'>Monday</MenuItem>
            </Select>
        </FormControl>
      );
    }

    printDateChoices() {
      return(
        <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("date_format")}</FormLabel>
            <Select aria-label="format" name="format1" value={this.state.clock_date_format} onChange={(e) => {this.setState({clock_date_format:e.target.value})}}>
            <MenuItem value="HH:mm">13:55</MenuItem>
              <MenuItem value="hh:mm">1:55</MenuItem>
              <MenuItem value="HH:mm:ss">13:55:59</MenuItem>
              <MenuItem value="hh:mm:ss">01:55:59</MenuItem>
              <MenuItem value="HH:mm:ss a">13:55:59 pm</MenuItem>
              <MenuItem value="hh:mm:ss a">01:55:59 pm</MenuItem>
              <MenuItem value="HH:mm a">13:55 am/pm</MenuItem>
              <MenuItem value="hh:mm a">01:55 am/pm</MenuItem>
              <MenuItem value='dd-MM-yyyy'>23-12-2022</MenuItem>
              <MenuItem value='dd/MM/yyyy'>23/12/2022</MenuItem>
              <MenuItem value='dd.MM.yyyy'>23.12.2022</MenuItem>
              <MenuItem value='MM-dd-yyyy'>12-23-2022</MenuItem>
              <MenuItem value='MM/dd/yyyy'>12/23/2022</MenuItem>
              <MenuItem value='MM.dd.yyyy'>12.23.2022</MenuItem>
              <MenuItem value='1'>localized Date Long (e.g. December 23, 2022)</MenuItem>
              <MenuItem value='2'>localized Date Medium (e.g. Dec 23, 2022)</MenuItem>
              <MenuItem value='3'>localized Date Short (e.g. 12/23/2022)</MenuItem>
              <MenuItem value='EEEE dd-MM-yyyy HH:mm'>Monday, 23-12-2022 12:33</MenuItem>
              <MenuItem value='EEE dd-MMMM-yyyy HH:mm'>Mon, 23-December-2022 12:33</MenuItem>
              <MenuItem value='EEE dd-MMMM-yyyy HH:mma'>Mon, 23-December-2022 12:33PM</MenuItem>
              <MenuItem value='EEEE dd-MMMM-yyyy HH:mm'>Monday, 23-December-2022 12:33</MenuItem>
              <MenuItem value='EEEE dd-MMMM-yyyy HH:mma'>Monday, 23-December-2022 12:33PM</MenuItem>
              <MenuItem value='EEE, MMM dd, HH:mm'>Mon, Dec 23, 12:33</MenuItem>
              <MenuItem value='EEE, MMM dd, HH:mma'>Mon, Dec 23, 12:33PM</MenuItem>
              <MenuItem value='EEE'>Mon</MenuItem>
              <MenuItem value='EEEE'>Monday</MenuItem>
            </Select>
        </FormControl>
      );
    }

    printLangChoices() {
      return(
        <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("clock_language")}</FormLabel>
            <Select aria-label="format" name="format1" value={this.state.clock_locale} onChange={(e) => {this.setState({clock_locale:e.target.value})}}>
              <MenuItem value="en">{ETVConstants.trans("english")}</MenuItem>
              <MenuItem value="de">{ETVConstants.trans("german")}</MenuItem>
              <MenuItem value="fr">{ETVConstants.trans("french")}</MenuItem>
              <MenuItem value="ja">{ETVConstants.trans("japanese")}</MenuItem>
              <MenuItem value="es">{ETVConstants.trans("spanish")}</MenuItem>
              <MenuItem value='zh'>{ETVConstants.trans("chinese")}</MenuItem>
              <MenuItem value='pt'>{ETVConstants.trans("portugese")}</MenuItem>
              <MenuItem value='it'>{ETVConstants.trans("italian")}</MenuItem>
              <MenuItem value='ko'>{ETVConstants.trans("korean")}</MenuItem>
              <MenuItem value='th'>{ETVConstants.trans("thai")}</MenuItem>
              <MenuItem value='vi'>{ETVConstants.trans("vietnamese")}</MenuItem>
           </Select>
        </FormControl>
      )
    }

    printTime3Type() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD',marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_time_help")}
            </Typography>
            <br/><br/>
            {this.printTimeChoices()}
             <br/><br/>
             {this.printDateChoices()}
             <br/><br/>
             {this.printLangChoices()}
             <br/><br/>
             {this.printWidgetBGChoices()}
             <br/><br/>
             <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                  <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                  <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                </RadioGroup>
             </FormControl>
        </div>
      );
    }

    printTime2Type() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD',marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_time_help")}
            </Typography>
            <br/><br/>
            {this.printTimeChoices()}
             <br/><br/>
             {this.printDateChoices()}
             <br/><br/>
             {this.printLangChoices()}
             <br/><br/>
             {this.printWidgetBGChoices()}
             <br/><br/>
             <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                  <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                  <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                </RadioGroup>
             </FormControl>
        </div>
      );
    }

    printTimeSimpleType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD',marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_time_simple_help")}
            </Typography>
            <br/><br/>
            <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("time_format")}</FormLabel>
                <Select aria-label="format" name="format1" value={this.state.clock_format} onChange={(e) => {this.setState({clock_format:e.target.value})}}>
                    <MenuItem value="HH:mm">13:55</MenuItem>
                    <MenuItem value="hh:mm">1:55</MenuItem>
                    <MenuItem value="HH:mm:ss">13:55:59</MenuItem>
                    <MenuItem value="hh:mm:ss">01:55:59</MenuItem>
                    <MenuItem value="HH:mm:ss a">13:55:59 pm</MenuItem>
                    <MenuItem value="hh:mm:ss a">01:55:59 pm</MenuItem>
                    <MenuItem value="HH:mm a">13:55 am/pm</MenuItem>
                    <MenuItem value="hh:mm a">01:55 am/pm</MenuItem>
                    <MenuItem value='dd-MM-yyyy'>23-12-2022</MenuItem>
                    <MenuItem value='dd/MM/yyyy'>23/12/2022</MenuItem>
                    <MenuItem value='dd.MM.yyyy'>23.12.2022</MenuItem>
                    <MenuItem value='MM-dd-yyyy'>12-23-2022</MenuItem>
                    <MenuItem value='MM/dd/yyyy'>12/23/2022</MenuItem>
                    <MenuItem value='MM.dd.yyyy'>12.23.2022</MenuItem>
                    <MenuItem value='1'>localized Date Long (e.g. December 23, 2022)</MenuItem>
                    <MenuItem value='2'>localized Date Medium (e.g. Dec 23, 2022)</MenuItem>
                    <MenuItem value='3'>localized Date Short (e.g. 12/23/2022)</MenuItem>
                    <MenuItem value='EEEE dd-MM-yyyy HH:mm'>Monday, 23-12-2022 12:33</MenuItem>
                    <MenuItem value='EEE dd-MMMM-yyyy HH:mm'>Mon, 23-December-2022 12:33</MenuItem>
                    <MenuItem value='EEE dd-MMMM-yyyy HH:mma'>Mon, 23-December-2022 12:33PM</MenuItem>
                    <MenuItem value='EEEE dd-MMMM-yyyy HH:mm'>Monday, 23-December-2022 12:33</MenuItem>
                    <MenuItem value='EEEE dd-MMMM-yyyy HH:mma'>Monday, 23-December-2022 12:33PM</MenuItem>
                    <MenuItem value='EEE, MMM dd, HH:mm'>Mon, Dec 23, 12:33</MenuItem>
                    <MenuItem value='EEE, MMM dd, HH:mma'>Mon, Dec 23, 12:33PM</MenuItem>
                    <MenuItem value='EEE'>Mon</MenuItem>
                    <MenuItem value='EEEE'>Monday</MenuItem>
                </Select>
            </FormControl>
            <br/><br/>
            {this.printLangChoices()}
            <br/><br/>
            {this.printWidgetBGChoices()}
            <br/><br/>
            <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                    <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                    <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                </RadioGroup>
            </FormControl>
        </div>
      )
    }

    printWeatherType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_weather_help")}
            </Typography>
            <br/><br/>
            <Button id="choose_btn" variant='contained' color='primary' onClick={()=>this.showWindow("location_map_div",true)}>{ETVConstants.trans("choose_location")}</Button>
            <br/>
            <TextField id="weather_city_lat" disabled={true} label={ETVConstants.trans("latitude")} size="small" value={this.state.weather_city_lat} onChange={(e)=>{this.setState({weather_city_lat:e.target.value})}}/>
            <br/>
            <TextField id="weather_city_lgt" disabled={true} label={ETVConstants.trans("longitude")} size="small" value={this.state.weather_city_lgt} onChange={(e)=>{this.setState({weather_city_lgt:e.target.value})}}/>
            <br/>
            <TextField id="weather_city_name" label={ETVConstants.trans("weather_city_name")} size="small" value={this.state.weather_city_name} onChange={(e)=>{this.setState({weather_city_name:e.target.value})}}/>
            <br/><br/>
            {this.printWidgetBGChoices()}
            <br/><br/>
            <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                </RadioGroup>
            </FormControl>
        </div>
      )
    }

    printWeather5Type() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
            <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
                {ETVConstants.trans("widget_weather_help")}
            </Typography>
            <br/><br/>
            <Button id="choose_btn" variant='contained' color='primary' onClick={()=>this.showWindow("location_map_div",true)}>{ETVConstants.trans("choose_location")}</Button>
            <br/>
            <TextField id="weather_city_lat" disabled={true} label={ETVConstants.trans("latitude")} size="small" value={this.state.weather_city_lat} onChange={(e)=>{this.setState({weather_city_lat:e.target.value})}}/>
            <br/>
            <TextField id="weather_city_lgt" disabled={true} label={ETVConstants.trans("longitude")} size="small" value={this.state.weather_city_lgt} onChange={(e)=>{this.setState({weather_city_lgt:e.target.value})}}/>
            <br/>
            <TextField id="weather_city_name" label={ETVConstants.trans("weather_city_name")} size="small" value={this.state.weather_city_name} onChange={(e)=>{this.setState({weather_city_name:e.target.value})}}/>
            <br/><br/>
            {this.printWidgetBGChoices()}
            <br/><br/>
            <FormControl component="fieldset">
                <FormLabel component="legend">{ETVConstants.trans("layout")}</FormLabel>
                <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                  <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                  <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                </RadioGroup>
            </FormControl>
        </div>
      )
    }

    printElevatorLandscapeType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
           <FormControl component="fieldset">
              <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
              <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
                <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
              </RadioGroup>
           </FormControl>
        </div>
      )
    }

    printElevatorPortraitType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
           <FormControl component="fieldset">
              <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
              <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                <FormControlLabel value="3_4" control={<Radio />} label={ETVConstants.trans("4_3")} />
                <FormControlLabel value="9_16" control={<Radio />} label={ETVConstants.trans("9_16")} />
                <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
              </RadioGroup>
           </FormControl>
        </div>
      )
    }

    printElevatorFloorPicsType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
         <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
            <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
              <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")}/>
              <FormControlLabel value="3_4" control={<Radio />} label={ETVConstants.trans("3_4")} />
              <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
              <FormControlLabel value="9_16" control={<Radio />} label={ETVConstants.trans("9_16")} />
              <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
              <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
            </RadioGroup>
         </FormControl>
        </div>
      )
    }

    printStaticTextType() {
      return(
        <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
          <Typography style={{color:'gray'}} component={'span'} gutterBottom variant="body2" paragraph={true} >
              {ETVConstants.trans("widget_static_text_help")}
          </Typography>
          <br/>
          <TextField style={{marginTop:10}} id="static_txt_line_1" fullWidth={true} label={ETVConstants.trans("text")} size="small" value={this.state.static_txt_line_1} onChange={(e)=>{this.setState({static_txt_line_1:e.target.value})}}/>
          <br/><br/>
          {this.printWidgetBGChoices()}
          <br/><br/>
          {this.printWidgetTextColorChoices()}
          <br/><br/>
         <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
            <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
              <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")} />
              <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
              <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
              <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
            </RadioGroup>
         </FormControl>
        </div>
      );
    }

    printWidgetTextColorChoices() {
      return(
        <FormControl component="fieldset">
            <FormLabel component="legend">{ETVConstants.trans("widget_text_color")}</FormLabel>
              <input style={{marginTop:'10px'}} type="color" value={this.state.widget_text_color} onChange={(e) => this.setState({widget_text_color:e.target.value})}/>
              <Button style={{marginTop:'5px'}} size='small' variant='outlined' color='primary' onClick={(e)=>{this.setState({widget_text_color:''})}}>set default</Button>
        </FormControl>
      )
    }

    readerBGImageLoaded = (e) => {
      const content = e.target.result;
      //console.log("readerLoaded="+content);
      var image = new Image();
      image.src = e.target.result;
      image.onload = this.bgImageLoaded;
      this.setState({canvas_img:image, canvas_bg:e.target.result,window:''});
    }

    loadBG(selectorFiles) {
      console.log(selectorFiles[0]);
      var file = selectorFiles[0];
      var reader  = new FileReader();
      reader.onloadend = this.readerBGImageLoaded;
      if (file) {
        reader.readAsDataURL(file);
      }
    }

    bgImageLoaded(image) {
      var b0 = this.state.border;
      var w = this.state.canvas_img.width;
      var h = this.state.canvas_img.height;
      var ratio = w/h;
      var canvasResolution = this.estimateResolution(ratio);
      var isLandscape = w>=h?true:false;
      this.setState({isLandscape:isLandscape,canvasResolution:canvasResolution,cWidth:w+2*b0,cHeight:h+2*b0, bgImage:image, canvas_bg_color:'#FFFFFF',items:[],needRedraw:true});
    }

    checkRatio( orig, check) {
      const tol = 0.02;
      var val = Math.abs(orig-check);
      console.log("val="+val);
      return val<tol;
    }

    estimateResolution(ratio) {
      const tol = 0.02;
      if(this.checkRatio(1.33334,ratio)) return 1.33334;
      if(this.checkRatio(0.75,ratio)) return 0.75;
      if(this.checkRatio(1.7778,ratio)) return 1.7778;
      if(this.checkRatio(0.5625,ratio)) return 0.5625;
      if(this.checkRatio(0.625,ratio)) return 0.625;
      if(this.checkRatio(1.6,ratio)) return 1.6;
      if(this.checkRatio(2.05556,ratio)) return 2.05556;
      if(this.checkRatio(1.71,ratio)) return 1.71;
      if(this.checkRatio(1.6,ratio)) return 1.6;
      if(this.checkRatio(1.506,ratio)) return 1.506;
      if(this.checkRatio(1.25,ratio)) return 1.25;
      if(this.checkRatio(0.8,ratio)) return 0.8;
      if(this.checkRatio(0.4865,ratio)) return 0.4865;
      if(this.checkRatio(0.625,ratio)) return 0.625;
      if(this.checkRatio(0.5859375,ratio)) return 0.5859375;
      if(this.checkRatio(0.664,ratio)) return 0.664;
      if(this.checkRatio(1.0,ratio)) return 1.0;
      return -1.0;
    }

    render() {
      var dropDownWidth = 200;
      return (
        <Card>
          <CardContent>
            <table><tbody>
              <tr valign='top'>
                <td style={{width:350}}>
                    <span>
                    <FormControl>
                        <InputLabel component="legend">{ETVConstants.trans("channel_type")}</InputLabel>
                        <Select
                          label={ETVConstants.trans("channel_type")}
                          id="sizeID"
                          style={{width:330}}
                          value={this.state.channel_type}
                          onChange={(e)=>{this.handleChannelTypeChange(e.target.value)}}>
                          <MenuItem value={''}>{ETVConstants.trans("choose_channel_type")}</MenuItem>
                          {this.state.curChannel>4?<span/>:<MenuItem value={'content'}>{ETVConstants.trans("content")}</MenuItem>}
                          <MenuItem value={'streaming'}>{ETVConstants.trans("ip_streaming")}</MenuItem>
                          {this.state.curElevFrag>0?<span/>:<MenuItem value={'elevator_landscape'} >{ETVConstants.trans("floor_indicator_landscape")}</MenuItem>}
                          {this.state.curElevFrag>0?<span/>:<MenuItem value={'elevator_portrait'} >{ETVConstants.trans("floor_indicator_portrait")}</MenuItem>}
                          <MenuItem value={'time1'}>{ETVConstants.trans("time_fragment_simple")}</MenuItem>
                          <MenuItem value={'time2'}>{ETVConstants.trans("time_fragment_2_lines")}</MenuItem>
                          <MenuItem value={'time3'}>{ETVConstants.trans("time_fragment")}</MenuItem>
                          <MenuItem value={'weather'}>{ETVConstants.trans("current_weather")}</MenuItem>
                          <MenuItem value={'weather5'}>{ETVConstants.trans("weather_forecast")}</MenuItem>
                          <MenuItem value={'floorpics'}>{ETVConstants.trans("floor_information")}</MenuItem>
                          <MenuItem value={'tripcounter'}>{ETVConstants.trans("trip_counter")}</MenuItem>
                          <MenuItem value={'statictext'}>{ETVConstants.trans("static_text")}</MenuItem>
                          <MenuItem value={'qrcode'}>{ETVConstants.trans("qr_code")}</MenuItem>
                          <MenuItem value={'arrow'}>{ETVConstants.trans("arrow")}</MenuItem>
                          <MenuItem value={'door'}>{ETVConstants.trans("door")}</MenuItem>
                          <MenuItem value={'floornumber'}>{ETVConstants.trans("floornumber")}</MenuItem>
                          <MenuItem value={'next_stop'}>{ETVConstants.trans("next_stop")}</MenuItem>
                          <MenuItem value={'floor_directory'}>{ETVConstants.trans("floor_directory")}</MenuItem>
                          <MenuItem value={'floor_directory_noscrolling'}>{ETVConstants.trans("floor_directory_noscrolling")}</MenuItem>
                        </Select>
                    </FormControl>
                    </span>
                    <br/>
                    <br/>
                    { this.state.channel_type==='streaming'? this.printStreamingType():
                      this.state.channel_type==='arrow'? this.printArrowType():
                      this.state.channel_type==='door'? this.printDoorType():
                      this.state.channel_type==='qrcode'? this.printQRType():
                      this.state.channel_type==='floornumber'? this.printFloorNumberType():
                      this.state.channel_type==='next_stop'? this.printNextStopType():
                      this.state.channel_type==='floor_directory_noscrolling'? this.printFloorDirectoryNoScrollingType():
                      this.state.channel_type==='floor_directory'? this.printFloorDirectoryType():
                      this.state.channel_type==='time3'? this.printTime3Type():
                      this.state.channel_type==='time2'? this.printTime2Type():
                      this.state.channel_type==='time1'? this.printTimeSimpleType():
                      this.state.channel_type==='weather'? this.printWeatherType():
                      this.state.channel_type==='weather5'? this.printWeather5Type():
                      this.state.channel_type==='elevator_landscape'? this.printElevatorLandscapeType():
                      this.state.channel_type==='elevator_portrait'? this.printElevatorPortraitType():
                      this.state.channel_type==='floorpics'? this.printElevatorFloorPicsType():
                      this.state.channel_type==='statictext'? this.printStaticTextType():
                      this.state.channel_type==='tripcounter'? this.printTripCounterType():
                      <div style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD', marginRight:20, padding:10}}>
                      <FormControl component="fieldset">
                          <FormLabel component="legend">{ETVConstants.trans("channel_layout")}</FormLabel>
                          <RadioGroup aria-label="format" name="format1" value={this.state.channelLayout} onChange={(e) => {this.setState({channelLayout:e.target.value})}}>
                            <FormControlLabel value="4_3" control={<Radio />} label={ETVConstants.trans("4_3")}/>
                            <FormControlLabel value="3_4" control={<Radio />} label={ETVConstants.trans("3_4")} />
                            <FormControlLabel value="16_9" control={<Radio />} label={ETVConstants.trans("16_9")} />
                            <FormControlLabel value="9_16" control={<Radio />} label={ETVConstants.trans("9_16")} />
                            <FormControlLabel value="1_1" control={<Radio />} label={ETVConstants.trans("square")} />
                            <FormControlLabel value="banner" control={<Radio />} label="Banner (468x60)" />
                            <FormControlLabel value="leaderboard" control={<Radio />} label="Leaderboard (728x90)" />
                            <FormControlLabel value="inline_rectangle" control={<Radio />} label="Inline Rectangle (300x250)" />
                            <FormControlLabel value="skyscraper" control={<Radio />} label="Skyscraper (120x600)" />
                            <FormControlLabel value="half_page_ad" control={<Radio />} label="Half Page Ad (300x600)" />
                            <FormControlLabel value="large_leaderboard" control={<Radio />} label="Large Leaderboard (970x90)" />
                            <FormControlLabel value="freestyle" control={<Radio />} label={ETVConstants.trans("freestyle")} />
                            <FormControlLabel value="raster" control={<Radio />} label={ETVConstants.trans("raster")} />
                          </RadioGroup>
                      </FormControl>
                      </div>
                    }
                    <br/>
                </td>
                <td>
                     <div>
                        <div>
                             <FormControl>
                                <InputLabel component="legend">{ETVConstants.trans("screen_resolution")}</InputLabel>
                                <Select
                                  label={ETVConstants.trans("screen_resolution")}
                                  id="sizeID"
                                  style={{ width: dropDownWidth}}
                                  value={this.state.canvasResolution}
                                  onChange={this.handleCanvasResolutionChange}>
                                  <MenuItem value={1.33334}>4:3 landscape</MenuItem>
                                  <MenuItem value={0.75}>4:3 portrait</MenuItem>
                                  <MenuItem value={1.7778}>16:9 landscape </MenuItem>
                                  <MenuItem value={0.5625}>16:9 portrait </MenuItem>
                                  <MenuItem value={0.625}>16:10 portrait </MenuItem>
                                  <MenuItem value={1.6}>16:10 landscape </MenuItem>
                                  <MenuItem value={2.05556}>740 x 360 (ratio:2.06)</MenuItem>
                                  <MenuItem value={1.6}>960 x 600 (ratio:1.6)</MenuItem>
                                  <MenuItem value={1.33334}>1024 x 768  (4:3)</MenuItem>
                                  <MenuItem value={1.71}>1280 x 750</MenuItem>
                                  <MenuItem value={1.6}>1280 x 800 (16:10)</MenuItem>
                                  <MenuItem value={1.506}>1280 x 850 (ratio:1.506)</MenuItem>
                                  <MenuItem value={1.25}>1280 x 1024 (5:4) 19 inch</MenuItem>
                                  <MenuItem value={0.8}>1024 x 1280 (4:5) 19 inch</MenuItem>
                                  <MenuItem value={1.7778}>1920 x 1080</MenuItem>
                                  <MenuItem value={0.4865}>360 x 740 (ratio:2.06)</MenuItem>
                                  <MenuItem value={0.625}>600 x 960 (ratio:1.6)</MenuItem>
                                  <MenuItem value={0.5859375}>750 x 1280</MenuItem>
                                  <MenuItem value={0.75}>768 x 1024 (3:4)</MenuItem>
                                  <MenuItem value={0.625}>800 x 1280 (10:16)</MenuItem>
                                  <MenuItem value={0.664}>850 x 1280 (ratio:1.506)</MenuItem>
                                  <MenuItem value={0.8}>1024 x 1280 (ratio:0.8)</MenuItem>
                                  <MenuItem value={0.5625}>1080 x 1920</MenuItem>
                                  <MenuItem value={1.0}>Square (special screen)</MenuItem>
                                  <MenuItem value={-1}>{ETVConstants.trans("unknown")}</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl style={{marginLeft:20}}>
                                  <FormLabel component="legend" style={{marginBottom:10}}>{ETVConstants.trans("background_helper")}</FormLabel>
                                  <input type="file" onChange={ (e) => this.loadBG(e.target.files) } style={{ width:200}} />
                              </FormControl>
                          </div>
                          <canvas ref="canvas" width={this.state.cWidth} height={this.state.cHeight} style={{border:"1px solid lightgrey", backgroundColor:'#FDFDFD',marginTop:20}}
                                onClick={this.handleCanvasClick}
                                tabIndex="0"
                                onKeyDown={this.onKeyDown}
                                onMouseOut={this.onMouseOut}
                                onMouseMove={this.onMouseMove} />
                     </div>
                </td>
              </tr>
             </tbody></table>

             <Dialog
               open={this.state.window==='error_message_div'}
               maxWidth='xs'
               fullWidth={true}
               onClose={()=>{this.closeErrorMessage()}}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description">
               <DialogTitle id="alert-dialog-title">{ETVConstants.trans("error")}</DialogTitle>
               <DialogContent>
                  <Typography variant='body1'>{this.state.error_message}</Typography>
               </DialogContent>
               <DialogActions>
                 <Button onClick={()=>{this.closeErrorMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
               </DialogActions>
             </Dialog>

             <Dialog
               open={this.state.window==='message_div'}
               maxWidth='xs'
               fullWidth={true}
               onClose={()=>{this.closeInfoMessage()}}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description">
               <DialogTitle id="alert-dialog-title">{ETVConstants.trans("info")}</DialogTitle>
               <DialogContent>
                  <Typography variant='body1'>{this.state.info_message}</Typography>
               </DialogContent>
               <DialogActions>
                 <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
               </DialogActions>
             </Dialog>

             <Dialog
   							open={this.state.window==='location_map_div'}
   							onClose={()=>{}}
   							fullWidth={true}
   							maxWidth='xl'
   							scroll='paper'
   							aria-labelledby="alert-dialog-title"
   							aria-describedby="alert-dialog-description">
   							<DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_location")}</DialogTitle>
   							<DialogContent>
   									<div style={{height:800,maxHeight:800}}>
   										{this.state.window==='location_map_div'?<LocationMap lat={this.state.weather_city_lat} lgt={this.state.weather_city_lgt} setLocationGPS={this.setLocationGPS} />:<span/>}
   									</div>
   							</DialogContent>
   							<DialogActions>
   									<Button onClick={()=>{this.setState({window:this.state.selected_group_id<0?'edit_location_div':'edit_group_location_div'})}} color="primary" autoFocus>{ETVConstants.trans("close")}</Button>
   							</DialogActions>
 						</Dialog>

          </CardContent>
          <CardActions>
              {this.state.items.length>0?<Button color="primary" onClick={() => this.stepBack()}>{ETVConstants.trans("back")}</Button>:<span/>}
              <Button color="primary" startIcon={<RestartAlt/>} onClick={() => this.clearChannels()}>{ETVConstants.trans("reset")}</Button>
              {this.state.items.length>0?<Button color="primary" size="small" startIcon={<Upload/>} variant='contained' onClick={() => this.finalize()}>{ETVConstants.trans("upload")}</Button>:<span/>}
          </CardActions>
        </Card>
      )
    }
}

export default LayoutDesigner;
