import React from 'react';
import { Button } from '@mui/material';
import { Image } from '@mui/icons-material';

class LoginWithCanvaButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupWindow: null, // To track the popup
    };
  }

  handleOpenPopup = () => {
      console.log("handleOpenPopUp");
      const { popupWindow } = this.state;

      // Check if popup is already open and not closed
      if (popupWindow && !popupWindow.closed) {
        //console.log("Popup already open. Focusing...");
        popupWindow.focus();
        return;
      }

      // Open the popup window
      const newPopup = window.open(
        '/canva-popup',
        'CanvaPopup',
        'width=600,height=700'
      );

      if (newPopup) {
          this.setState({ popupWindow: newPopup });
          // Listen for messages from the popup
          window.addEventListener('message', this.handlePopupMessage, false);
      } else {
        //console.log("popup could not be opened. please disable popup blockers");
        alert("Popup could not be opened. Please disable popup blockers.");
      }
  };

  handlePopupMessage = (event) => {
      const { onAccessTokenReceived } = this.props;
      // Validate message origin (important for security)
      if (event.origin !== window.location.origin) {
        //alert("untrusted origin");
        console.warn("Untrusted origin:", event.origin);
        console.log("xdata="+JSON.stringify(event.data));
        return;
      }

      const { tokenData } = event.data;
      console.log("tokenData="+tokenData+" tokendata="+JSON.stringify(tokenData));
      if (tokenData) {
        console.log("---->Access token received:", tokenData);
        this.props.onAccessTokenReceived(tokenData);
        // Clean up
        window.removeEventListener('message', this.handlePopupMessage, false);
        if (this.state.popupWindow) {
            this.state.popupWindow.close();
            this.setState({ popupWindow: null });
        }
      }else{
        console.log("xx no tokendata");
      }
  };

  render() {
    return (
      <Button 
          size='large' 
          startIcon={<img src='/logo/Canva_Icon_logo.svg' width='35' height='auto'/>} 
          variant="outlined" 
          onClick={this.handleOpenPopup}
          style={{
              //backgroundColor: '#00c4cc', // Canva brand color
              color: '#00c4cc',
              textTransform: 'none', // Prevent uppercase text transformation
              fontWeight: 'bold',
              fontSize: '16px',
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px', // Space between text and logo
              borderRadius: '5px',
              cursor: 'pointer',
          }}
          //onMouseOver={(e) => (e.target.style.backgroundColor = '#008f94')} // Hover effect
          //onMouseOut={(e) => (e.target.style.backgroundColor = '#00c4cc')} // Revert hover effect
      >
        Canva Import
      </Button>
    );
  }
}

export default LoginWithCanvaButton;