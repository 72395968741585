import React from 'react';
import BasicTable2 from '../tools/BasicTable2';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Delete from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import ExtraAccessScreens from './ExtraAccessScreens';
import RolesList from './RolesList.js';
import StatusSetting from './StatusSetting';
import Tooltip from '@mui/material/Tooltip';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import StarIcon from '@mui/icons-material/Star';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import GroupIcon from '@mui/icons-material/Group';
import ImageIcon from '@mui/icons-material/Image';
import CollectionsIcon from '@mui/icons-material/Collections';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Face, LockReset, Person, PersonAdd, Save, ScreenShare, Security, VpnKey } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import * as ETVConstants from '../ETVConstants';
import LoadingDialog from '../tools/LoadingDialog.js';
import MessageDialog from '../tools/MessageDialog.js';

class Create3rdPartyUserTable extends React.Component {

	constructor(props) {
      	super(props);
	    this.insertUser = this.insertUser.bind(this);
		this.closeScreenSelection = this.closeScreenSelection.bind(this);
		this.closeStatusSetting = this.closeStatusSetting.bind(this);
		this.closeInfoMessage = this.closeInfoMessage.bind(this);
    }

    state = {
      	isLoaded: false,
		isLoading: false,
      	items: [],
		window:undefined,
		changePasswordValue:'',
		change_pw_helper_ok:true,
		new_user_email: '',
		new_user_firstname: '',
		new_user_lastname: '',
		new_user_pw1:'',
		new_user_pw2:'',
		info_message:'',
		info_title:'',
		info_type:'',
		selected_screens:[],
		all_roles:[],
		new_user_email_helper_ok:true,
		new_user_pw_helper_ok:true,
		new_user_pw2_helper_ok:true,
		selected_user: undefined,
		selected_user_object: undefined,
		showDeleteUserDialog: false,
		deleteUserID: undefined,
		otp_phone:'',
		otp_phone_helper_ok:true,
		otp_enabled:false,
		use_secure_pw:false,
		change_userid_value:'',
		change_userid_helper_ok:true,
		selected_new_parent_id:-2,
		root_user_id:-1,
		root_user_name:'',
    }

    handleUsers(items) {
      	var data = [];
		if(!items || items===null || items.length===0) {
			return data;
		}
      	for(var i=0; i<items.length; i++) {
			var isTechnician = items[i].status === 'service_technician';
			//console.log("----->serviceTechnician="+isTechnician);
			var item = items[i];
	        item["row_id"]=item["user_id"];
	        if(!isTechnician) {
				data.push(item);
			}
      	}
      	return data;
    }

    componentDidMount() {
		this.loadAllRoles();
    }

	componentDidUpdate() {
		if(!this.state.isLoaded && !this.state.isLoading) {
			this.loadUsers();
		}
	}

	loadAllRoles() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/useradmin?user_id="+this.props.user_id+"&action=get_roles";
	    fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        	.then(res => res.json())
        	.then(
				(result) => {
					var allR = result["roles"];
					this.setState({all_roles:allR})
				}
        )
	}

    loadUsers() {
		this.setState({isLoading:true});
		//console.log("----- load users -----");
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/useradmin?action=list_children&user_id="+this.props.user_id;
		//console.log("---------------------loading data, url="+urlStr);
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var rx = this.handleUsers(result.children);
            this.setState({
              	isLoaded: true,
				isLoading:false,
              	items: rx
            });
          },
          (error) => {
            this.setState({
              	items:[],
				isLoaded: true,
				isLoading:false,
              	error
            });
          }
        )
    }

	sendCMDToServer(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({
					isLoaded: false
				})
			})
			.catch((error) => {
				console.error(error);
			});
    }

    blockUser( userID, value) {
		var url = ETVConstants.getServerBase()+'/useradmin?user_id='+userID;
		if(value) {
			url += "&action=block";
		}else{
			url += "&action=unblock";
		}
		this.sendCMDToServer(url);
	}

    deleteUser( userID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/useradmin?action=delete&user_id="+userID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
					//console.log(JSON.stringify(responseJson));
					if (!responseJson["result"]) {
						if(responseJson["reason"]==='has_children') {
							this.showErrorMessage(ETVConstants.trans("error"), ETVConstants.trans("can_not_delete_a_user_who_has_children"));
						}else{
							this.showErrorMessage(ETVConstants.trans("error"),  responseJson['reason']);
						}
					}else{
						this.setState({
								isLoaded: false, showDeleteUserDialog:false,
						})
					}
		})
		.catch((error) => {
				console.error(error);
		});
	}

    insertUser() {
      	var username = this.state.new_user_email.trim();
		if(!ETVConstants.isEmail(username)) {
			this.setState({new_user_email_helper_ok:false});
			return;
		}
		var pw = this.state.new_user_pw1.trim();
		if(pw.length<6) {
			this.setState({new_user_pw_helper_ok:false});
			return;
		}
		var pw2 = this.state.new_user_pw2.trim();
		if(pw2!==pw) {
			this.setState({new_user_pw2_helper_ok:false});
			return;
		}
      	var firstname = this.state.new_user_firstname.trim();
      	var lastname = this.state.new_user_lastname.trim();
      	var parentID = this.props.user_id;
      	this.addUser(username,firstname,lastname,pw,parentID );
		this.showWindow('add_user',false);
    }

    addUser( username, firstname, lastname, password, parentID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
      	//console.log("adding user id="+username+"/firstname="+firstname+" last="+lastname+" pw="+password+" parentID="+parentID);
		var url = ETVConstants.getServerBase()+'/useradmin?action=add_user&username='+username+'&parent_id='+parentID+'&firstname='+firstname+'&lastname='+lastname+'&password='+password;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
      	.then((response) => response.json())
      	.then((result) => {
			//console.log("---> "+JSON.stringify(result));
			if(result["error"]) {
				//console.log(JSON.stringify(result));
				this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("user_exists_already"));
				return;
			}
         	this.setState({
	            isLoaded: false,
				new_user_email:'',
				new_user_firstname:'',
				new_user_lastname:'',
				new_user_pw1:'',
				new_user_pw2:'',
         	});
      })
      .catch((error) => {
         	this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("internal_error_happend"));
      });
    }

	activateUser( userID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/useradmin?action=activate_user_id&user_id='+userID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
				this.setState({
					isLoaded: false,
				})
		})
		.catch((error) => {
				//console.log("Error="+error);
		});
	}

	pwChange(userID) {
		this.setState( state => ({
			selected_user:userID,
		}));
		this.showWindow('show_pw_change',true);
	}

	userIDChange(user) {
		this.setState({selected_user:user.user_id,change_userid_value:user.user_name});
		this.showWindow('show_userid_change',true);
	}

	submitNewPW(userID) {
		var newPW = this.state.changePasswordValue;
		if(!newPW || newPW.trim().length<6) {
			this.setState({change_pw_helper_ok:false});
			return;
		}

		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/useradmin?action=change_password&user_id='+userID+"&password="+encodeURIComponent(newPW);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
			//console.log(JSON.stringify(responseJson));
			if(responseJson["result"]===true) {
				this.setState({changePasswordValue:''});
				this.showSuccessMessage(ETVConstants.trans("success"),ETVConstants.trans("password_successfully_changed"));
			}else{
				this.setState({changePasswordValue:''});
				this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans(responseJson["reason"]));
			}
		})
		.catch((error) => {
				//console.log("Error="+error);
		});
	}

	submitNewUserID(userID) {
		var newUserID = this.state.change_userid_value;
		//console.log("check submit new userID, userID="+userID+" /new:"+newUserID);
		if(!newUserID || !ETVConstants.isEmail(newUserID)) {
			this.setState({change_userid_helper_ok:false});
			return;
		}
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/useradmin?action=change_user_id&user_id='+userID+"&new_user_id="+encodeURIComponent(newUserID);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
				//console.log(JSON.stringify(responseJson));
				if(responseJson["result"]===true) {
					this.setState({change_userid_value:'',isLoaded:false});
					this.showSuccessMessage(ETVConstants.trans("success"),ETVConstants.trans("user_id_successfully_changed"));
				}else if(responseJson['reason']==='user_already_exists') {
					this.setState({change_userid_value:''});
					this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("user_exists_already"));
				}else{
					this.setState({change_userid_value:''});
					this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_user_id"));
				}
		})
		.catch((error) => {
				//console.log("Error="+error);
		});
	}

	showWindow(divID, show) {
		if(show) {
			this.setState({window:divID});
		}else{
			this.setState({window:''});
		}
	}

	getSelectedUser() {
		return this.state.selected_user;
	}

	closeScreenSelection() {
		this.showWindow('extra_screen_access_div',false);
	}

	closeStatusSetting( needReloadUsers) {
		this.showWindow('show_status_div',false);
		this.setState({isLoaded:!needReloadUsers});
	}

	showInfoMessage(title,msg) {
		this.setState({window:'message_div',info_type:'info',info_title:title,info_message:msg});
  	}

  	showSuccessMessage(title,msg) {
	  this.setState({window:'message_div',info_type:'success',info_title:title,info_message:msg});
	}

	showErrorMessage(title,msg) {
	  this.setState({window:'message_div',info_type:'error',info_title:title,info_message:msg});
	}

  	closeInfoMessage() {
		this.setState({window:'',info_type:'',info_title:'',info_message:''});
  	}

	showScreenAccess(userID) {
		this.setState({selected_user:userID});
		this.showWindow('extra_screen_access_div',true);
	}

	showCyberSecurity(user) {
		this.setState({selected_user:user.user_id, otp_phone:user.phone, otp_enabled:user.use_2_factor_authentication, use_secure_pw:user.use_secure_pw});
		this.showWindow('cyber_security_div',true);
	}

	showRoles(userID) {
		this.setState({selected_user:userID});
		this.showWindow('show_roles',true);
	}

	statusChange(rowData) {
		this.setState({selected_user:rowData.user_id, selected_user_object:rowData});
		this.showWindow('show_status_div',true);
	}

	getUserStatusIcon( rowData) {

		var isOffline = rowData.status === 'offline';
		if (isOffline) return <Tooltip title="Offline User"><CloudOffIcon/></Tooltip>;

		var isBasic = rowData.status === 'basic';
		if(isBasic) return <Tooltip title="Basic User"><CloudDoneIcon/></Tooltip>;

		var isPremium = rowData.status === 'premium';
		if(isPremium) return <Tooltip title="Premium User"><StarIcon style={{ color: '#FFC750' }}/></Tooltip>;

		var isEnterprise = rowData.status === 'enterprise';
		if(isEnterprise) return <Tooltip title="Enterprise User"><StarIcon style={{ color: '#FFC750' }}/></Tooltip>;

		var isOem = rowData.status === 'oem';
		if(isOem) return <Tooltip title="OEM"><GroupIcon/></Tooltip>;

		var isCP = rowData.status === 'content_provider';
		if(isCP) return <Tooltip title='Content Provier'><ImageIcon /></Tooltip>;

		if(rowData.status==='marketplace_provider') return <Tooltip title='Marketplace Provier'><CollectionsIcon /></Tooltip>;
		if(rowData.status==='superadmin') return <Tooltip title='Superadmin'><SupervisedUserCircleIcon /></Tooltip>;

		return <span/>;
	}

	saveCyberSecuritySettings() {
		var phone = this.state.otp_phone.trim();
		if(this.state.otp_enabled) {
			if(!ETVConstants.isPhone(phone)) {
				this.setState({otp_phone_helper_ok:false});
				return;
			}
		}else{
			// otp not enabled --> phone === 0 or then correct
			if(phone.trim().length>0 && !ETVConstants.isPhone(phone)) {
				this.setState({otp_phone_helper_ok:false});
				return;
			}
		}
		phone = phone.replace(/\s/g, '');
		var url = ETVConstants.getServerBase()+"/useradmin?action=update_2_factor_authentication&phone="+encodeURIComponent(phone)+"&user_id="+this.state.selected_user+"&use_2_factor_authentication="+this.state.otp_enabled+"&use_secure_pw="+this.state.use_secure_pw;
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		//console.log(url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((result) => {
				if(result["result"]!==true) {
					this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_cybersecurity_settings"));
				}else{
					this.setState({isLoaded:false, otp_enabled:false, use_safe_pw:false, otp_phone_helper_ok:true, otp_phone:'', window:''});
				}
		})
		.catch((error) => {
				//console.log(error);
				//this.showInfoMessage("Error",error);
		});
	}

	changeParent( rowData) {
		//console.log(JSON.stringify(rowData));
		var rt = localStorage.getItem("etv.user_id");
		var rtn = localStorage.getItem("etv.user_name");
		this.setState({root_user_id:rt, root_user_name:rtn, selected_user: rowData.user_id, selected_new_parent_id:rowData.parent_id});
		this.showWindow("change_parent_div",true);
	}

	showAddUserWindow() {
		this.setState({new_user_pw1:'',new_user_pw2:'',new_user_name:'',new_user_firstname:'',new_user_lastname:''});
		this.showWindow('add_user',true);
	}

	submitNewParent() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/useradmin?action=change_parent&user_id='+this.state.selected_user+"&parent_id="+this.state.selected_new_parent_id;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
				//console.log(JSON.stringify(responseJson));
				if(responseJson["result"]===true) {
					this.showSuccessMessage(ETVConstants.trans("success"),"parent changed");
					this.setState({isLoaded:false});
				}else{
					this.showErrorMessage(ETVConstants.trans("error"),responseJson["reason"]);
				}
		})
		.catch((error) => {
				//console.log("Error="+error);
		});
	}

	getUserName( isSuperAdmin, name) {
		return name;
	}

	getUserNameFromID( id) {
		//console.log("id:"+id+" rootID="+this.state.root_user_id+" rootName="+this.state.root_user_name+" equal="+(id===this.state.root_user_id)+" typeOf1="+typeof(id)+" typeOf2="+typeof(this.state.root_user_id));
		//note: root_user_id is string
		if(!this.state.items) return "-";
		if(parseInt(id)===parseInt(this.state.root_user_id)) return this.state.root_user_name;
		for(var i=0; i<this.state.items.length; i++) {
			if(this.state.items[i].user_id===id) return this.state.items[i].user_name;
		}
		return "--";
	}

    render() {
		
		if(!this.state.items || !this.state.isLoaded) {
			return <LoadingDialog open={!this.state.isLoaded} message={ETVConstants.trans("loading")}/>
		}

		var status = this.props.status;
		var isSuperAdmin = (status==='superadmin');
		var isOEM = (status==='oem');

		var columnsData= [
			{ title: '', field: 'status', render: rowData => this.getUserStatusIcon(rowData)},
          	{ title: ETVConstants.trans("username"), field: 'user_name', render: rowData => <span>{rowData.user_name} {isSuperAdmin?" ["+rowData.user_id+"]":""}</span>},
			{ title: ETVConstants.trans("firstname"), field: 'firstname'},
			{ title: ETVConstants.trans("lastname"), field: 'lastname'},
			{ title: ETVConstants.trans("phone"), field: 'phone'},
			{ title: ETVConstants.trans("cyber_security"), field: '', render: rowData => <Button size='small' startIcon={<Security/>} variant='contained' color={rowData.use_2_factor_authentication || rowData.use_secure_pw?'secondary':'primary'} onClick={()=>this.showCyberSecurity(rowData)}>{rowData.use_2_factor_authentication || rowData.use_secure_pw?ETVConstants.trans("disable"):ETVConstants.trans("enable")}</Button>},
			{ title: ETVConstants.trans("password"), field: 'pw_change', render: rowData => <Button size='small' startIcon={<LockReset/>} variant='contained' color='primary' onClick={()=>this.pwChange(rowData.user_id)}>{ETVConstants.trans("change")}</Button>},
			{ title: ETVConstants.trans("screen_access"), field: 'screen_access', render: rowData => <Button size='small' startIcon={<ScreenShare/>} variant='contained' color='primary' onClick={()=>this.showScreenAccess(rowData.user_id)}>{ETVConstants.trans("screens")}</Button>},
			{ title: ETVConstants.trans("access_rights"), field: 'access_rights', render: rowData => <Button size='small' startIcon={<VpnKey/>} variant='contained' color='primary' onClick={()=>this.showRoles(rowData.user_id)}>{ETVConstants.trans("rights")}</Button>},
			{ title: ETVConstants.trans("access"), field: 'blocked', render: rowData => !rowData.active?<Tooltip title={ETVConstants.trans("click_to_unblock_user")}><IconButton size='small' style={{color:'red'}} onClick={()=>this.blockUser(rowData.user_id,false)}><BlockIcon/></IconButton></Tooltip>:<Tooltip title={ETVConstants.trans("block_user")}><IconButton size='small' style={{color:'green'}} onClick={()=>this.blockUser(rowData.user_id,true)}><CheckCircleIcon/></IconButton></Tooltip>},
			{ title: ETVConstants.trans("activation"), field: 'actvation', render: rowData => !rowData.activation?<Tooltip title={ETVConstants.trans("click_to_activate_user")}><IconButton style={{color:'red'}} size='small' label={ETVConstants.trans("activate_user")} onClick={()=>this.activateUser(rowData.user_id)}><ReportProblemOutlinedIcon/></IconButton></Tooltip>:<HowToRegIcon style={{color:'green'}}/>},
			{ title: ETVConstants.trans("delete"), field: 'actions', render: rowData => <IconButton size='small' label='delete' variant='contained' color='error' onClick={()=>this.setState({showDeleteUserDialog:true, deleteUserID:rowData.user_id})}><Delete/></IconButton>},
        ];


		if(isSuperAdmin) {
			columnsData= [
				{ title: '', field: 'status', render: rowData => this.getUserStatusIcon(rowData)},
	          	{ title: ETVConstants.trans("username"), field: 'user_name', render: rowData => <span>{rowData.user_name} {isSuperAdmin?" ["+rowData.user_id+"]":""}</span>},
				{ title: ETVConstants.trans("firstname"), field: 'firstname'},
				{ title: ETVConstants.trans("lastname"), field: 'lastname'},
				{ title: ETVConstants.trans("phone"), field: 'phone'},
				{ title: ETVConstants.trans("cyber_security"), field: '', render: rowData => <Button size='small' startIcon={<Security/>} variant='contained' color={rowData.use_2_factor_authentication || rowData.use_secure_pw?'secondary':'primary'} onClick={()=>this.showCyberSecurity(rowData)}>{rowData.use_2_factor_authentication || rowData.use_secure_pw?ETVConstants.trans("disable"):ETVConstants.trans("enable")}</Button>},
				{ title: ETVConstants.trans("password"), field: 'pw_change', render: rowData => <Button size='small' startIcon={<LockReset/>} variant='contained' color='primary' onClick={()=>this.pwChange(rowData.user_id)}>{ETVConstants.trans("change")}</Button>},

				// superadmin functions
				{ title: ETVConstants.trans("change_user_id"), field: '', render: rowData => <Button size='small' variant='contained' color='error' onClick={()=>this.userIDChange(rowData)}>{ETVConstants.trans("change")}</Button>},
				{ title: ETVConstants.trans("status"), field: 'status_change', render: rowData => <Button size='small' variant='contained' color='error' onClick={()=>this.statusChange(rowData)}>{ETVConstants.trans("status")}</Button>},
				{ title: ETVConstants.trans("change_parent"), field: '', render: rowData => <Button size='small' variant='contained' startIcon={<Person/>} color='error' onClick={()=>this.changeParent(rowData)}>{ETVConstants.trans("parent")}</Button>},

				{ title: ETVConstants.trans("screen_access"), field: 'screen_access', render: rowData => <Button size='small' startIcon={<ScreenShare/>} variant='contained' color='primary' onClick={()=>this.showScreenAccess(rowData.user_id)}>{ETVConstants.trans("screens")}</Button>},
				{ title: ETVConstants.trans("access_rights"), field: 'access_rights', render: rowData => <Button size='small' startIcon={<VpnKey/>} variant='contained' color='primary' onClick={()=>this.showRoles(rowData.user_id)}>{ETVConstants.trans("rights")}</Button>},
				{ title: ETVConstants.trans("access"), field: 'blocked', render: rowData => !rowData.active?<Tooltip title={ETVConstants.trans("click_to_unblock_user")}><IconButton size='small' style={{color:'red'}} onClick={()=>this.blockUser(rowData.user_id,false)}><BlockIcon/></IconButton></Tooltip>:<Tooltip title={ETVConstants.trans("block_user")}><IconButton size='small' style={{color:'green'}} onClick={()=>this.blockUser(rowData.user_id,true)}><CheckCircleIcon/></IconButton></Tooltip>},
				{ title: ETVConstants.trans("activation"), field: 'actvation', render: rowData => !rowData.activation?<Tooltip title={ETVConstants.trans("click_to_activate_user")}><IconButton style={{color:'red'}} size='small' label={ETVConstants.trans("activate_user")} onClick={()=>this.activateUser(rowData.user_id)}><ReportProblemOutlinedIcon/></IconButton></Tooltip>:<HowToRegIcon style={{color:'green'}}/>},
				{ title: ETVConstants.trans("delete"), field: 'actions', render: rowData => <IconButton size='small' label='delete' variant='contained' color='error' onClick={()=>this.setState({showDeleteUserDialog:true, deleteUserID:rowData.user_id})}><Delete/></IconButton>},
	        ];
		}

		if(isOEM) {
			columnsData= [
				{ title: '', field: 'status', render: rowData => this.getUserStatusIcon(rowData)},
	          	{ title: ETVConstants.trans("username"), field: 'user_name', render: rowData => <span>{rowData.user_name} {isSuperAdmin?" ["+rowData.user_id+"]":""}</span>},
				{ title: ETVConstants.trans("firstname"), field: 'firstname'},
				{ title: ETVConstants.trans("lastname"), field: 'lastname'},
				{ title: ETVConstants.trans("phone"), field: 'phone'},
				{ title: ETVConstants.trans("cyber_security"), field: '', render: rowData => <Button size='small' startIcon={<Security/>} variant='contained' color={rowData.use_2_factor_authentication || rowData.use_secure_pw?'secondary':'primary'} onClick={()=>this.showCyberSecurity(rowData)}>{rowData.use_2_factor_authentication || rowData.use_secure_pw?ETVConstants.trans("disable"):ETVConstants.trans("enable")}</Button>},
				{ title: ETVConstants.trans("password"), field: 'pw_change', render: rowData => <Button size='small' startIcon={<LockReset/>} variant='contained' color='primary' onClick={()=>this.pwChange(rowData.user_id)}>{ETVConstants.trans("change")}</Button>},

				// oem functions
				{ title: ETVConstants.trans("change_user_id"), field: '', render: rowData => <Button size='small' variant='contained' color='error' onClick={()=>this.userIDChange(rowData)}>{ETVConstants.trans("change")}</Button>},
				{ title: ETVConstants.trans("status"), field: 'status_change', render: rowData => <Button size='small' variant='contained' color='error' onClick={()=>this.statusChange(rowData)}>{ETVConstants.trans("status")}</Button>},

				{ title: ETVConstants.trans("screen_access"), field: 'screen_access', render: rowData => <Button size='small' startIcon={<ScreenShare/>} variant='contained' color='primary' onClick={()=>this.showScreenAccess(rowData.user_id)}>{ETVConstants.trans("screens")}</Button>},
				{ title: ETVConstants.trans("access_rights"), field: 'access_rights', render: rowData => <Button size='small' startIcon={<VpnKey/>} variant='contained' color='primary' onClick={()=>this.showRoles(rowData.user_id)}>{ETVConstants.trans("rights")}</Button>},
				{ title: ETVConstants.trans("access"), field: 'blocked', render: rowData => !rowData.active?<Tooltip title={ETVConstants.trans("click_to_unblock_user")}><IconButton size='small' style={{color:'red'}} onClick={()=>this.blockUser(rowData.user_id,false)}><BlockIcon/></IconButton></Tooltip>:<Tooltip title={ETVConstants.trans("block_user")}><IconButton size='small' style={{color:'green'}} onClick={()=>this.blockUser(rowData.user_id,true)}><CheckCircleIcon/></IconButton></Tooltip>},
				{ title: ETVConstants.trans("activation"), field: 'actvation', render: rowData => !rowData.activation?<Tooltip title={ETVConstants.trans("click_to_activate_user")}><IconButton style={{color:'red'}} size='small' label={ETVConstants.trans("activate_user")} onClick={()=>this.activateUser(rowData.user_id)}><ReportProblemOutlinedIcon/></IconButton></Tooltip>:<HowToRegIcon style={{color:'green'}}/>},
				{ title: ETVConstants.trans("delete"), field: 'actions', render: rowData => <IconButton size='small' label='delete' variant='contained' color='error' onClick={()=>this.setState({showDeleteUserDialog:true, deleteUserID:rowData.user_id})}><Delete/></IconButton>},
	        ];
		}
      	const tabletitle = "User Table";

      	return(
			<span>
				<BasicTable2 data={this.state.items} columns={columnsData} title={tabletitle} pageSize={10} deleteF={this.deleteF} allow_search={true} />

				<Dialog
					open={this.state.showDeleteUserDialog}
					onClose={()=>{this.setState({showDeleteUserDialog:false, deleteUserID: undefined,})}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("delete_user")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{ETVConstants.trans("are_you_sure_to_delete_user")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.setState({showDeleteUserDialog:false, deleteUserID:undefined,})}} autoFocus color="primary">{ETVConstants.trans("cancel")}</Button>
						<Button onClick={()=>{this.deleteUser(this.state.deleteUserID)}} color="primary" variant='contained'>{ETVConstants.trans("agree")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='cyber_security_div'}
					maxWidth='sm'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("cyber_security")}</DialogTitle>
					<DialogContent>
						<FormControlLabel control={<Checkbox checked={this.state.use_secure_pw} onChange={()=>{this.setState({use_secure_pw:!this.state.use_secure_pw})}} />} label={ETVConstants.trans("enforce_secure_pw")} />
						<Typography variant='body1'>{ETVConstants.trans("secure_pw_requirements")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_digit")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_lowercase")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_uppercase")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_at_least_1_special_char")}</Typography>
						<Typography variant='body2'>{ETVConstants.trans("pw_len_8_20")}</Typography>
						<Divider style={{marginTop:20,marginBottom:20}}/>
						<FormControlLabel control={<Checkbox checked={this.state.otp_enabled} onChange={()=>{this.setState({otp_enabled:!this.state.otp_enabled})}} />} label={ETVConstants.trans("2_factor_authentication_enabled")} />
						<TextField autoComplete='off' error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} style={{marginTop:10}} fullWidth={true} size="small" required id="otp_phone" label={ETVConstants.trans("phone")} value={this.state.otp_phone} onChange={(e)=>{this.setState({otp_phone:e.target.value})}} onInput={()=>{this.setState({otp_phone_helper_ok:true})}} />
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.showWindow('cyber_security_div',false)}} size="small" color="primary">{ETVConstants.trans("close")}</Button>
						<Button disabled={ (this.state.otp_phone.trim().length===0 && this.state.otp_enabled) || !this.state.otp_phone_helper_ok} onClick={()=>{this.saveCyberSecuritySettings()}} color="primary" startIcon={<Save/>} size="small" variant='contained'>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='add_user'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="adduser-dialog-title">{ETVConstants.trans("add_new_user")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" component={'span'}>
							<TextField
								InputProps={{
								startAdornment: (
								<InputAdornment position="start">
									<Face />
								</InputAdornment>
								)
								}}
								style={{marginTop:20}}
								error={!this.state.new_user_email_helper_ok}	
								helperText={this.state.new_user_email_helper_ok?"":ETVConstants.trans("enter_valid_email_address")}	
								size="small" 
								fullWidth={true} 
								required id="new_user_name" 
								label={ETVConstants.trans("username")} 
								value={this.state.new_user_email} 
								onChange={(e)=>{this.setState({new_user_email:e.target.value})}} 
								onInputChange={()=>{this.setState({new_user_email_helper_ok:true})}}/>
							<TextField style={{marginTop:10}} fullWidth={true} size="small" id="new_firstname_11" label={ETVConstants.trans("firstname")}  value={this.state.new_user_firstname} onChange={(e)=>{this.setState({new_user_firstname:e.target.value})}} />
							<TextField style={{marginTop:10}} fullWidth={true} size="small" id="new_lastname_11"  label={ETVConstants.trans("lastname")}  value={this.state.new_user_lastname} onChange={(e)=>{this.setState({new_user_lastname:e.target.value})}}/>
							<TextField style={{marginTop:10}} error={!this.state.new_user_pw_helper_ok}	helperText={this.state.new_user_pw_helper_ok?"":ETVConstants.trans("password_at_least_6_characters")} fullWidth={true} size="small" required id='new_password_1' label={ETVConstants.trans("password")} type='password'  value={this.state.new_user_pw1} onChange={(e)=>{this.setState({new_user_pw1:e.target.value})}} onInput={()=>{this.setState({new_user_pw_helper_ok:true})}} />
							<TextField style={{marginTop:10}} error={!this.state.new_user_pw2_helper_ok}	helperText={this.state.new_user_pw2_helper_ok?"":ETVConstants.trans("password_mismatch")} fullWidth={true} size="small" required id='new_password2_1' label={ETVConstants.trans("repeat_password")} type='password'  value={this.state.new_user_pw2} onChange={(e)=>{this.setState({new_user_pw2:e.target.value})}} onInput={()=>{this.setState({new_user_pw2_helper_ok:true})}} />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
							<Button size='small' color="primary" onClick={() => this.showWindow('add_user',false)}>{ETVConstants.trans("cancel")}</Button>
							<Button size='small' variant="contained" startIcon={<Save/>} color="primary" disabled={!this.state.new_user_email_helper_ok || !this.state.new_user_pw_helper_ok || !this.state.new_user_pw2_helper_ok} onClick={()=>{this.insertUser()}}>{ETVConstants.trans("insert")}</Button>
					</DialogActions>
				</Dialog>

				<MessageDialog type={this.state.info_type} open={this.state.window==='message_div'} onClose={this.closeInfoMessage} title={this.state.info_title} message={this.state.info_message} />

				<Dialog
					open={this.state.window==='show_pw_change'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle>{ETVConstants.trans("reset_password")}</DialogTitle>
					<DialogContent>
						<div style={{marginTop:10}}>
							<TextField 
								error={!this.state.change_pw_helper_ok}
								helperText={this.state.change_pw_helper_ok?"":ETVConstants.trans("password_at_least_6_characters")}
								type='password'
								variant="outlined"
								autoComplete="off"
								fullWidth
								required id="new_password"
								value={this.state.changePasswordValue}
								onChange={(event) => this.setState({ changePasswordValue: event.target.value })}
								label={ETVConstants.trans("new_password")}
								onInput={ (e)=>{this.setState({change_pw_helper_ok:true})}} />
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('show_pw_change',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={this.state.changePasswordValue.trim().length<6} size="small" variant="contained" color="primary" startIcon={<Save/>} onClick={() => this.submitNewPW(this.state.selected_user)}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='show_userid_change'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle>{ETVConstants.trans("change_user_id")}</DialogTitle>
					<DialogContent>
						<div style={{marginTop:20}}>
							<TextField 
								size="small"
								error={!this.state.change_userid_helper_ok}
								helperText={this.state.change_userid_helper_ok?"":ETVConstants.trans("invalid_email")}
								variant="outlined"
								autoComplete="off"
								fullWidth={true}
								required id="new_user_id"
								value={this.state.change_userid_value}
								onChange={(event) => this.setState({ change_userid_value: event.target.value })}
								label={ETVConstants.trans("new_user_id")}
								onInput={ (e)=>{this.setState({change_userid_helper_ok:true})}} />
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('show_userid_change',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={this.state.change_userid_value.trim().length<6} size="small" startIcon={<Save/>} variant="contained" color="primary" onClick={() => this.submitNewUserID(this.state.selected_user)}>{ETVConstants.trans("change")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='show_roles'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle>{ETVConstants.trans("access_rights")}</DialogTitle>
					<DialogContent>
							{this.state.selected_user?<RolesList user_id={this.state.selected_user} roles={this.state.all_roles} />:<span/>}
					</DialogContent>
					<DialogActions>
							<Button size="small" color='primary' onClick={() => this.showWindow('show_roles',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='extra_screen_access_div'}
					maxWidth='lg'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle></DialogTitle>
					<DialogContent>
						<div>
							{this.state.selected_user?<ExtraAccessScreens parent_id={this.props.user_id} user_id={this.state.selected_user} closeHook={this.closeScreenSelection} />:<span/>}
						</div>
					</DialogContent>
					<DialogActions>
							<Button size="small" color='primary' onClick={() => this.showWindow('extra_screen_access_div',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='show_status_div'}
					maxWidth='xs'
					fullWidth
					onClose={()=>{}}>
					<DialogTitle>{ETVConstants.trans("status")}</DialogTitle>
					<DialogContent fullWidth>
						<div style={{marginTop:20}}>
							{this.state.selected_user_object?<StatusSetting user_object={this.state.selected_user_object} closeF={this.closeStatusSetting} status={this.props.status} />:<span/>}
						</div>
					</DialogContent>
					<DialogActions>
							<Button size="small" color='primary' onClick={() => this.showWindow('extra_screen_access_div',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='change_parent_div'}
					maxWidth='xs'
					fullWidth
					onClose={()=>{}}>
					<DialogTitle>{ETVConstants.trans("change_parent")}</DialogTitle>
					<DialogContent>
							<Typography variant="body2" color="textSecondary" component='h2'>
								current: {this.getUserNameFromID(this.state.selected_new_parent_id)}
								<Autocomplete
									id="select_new_parent"
									options={this.state.items}
									getOptionLabel={(option) => option.user_name}
									style={{ width: 300, marginTop:20 }}
									onChange={(e,value)=>{this.setState({selected_new_parent_id:value.user_id})}}
									renderInput={(params) => <TextField {...params} label={ETVConstants.trans("select_user")} variant="outlined" />}
								/>
							</Typography>
							<Button size="small" style={{marginTop:20}} variant="contained" color='primary' startIcon={<Person/>} onClick={() => {this.setState({selected_new_parent_id:this.state.root_user_id})}}>{ETVConstants.trans("set_myself")}</Button>
					</DialogContent>
					<DialogActions>
							<Button size="small" color='primary' onClick={() => this.showWindow('change_parent_div',false)}>{ETVConstants.trans("close")}</Button>
							<Button size="small" variant='contained' startIcon={<Save/>} color='primary' onClick={() => this.submitNewParent()}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<div>
				<br/>
					<Button variant="contained" startIcon={<PersonAdd/>} color="primary" onClick={() => this.showAddUserWindow()} >{ETVConstants.trans("add_new_user")}</Button>
				</div>
		</span>);
	}
}

export default Create3rdPartyUserTable
