import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Paper, Button, Divider,TextField,IconButton, CircularProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import * as ETVConstants from '../ETVConstants';
import { Add } from "@mui/icons-material";
import LoadingDialog from "../tools/LoadingDialog";

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  //padding: 1,
  margin: `0 0 ${grid}px 0`,
  height:45,
  borderRadius:"5px",
  marginTop:5,
  marginBottom:5,
  border:'0.5px solid lightgray',
  // change background colour if dragging
  background: isDragging ? "#ccffcc" : "#ffffff",
  // styles we need to apply on draggables
  ...draggableStyle
});


const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgreen" : "transparent",
  padding: grid,
  width: 200,
  margin: 6,
});

class GroupMgmt extends React.Component  {

    //const [state, setState] = useState([getItems(10), getItems(5, 10)]);
    constructor( props) {
      super(props);
      this.onDragEnd = this.onDragEnd.bind(this);
    }

    state = {
      groups:[],
      groupNames:[],
      group_id:[],
      edit_id:-1
    }

    componentDidMount() {
      this.loadGroups();
    }

    componentDidUpdate() {
      if(!this.state.isLoaded) {
        this.loadGroups();
      }
    }

    getGroupNames( groups) {
      var res = [];
      res.push("all devices");
      for(var i=0; i<groups.length; i++) {
        res[i+1]=groups[i].group_name;
      }
      return res;
    }

    getGroupIDs( groups) {
      var res = [];
      res.push("all devices");
      for(var i=0; i<groups.length; i++) {
        res[i+1]=groups[i].group_id;
      }
      return res;
    }

    createGroupArray( groupInfo) {
      var res = [];
      var deviceIDs = groupInfo.device_ids;
      var deviceNames = groupInfo.device_names;
      //console.log("deviceNames="+deviceNames);
      for(var k=0; k<deviceIDs.length; k++) {
        var elem = {device_id:deviceIDs[k],device_name:deviceNames[k]};
        //console.log("created elem="+JSON.stringify(elem));
        res.push(elem);
      }
      return res;
    }

    createGroups( groups) {
      var res = [];
      res.push([]);
      for(var i=0; i<groups.length; i++) {
        res[i+1]=this.createGroupArray(groups[i]);
      }
      return res;
    }

    loadGroups() {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=list&user_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("rx groups="+JSON.stringify(result));
            var groups = this.createGroups(result.list);
            var groupNames = this.getGroupNames(result.list);
            var groupIDs = this.getGroupIDs(result.list);
            //console.log("groups="+groups+" groupNames="+groupNames);
            this.setState({
              groups: groups,
              groupNames: groupNames,
              group_id: groupIDs,
            });
            this.loadDevices();
          },
          (error) => {
            console.log("error="+error);
          }
        )
    }

    createGroupArrayFromDevices(devices) {
      var res = [];
      for(var i=0; i<devices.length; i++) {
        res.push( {device_id:devices[i].id,device_name:devices[i].device_name});
      }
      return res;
    }

    deviceExistsInGroup( deviceID, groupIndex) {
      for(var i=0; i<this.state.groups[groupIndex].length; i++) {
         if (this.state.groups[groupIndex][i].device_id === deviceID) return true;
      }
      return false;
    }

    deviceExistsInAnyGroup( deviceID) {
      for(var i=1; i<this.state.groups.length; i++) {
         if(this.deviceExistsInGroup(deviceID,i)) return true;
      }
      return false;
    }

    filterOutDevicesFromGroups( allDevices) {
      //console.log("filterOutDevices, allDevices.length="+allDevices.length);
      var res = [];
      for(var i=0; i<allDevices.length; i++) {
        if(!this.deviceExistsInAnyGroup(allDevices[i].id)) {
          res.push(allDevices[i]);
        }
      }
      return res;
    }

    loadDevices() {
      var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						var devices = this.filterOutDevicesFromGroups(result.list);
            var group0Devices = this.createGroupArrayFromDevices(devices);
            var newGroups = [...this.state.groups];
            newGroups[0] = group0Devices;
            var newGroupNames = [...this.state.groupNames];
            newGroupNames[0] = "all devices";
            this.setState({
              groups:newGroups,
              groupNames:newGroupNames,
              loaded:true,
              isLoaded:true,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

    addToGroupDB(deviceID, groupID) {
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=add_or_update_group&device_id="+deviceID+"&group_id="+groupID;
      ETVConstants.sendCMDToServer(url);
    }

    switchGroup( item, itemIndex, fromGroupIndex, toGroupIndex) {
      //add element to new group
      var newState = [...this.state.groups];
      var elems = newState[toGroupIndex];
      elems.push(item);
      newState[fromGroupIndex].splice(itemIndex, 1);
      this.setState({groups:newState});

      var deviceID = item.device_id;
      var groupID = this.state.group_id[toGroupIndex];
      this.addToGroupDB(deviceID,groupID);
    }


    deleteGroup(groupIndex) {
      var elemsToStore = this.state.groups[groupIndex];
      var newState = [...this.state.groups];
      newState[0].push(...elemsToStore);
      //console.log("deleteGroup="+groupIndex+" newState[0]="+newState[0]);
      newState[0].sort(ETVConstants.compareDevice);
      newState.splice(groupIndex,1);
      var groupN = this.state.groupNames;
      groupN.splice(groupIndex,1);
      this.setState({groupNames:groupN,groups:newState});
      //console.log("groupID="+this.state.group_id[groupIndex]);
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=delete_group&group_id="+this.state.group_id[groupIndex];
      //console.log("url="+url);
      ETVConstants.sendCMDToServer(url);
      //this.setState({isLoaded:false});
    }

    addNewGroup() {
      var index = this.state.groups.length;
      var groupName = this.createNewGroupName();
      this.addGroupToDB(index,groupName);
      this.setState({groupNames:[...this.state.groupNames,groupName],groups:[...this.state.groups, []]});
    }

    addGroupToDB( groupIndex, groupName) {
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=add_group&user_id="+this.props.user_id+"&group_name="+groupName;
      //console.log("url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            var groupIDs = this.state.group_id;
            groupIDs[groupIndex]=result.data;
            this.setState({group_id:groupIDs});
          },
          (error) => {
            console.log("error="+error);
          }
        )
    }

    editGroupName( groupID) {
      this.setState({edit_id:groupID});
    }

    removeDeviceFromGroupInDB( groupID, deviceID) {
      //note: need the group id, because deviceID can be part of several groups
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=remove_device_from_group&device_id="+deviceID+"&group_id="+groupID;
      ETVConstants.sendCMDToServer(url);
    }

    switchElement( fromGroupIndex, elementIndex, toGroupIndex) {
      //console.log("switchElement fromGroupIndex="+fromGroupIndex+" (elemIndex="+elementIndex+") toGroupIndex="+toGroupIndex);
      var newState = [...this.state.groups];
      var elem = this.state.groups[fromGroupIndex][elementIndex];
      newState[fromGroupIndex].splice(elementIndex,1);
      newState[toGroupIndex].push(elem);
      this.setState({groups:newState});
      //console.log("toGroupIndex="+toGroupIndex);
      if(toGroupIndex===0 || toGroupIndex==='0') { //funnily it is '0'
          //console.log("toGroupIndex="+(toGroupIndex===0));
          this.removeDeviceFromGroupInDB( this.state.group_id[fromGroupIndex], elem.device_id);
      }else{
          this.addToGroupDB(elem.device_id,this.state.group_id[toGroupIndex]);
      }
    }

    onDragEnd(result) {
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd !== dInd) {
          this.switchElement( sInd, source.index, dInd);
        }
    }

    printGroups() {
      for(var i=0; i<this.state.groupNames.length; i++) {
        console.log("GroupName="+this.state.groupNames[i]+"("+this.state.group_id[i]+"), #elems="+this.state.groups[i].length);
      }
    }

    createDraggableElement(provided, snapshot, item, index, groupIndex) {
      return(
        <Draggable key={item.device_id} draggableId={item.device_id} index={index} >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle( snapshot.isDragging, provided.draggableProps.style )} >
                <div style={{width:'100%',padding:5}}>
                  <div style={{width:'80%',float:'left',height:20}}><Typography variant='body2'>{item.device_name}</Typography></div>
                  <div style={{marginLeft:'80%'}}>{groupIndex===0?<span/>:<IconButton color='primary' size='small' onClick={() => {this.switchElement(groupIndex,index,0)}}><Typography variant='body2'>[x]</Typography></IconButton>}</div>
                </div>
            </div>
          )}
        </Draggable>
      )
    }

    //this is for every keyboard strike (only update in DB on the save button)
    updateGroupName( groupIndex, name) {
      var groupN = [...this.state.groupNames];
      groupN[groupIndex] = name;
      this.setState({groupNames:groupN});
    }

    createNewGroupName() {
      var i = 1;
      var index = this.state.groupNames.indexOf("Group "+i);
      while(index>=0) {
        i++;
        index = this.state.groupNames.indexOf("Group "+i);
      }
      return "Group "+i;
    }

    submitGroupName() {
      var groupIndex = this.state.edit_id;
      var groupName = this.state.groupNames[groupIndex];
      var groupID = this.state.group_id[groupIndex];
      this.renameGroupInDB(groupID, groupName);
      this.setState({edit_id:-1});
    }

    renameGroupInDB( groupID, groupName) {
      var url = ETVConstants.getServerBase()+"/group_mgmt?action=update_group_name&group_id="+groupID+"&group_name="+groupName;
      ETVConstants.sendCMDToServer(url);
    }

    createDroppableArea(groupElements, groupIndex) {
      return(
        <Paper key={"group_"+groupIndex} elevation={2} style={{margin:5}}>
            <div style={{overflow:'hidden'}}>
              <div style={{float:'left',marginLeft:0}}>
                {this.state.edit_id!==groupIndex?
                  <Typography id={"group_"+groupIndex} style={{marginLeft:10}} variant="h6">{this.state.groupNames[groupIndex]}</Typography>
                :
                  <TextField id={"group_"+groupIndex} onChange={(e)=>{this.updateGroupName(groupIndex, e.target.value)}} style={{width:150}} disabled={this.state.edit_id!==groupIndex} size='small' variant='outlined' value={this.state.groupNames[groupIndex]} />
                }

              </div>
              {groupIndex!==0?
                  <div style={{float:'right'}}>
                    {this.state.edit_id===groupIndex?
                       <IconButton size='small' color='primary' onClick={()=>this.submitGroupName()}><SaveIcon/></IconButton>
                       :
                       <IconButton size='small' color='primary' onClick={()=>this.editGroupName(groupIndex)}><EditIcon/></IconButton>
                    }
                    <IconButton size='small' color='error' onClick={()=>this.deleteGroup(groupIndex)}><DeleteIcon/></IconButton>
                  </div>
                  :
                  <span/>
              }
            </div>
            <Divider/>
            <Droppable key={groupIndex} droppableId={`${groupIndex}`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps} >
                  {groupElements.map((item, index) => (this.createDraggableElement(provided,snapshot,item,index,groupIndex)))}
                  {provided.placeholder}
                </div>)
              }
            </Droppable>
        </Paper>
      )
    }

    render() {
        
        if(!this.state.isLoaded) {
          return <LoadingDialog open={!this.state.isLoaded} message={ETVConstants.trans("loading")}/>
        }

        return (
          <div>
            <div style={{marginTop:20,marginLeft:10,marginBottom:20}}>
              <Button variant='contained' color='primary' size='small' startIcon={<Add/>} onClick={() => {this.addNewGroup()}}>Add new Group</Button>
            </div>
            <div style={{ display: "flex" }}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                  {this.state.groups.map((groupElements, groupIndex) => (
                    this.createDroppableArea(groupElements, groupIndex)
                  ))}
              </DragDropContext>
            </div>
          </div>
        );
      }
}


export default GroupMgmt;
