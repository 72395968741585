import React from 'react';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import InputLabel from '@mui/material/InputLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SelectAvailableScreensNew from './SelectAvailableScreensNew';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker,DatePicker } from '@mui/x-date-pickers';
import * as ETVConstants from '../ETVConstants';
import dayjs from 'dayjs';
import MessageDialog from '../tools/MessageDialog';

class Countdown extends React.Component {

    constructor(props) {
      super(props);
      this.selectedScreens = this.selectedScreens.bind(this);
      this.closeScreenSelection = this.closeScreenSelection.bind(this);
      this.closeInfoMessage = this.closeInfoMessage.bind(this);
    }

    state = {
      ticker_text:"",
      channel: "0",
      window:'',
      info_type:'',
      info_message:'',
      info_title:'',
      selected_screens: [],
      validating: false,
      selected_date: ETVConstants.createDateNow(),
      selected_time: ETVConstants.createDateNow(),
    }

    selectedScreens(screenIDs) {
      this.showWindow('select_screens_div',false);
      this.setState({selected_screens:screenIDs});
    }

    closeScreenSelection() {
      this.showWindow('select_screens_div',false);
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    showInfoMessage(title, msg) {
      this.setState({window:'message_div',info_title:title, info_message:msg, info_type:'info'});
    }

    showSuccessMessage(title, msg) {
      this.setState({window:'message_div',info_title:title, info_message:msg, info_type:'success'});
    }

    showErrorMessage(title, msg) {
      this.setState({window:'message_div',info_title:title, info_message:msg, info_type:'error'});
    }

    closeInfoMessage() {
      this.setState({window:'',info_message:'',info_title:'',info_type:''});
    }

    getFilename() {
      var m = new Date();
      var dateString = ""+m.getUTCFullYear() + (m.getUTCMonth()+1) + m.getUTCDate() + m.getUTCHours() +  m.getUTCMinutes() + m.getUTCSeconds();
      return dateString+"_countdown.html";
    }

    saveCountdown() {
      this.setState({ validating: true });
    
      // Ensure `selected_date` and `selected_time` are dayjs objects
      const d0 = dayjs(this.state.selected_date);
      const time = dayjs(this.state.selected_time);
    
      // Combine the date and time
      const updatedDateTime = d0.hour(time.hour()).minute(time.minute());
    
      this.showInfoMessage("","Uploading... ");
    
      var url = ETVConstants.getServerBase()+"/template_servlet?action=create_and_save_template&user_id="+this.props.user_id;
      url+="&template_path=templates/countdown/countdown_template.html";
      url+="&filename="+this.getFilename();
      url+="&showing_time=20";
      url+="&param_0.name=@@title@@&param_0.value="+ETVConstants.encodeURIComponentSafe(this.state.ticker_text);
      url+="&param_1.name=@@date@@&param_1.value="+encodeURIComponent(updatedDateTime.format());
      url+="&desc="+ETVConstants.encodeURIComponentSafe(this.state.ticker_text);
      url+="&thumbnail="+encodeURIComponent(ETVConstants.getServerBase() + "/images/thumbnails/countdown_thumbnail.jpg");
    
      if (this.state.selected_screens && this.state.selected_screens.length > 0) {
        url += "&channel="+this.state.channel;
        this.state.selected_screens.forEach((screen) => {
          url += "&device_id="+screen;
        });
      }
    
      var accessToken = localStorage.getItem("etv.access_token");
      var reqUserID = localStorage.getItem("etv.user_id");
    
      fetch(url, {
        headers: { 'user_id': reqUserID, 'access_token': accessToken },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              ticker_text: "",
              channel: "0",
              selected_screens: [],
              validating: false,
              selected_date: dayjs(),
              selected_time: dayjs(),
            });
            this.showSuccessMessage("",ETVConstants.trans("the_countdown_ticker_was_stored_in_your_content_library"));
          },
          (error) => {
            this.showErrorMessage("","error happened: " + error);
            this.setState({
              ticker_text: "",
              channel: "0",
              selected_screens: [],
              validating: false,
              selected_date: dayjs(),
              selected_time: dayjs(),
            });
          }
        );
    }

    buttonIsDisabled() {
      if(!this.state.ticker_text ) return true;
      if(this.state.ticker_text.trim().length===0) return true;
      return false;
    }

    render() {

      return (
        <div style={{width:'700px'}} >
          <Card>
            <CardContent>
                <Typography variant="h5">
                    {ETVConstants.trans("countdown_ticker")}
                </Typography>
                <div style={{marginTop:20}} />
                <Typography variant="body1" >
                    {ETVConstants.trans("countdown_clock_until_given_date")}
                </Typography>
                <div style={{marginTop:20}} />
                     <TextField required
                        key='countdown_text'
                        label={ETVConstants.trans("countdown_text_max_100_chars")}
                        InputProps={{ maxLength: 100 }}
                        fullWidth={true}
                        value={this.state.ticker_text}
                        onChange={(event)=>{this.setState({ticker_text:event.target.value})}} />
                <div style={{marginTop:20}} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <Grid container justifycontent="space-around">
                      <DatePicker
                          disableToolbar
                          format="MM/DD/YYYY"
                          margin="normal"
                          id="date-picker-inline"
                          label={ETVConstants.trans("countdown_date")}
                          value={dayjs(this.state.selected_date)}
                          onChange={(date) => this.setState({ selected_date: dayjs(date) })}
                        />
                       <span style={{margin:'20px'}} />
                       <TimePicker
                          margin="normal"
                          id="time-picker"
                          label={ETVConstants.trans("countdown_time")}
                          value={dayjs(this.state.selected_time)}
                          onChange={(time) => this.setState({ selected_time: dayjs(time) })}
                        />
                     </Grid>
                  </LocalizationProvider>
                  <div style={{marginTop:20}}>
                    <Button variant="contained" color="primary" onClick={() => this.showWindow('select_screens_div',true)}>{ETVConstants.trans("select_screens")} ({this.state.selected_screens.length})</Button>
                  </div>
                  <br/>
                  <FormControl style={{marginTop:10}}>
                      <InputLabel>{ETVConstants.trans("channel")}</InputLabel>
                      <Select
                        label={ETVConstants.trans("channel")}
                        id="channel_id"
                        style={{ width: 300}}
                        value={this.state.channel}
                        onChange={(e)=>{this.setState({channel:e.target.value})}}>
                        <MenuItem value='0'>{ETVConstants.trans("channel")} 0</MenuItem>
                        <MenuItem value='1'>{ETVConstants.trans("channel")} 1</MenuItem>
                        <MenuItem value='2'>{ETVConstants.trans("channel")} 2</MenuItem>
                        <MenuItem value='3'>{ETVConstants.trans("channel")} 3</MenuItem>
                        <MenuItem value='4'>{ETVConstants.trans("channel")} 4</MenuItem>
                      </Select>
                    </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={this.buttonIsDisabled()} color="primary" startIcon={<UploadFileIcon />} variant='contained' onClick={() => this.saveCountdown()}>{ETVConstants.trans("upload")}</Button>
            </CardActions>
          </Card>

          <div id='select_screens_div' style={{display:this.state.window==='select_screens_div'?'block':'none'}}>
								<SelectAvailableScreensNew isOpen={this.state.window==='select_screens_div'} user_id={this.props.user_id} closeHook={this.closeScreenSelection} selectedScreens={this.selectedScreens} selected={this.state.selected_screens?this.state.selected_screens:[]}/>
					</div>

          <MessageDialog type={this.state.info_type} open={this.state.window==='message_div'} onClose={this.closeInfoMessage} title={this.state.info_title} message={this.state.info_message} />

        </div>
      )
    }
}

export default Countdown;
