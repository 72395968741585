import React from 'react';
import {Card,Button,Box} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentLibrary from "./ContentLibrary";
import ContentSettings from "./ContentSettings";
import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/Warning';
import ScreenSelection from './ScreenSelection';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AddScreenIcon from '@mui/icons-material/AddToQueue';
import Dropzone from "react-dropzone";
import * as ETVConstants from '../ETVConstants';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { HourglassTop } from '@mui/icons-material';

class PlaylistChannel extends React.Component {

	constructor(props) {
      super(props);
      this.externalUpdatedF = this.externalUpdatedF.bind(this);
      this.closeSettings = this.closeSettings.bind(this);
			this.rxDefaultImage = this.rxDefaultImage.bind(this);
			this.rxPublicAccessConfirm = this.rxPublicAccessConfirm.bind(this);
    }

    state = {
      items:[],
      isLoaded: false,
      library_element: undefined,
			show_all_contents: false,
			default_img_link:undefined,
			uploading_default_img:false,
    }

		componentDidUpdate(prevProps, prevState) {
      if(!this.state.isLoaded) {
        this.loadPlaylist();
      }
      if(prevProps.device_id!==this.props.device_id) {
        this.loadPlaylist();
      }
		}

    componentDidMount() {
      this.loadPlaylist();
    }

    loadPlaylist() {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			var url = ETVConstants.getServerBase()+"/playlist?device_id="+this.props.device_id+"&channel="+this.props.channel+"&user_id="+this.props.user_id;
			if(this.props.group_id>=0) {
				url = ETVConstants.getServerBase()+"/playlist?group_id="+this.props.group_id+"&channel="+this.props.channel+"&user_id="+this.props.user_id;
			}
			//console.log("playlist url="+url);
      fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
						//console.log(JSON.stringify(result.list));
            this.setState({
              items: result.list,
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              items:[],
              isLoaded: false,
              error
            });
          }
        )
    }

		//filtering the items on whether they are currently active
		filterItems( list) {
			//console.log("filter list items, state="+this.state.show_all_contents);
			if(this.state.show_all_contents) return list;
			// filter out not active items
			var res = [];
			for(var i=0; i<list.length; i++) {
				if(this.isCurrentlyShown(list[i])) {
					res.push(list[i]);
				}
			}
			return res;
		}

		// currently shown
		isCurrentlyShown( elem) {
			var element = elem.library_element;
			var days = element.weekdays;
			var validWeekday = this.checkValidWeekdayToday(days);
			if(!validWeekday) return false;
			if(!this.checkValidDate(element.from_date,element.to_date)) return false;
			if(!this.checkValidTime(element.from_time,element.to_time)) return false;
			if(!element.active) return false;
			return true;
		}

    removeFromPlaylist( elementID) {
      var url = ETVConstants.getServerBase()+"/playlist?action=remove&channel="+this.props.channel+"&device_id="+this.props.device_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			if(this.props.group_id>=0) {
				url = ETVConstants.getServerBase()+"/playlist?action=remove&channel="+this.props.channel+"&group_id="+this.props.group_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			}
			//console.log("removeFromPlaylist url="+url);
      this.updatePlaylist(url);
    }

    moveDown( elementID) {
      var url = ETVConstants.getServerBase()+"/playlist?action=move_down&channel="+this.props.channel+"&device_id="+this.props.device_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			if(this.props.group_id>=0) {
				url = ETVConstants.getServerBase()+"/playlist?action=move_down&channel="+this.props.channel+"&group_id="+this.props.group_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			}
      this.updatePlaylist(url);
    }

    moveUp( elementID) {
      var url = ETVConstants.getServerBase()+"/playlist?action=move_up&channel="+this.props.channel+"&device_id="+this.props.device_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			if(this.props.group_id>=0) {
				url = ETVConstants.getServerBase()+"/playlist?action=move_up&channel="+this.props.channel+"&device_id="+this.props.device_id+"&user_id="+this.props.user_id+"&content_id="+elementID;
			}
      this.updatePlaylist(url);
    }

    getLowestRank() {
      if(this.state.items.length>0) {
        var lowest = this.state.items[0].rank;
        for(var i=1; i<this.state.items.length; i++) {
          var elemRank = this.state.items[i].rank;
          if(elemRank<lowest) {
            lowest = elemRank;
          }
        }
        return lowest;
      }else{
        return -1;
      }
    }

    getHighestRank() {
      if(this.state.items.length>0) {
        var highest = this.state.items[0].rank;
        for(var i=1; i<this.state.items.length; i++) {
          var elemRank = this.state.items[i].rank;
          if(elemRank>highest) {
            highest = elemRank;
          }
        }
        return highest;
      }else{
        return -1;
      }
    }

    updatePlaylist( url) {
			var accessToken = localStorage.getItem("etv.access_token");
			var reqUserID = localStorage.getItem("etv.user_id");
			fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
				.then( res => res.json() )
				.then(
						(result) => {
							this.setState({isLoaded:false});
						},
						(error) => {
              this.setState({isLoaded:false});
						}
					)
		}

    addFromLibrary(marketplace) {
      if(marketplace) {
        this.showWindow('add_from_marketplace',true);
      }else{
        this.showWindow('add_from_library',true);
      }
    }

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

    closeLibrary() {
      this.showWindow('add_from_library',false);
      this.setState({isLoaded:false});
    }

    getIds() {
      var res = [];
      for(var i=0; i<this.state.items.length; i++) {
        res.push(this.state.items[i].library_element.content_id);
      }
      return res;
    }

    getContentElement( id) {
        //console.log("xxxxxx getContentElement====>items="+this.state.items.length+" search for contentID="+id);
        for(var i=0; i<this.state.items.length; i++) {
            //console.log("xxxx===> checking "+this.state.items[i].library_element.content_id);
            if(this.state.items[i].library_element.content_id===id) {
              return this.state.items[i].library_element;
            }
        }
    }

    showScreens( contentID) {
      var elem = this.getContentElement(contentID);
      this.setState({library_element:elem});
      this.showWindow('show_screens_div',true);
    }

    closeScreens() {
        this.showWindow('show_screens_div',false);
        this.setState({isLoaded:false});
    }

    editItem( contentID) {
      var elem = this.getContentElement(contentID);
      this.setState({library_element:elem});
      this.showWindow('content_settings_div',true);
    }

    closeSettings() {
        this.showWindow('content_settings_div',false);
        this.setState({isLoaded:false});
    }

    showFilename( element) {
      var name = element.filename.substring(element.filename.indexOf("_")+1, element.filename.length);
			var color = element.active?'#000000':'#C0C0C0';
			var url = element.url;
			return <Typography variant='body2' style={{color:color}}><a href={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeContentURLRelative(url):url} style={{color:color}}>{name}{this.insertOutOfTimeWarning(element)}</a></Typography>
    }


	insertOutOfTimeWarning( element) {
		// check weekday
		var days = element.weekdays;
		if(!this.checkValidWeekdayToday(days)) {
			return (
				<Tooltip title={ETVConstants.trans("not_played_on_current_weekday")}>
					<WarningAmberIcon style={{margin:2, color:'#ffcc00'}}/>
				</Tooltip>
			)
		}
		if(!this.checkValidDate(element.from_date,element.to_date)) {
			return (
				<Tooltip title={ETVConstants.trans("out_of_date")}>
					<WarningAmberIcon style={{margin:2, color:'#ffcc00'}}/>
				</Tooltip>
			)
		}
		if(!this.checkValidTime(element.from_time,element.to_time)) {
			return (
				<Tooltip title={ETVConstants.trans("out_of_time")}>
					<WarningAmberIcon style={{margin:2, color:'#ffcc00'}}/>
				</Tooltip>
			)
		}
		return "";
	}

	checkValidTime(fromTime, toTime) {
		if(!fromTime && !toTime) return true;
		var nowTime = this.checkTimeFormat(this.createNowTime());
		if(fromTime) {
			fromTime = this.checkTimeFormat(fromTime);
			if(fromTime>nowTime) {
				return false;
			}
		}
		if(toTime) {
			toTime = this.checkTimeFormat(toTime);
			if(toTime<nowTime) {
				return false;
			}
		}
		return true;
	}

	checkTimeFormat(t) {
		var h = t.substring(0,t.indexOf(":"));
		var m = t.substring(t.indexOf(":")+1);
		if(h.length===1) h="0"+h;
		if(m.length===1) m="0"+m;
		return h+":"+m;
	}

	createNowTime() {
		var today = new Date();
		return today.getHours()+":"+(today.getMinutes()<10?"0"+today.getMinutes():today.getMinutes());
	}

	checkValidDate(fromDate,toDate) {
		if(!fromDate && !toDate) return true;
		var todayDate = this.createTodayDate();
		if(fromDate) {
			var fromD = new Date(fromDate);
			fromD.setHours(0,0,0);
			if(fromD>todayDate) {
				return false;
			}
		}
		if(toDate) {
			var toD = new Date(toDate);
			toD.setHours(0,0,0);
			if(toD<todayDate) return false;
		}
		return true;
	}

	createTodayDate() {
		var today = new Date();
		today.setHours(0,0,0,0);
		return today;
	}

	checkValidWeekdayToday(weekdays) {
		var d = new Date();
		var curD = d.getDay();
		if( (curD===0) && weekdays.includes("sunday")) return true;
		if( (curD===1) && weekdays.includes("monday")) return true;
		if( (curD===2) && weekdays.includes("tuesday")) return true;
		if( (curD===3) && weekdays.includes("wednesday")) return true;
		if( (curD===4) && weekdays.includes("thursday")) return true;
		if( (curD===5) && weekdays.includes("friday")) return true;
		if( (curD===6) && weekdays.includes("saturday")) return true;
		return false;
	}

	showPlayingTime( element) {
		var color = element.active?'#000000':'#C0C0C0';
		return <Typography variant='body2' style={{color:color}}>{this.isMovie(element)?"":element.showing_time}</Typography>
	}

	getSettingsButton( element) {
		return(
			<IconButton size='small' disabled={!element.active} variant='contained' color='primary' onClick={()=>this.editItem(element.content_id)}>
					{this.canEdit(element.owner_id)?<SettingsIcon/>:<VisibilityIcon/>}
			</IconButton>
		)
	}

	getScreensButton( element) {
		return (
			<IconButton disabled={!element.active || !this.canEdit(element.owner_id) }
									size='small'
									variant='contained'
									color='primary'
									onClick={()=>this.showScreens(element.content_id)}>
									<AddScreenIcon/>
			</IconButton>
		)
	}

	getDeleteIcon( element) {
		return (
			<Tooltip title={ETVConstants.trans("remove_from_playlist")}>
				<IconButton disabled={ false }
							size='small'
							variant='contained'
							color='error'
							onClick={()=>this.removeFromPlaylist(element.content_id)}>
					<DeleteIcon/>
				</IconButton>
			</Tooltip>
		)
	}

	setActiveF( contentID, isActive) {
		var url = ETVConstants.getServerBase()+"/content_library?action=set_active&content_id="+contentID+"&active="+isActive;
		this.updateContentRegistry(url);
	}

	updateContentRegistry( url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then( res => res.json() )
			.then(
					(result) => {
						this.setState({isLoaded:false});
					},
					(error) => {
					}
				)
	}

    isMovie( element) {
      if (element.filename.toLowerCase().endsWith('.mp4')) return true;
      if (element.filename.toLowerCase().endsWith('.mpeg')) return true;
      if (element.filename.toLowerCase().endsWith('.mp3')) return true;
      if (element.filename.toLowerCase().endsWith('.mov')) return true;
      if (element.filename.toLowerCase().endsWith('.avi')) return true;
      return false;
    }

	getActiveIcon( element) {
		var canEdit = this.canEdit(element.owner_id);
		var isActive = element.active;
		var id = element.content_id;
		//console.log("xx getActiveIcon, id="+id+" isActive="+isActive+" canEdit="+canEdit);
		if(!isActive) {
			return(
						<Tooltip title={ETVConstants.trans("set_active")}>
								<span>
								<IconButton size='small' disabled={!canEdit} aria-label="activate" color={(canEdit?"error":"#C0C0C0")} onClick={() => this.setActiveF(id,!isActive)}><BlockIcon/></IconButton>
								</span>
						</Tooltip>
					)
		}else{
			return(
					<Tooltip title={ETVConstants.trans("set_inactive")}>
							<span>
							<IconButton size='small' disabled={!canEdit} aria-label="active" color={(canEdit?"success":"#C0C0C0")} onClick={() => this.setActiveF(id,!isActive)}><CheckIcon/></IconButton>
							</span>
					</Tooltip>
			)
		}
	}

		getRankIconUp( index, element) {
				if(index===0) {
						return <span></span>
				}
				return <span><IconButton size='small' onClick={()=>this.moveUp(element.content_id)} style={{color:'green'}}><KeyboardArrowUpIcon/></IconButton></span>
		}

		getRankIconDown( index, element) {
				if(index===this.state.items.length-1) {
						return <span></span>
				}
				return <span><IconButton size='small' onClick={()=>this.moveDown(element.content_id)} style={{color:'red'}}><KeyboardArrowDownIcon/></IconButton></span>
		}

		externalUpdatedF() {
			this.setState({isLoaded:false});
		}

		getActiveSelection() {
			var switchActive = this.state.items && this.state.items.length>0;
			return <FormControlLabel control={<Switch color='primary' disabled={!switchActive} checked={this.state.show_all_contents} onClick={()=>{this.switchActiveContents()}} />} label={this.state.show_all_contents?ETVConstants.trans("all_contents"):ETVConstants.trans("active_contents")} />
		}

		switchActiveContents() {
			this.setState({show_all_contents:!this.state.show_all_contents});
		}

		canEdit( ownerID) {
			var userID = this.props.user_id;
			var isChild = this.props.children.includes(ownerID);
			return (userID===ownerID) || isChild;
		}

		showAddDefaultImage() {
			this.showWindow("add_default_image",true);
			this.loadDefaultImage();
		}

		loadDefaultImage() {
			var url = ETVConstants.getServerBase()+"/playlist?device_id="+this.props.device_id+"&channel="+this.props.channel+"&action=default_contents";
			ETVConstants.sendCMDToServerListener(url,this.rxDefaultImage);
		}

		deleteDefaultContents() {
			var url = ETVConstants.getServerBase()+"/playlist?device_id="+this.props.device_id+"&channel="+this.props.channel+"&action=delete_default_contents";
			ETVConstants.sendCMDToServerListener(url,this.rxDefaultImage);
		}

		rxDefaultImage(jsonObj) {
			//console.log("obj="+JSON.stringify(jsonObj));
			if(!jsonObj) {
				this.setState({default_img_link:undefined});
				return;
			}
			var list = jsonObj.default_contents;
			if(!list || list.length==0) {
				this.setState({default_img_link:undefined});
				return;
			}
			var link = list[0].link;
			if(!link) {
				this.setState({default_img_link:undefined});
				return;
			}
			this.setState({default_img_link:link});
		}

		onDrop = (files) => {
				//console.log("onDrop, isScreenOwner="+this.props.is_device_owner+" deviceID="+this.props.device_id+" groupID="+this.props.group_id+" channel="+this.props.channel);
				var targetDir = "/"+this.props.device_id+"/default_content/channel"+this.props.channel;
				for( var i=0; i<files.length; i++) {
					if(ETVConstants.ON_PREMISE_VERSION) {
						this.uploadFileToOnPremise( files[i], targetDir);
					}else{
						this.uploadFileToGCS( files[i], targetDir);
					}
				}
		}

		uploadFileToOnPremise( file, targetDir) {
		  //console.log("uploadFileToOnPremise");
			this.setState({uploading_default_img:true});
		  var filename = file.name;
		  var accessToken = localStorage.getItem("etv.access_token");
		  var reqUserID = localStorage.getItem("etv.user_id");
		  var formData = new FormData();
		  formData.append('file', file);
		  formData.append('filename', filename);
		  formData.append('target_dir',targetDir);
		  const config = {
		    headers: {	'content-type':'multipart/form-data', 'user_id':reqUserID, 'access_token': accessToken },
		    onUploadProgress: progressEvent => this.updateProgress(file.size, progressEvent.loaded)
		  }
		  var url = ETVConstants.getServerBase()+"/file_premise_upload";
		  axios.post(url, formData, config)
		      .then((response) => {
						this.loadDefaultImage();
		      })
		      .catch((error) => {
		         console.error(error);
		      });
		}

		uploadFileToGCS( file, targetDir) {
		  //console.log("uploadFileToGCS");
			this.setState({uploading_default_img:true});
			var filename = file.name;
		  var accessToken = localStorage.getItem("etv.access_token");
		  var reqUserID = localStorage.getItem("etv.user_id");
		  var url = ETVConstants.getServerBase()+"/gcs_upload?action=request_upload&target_dir="+targetDir+"&filename="+filename;
		  fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		    .then(res => res.json())
		    .then(
		      (result) => {
		        //console.log("res="+JSON.stringify(result));
		        var formData = new FormData();
		        Object.entries(result.fields).map(([key,value])=>{
		            return formData.append(key,value);
		        })
		        formData.append('file', file);
		        const config = {
		          headers: {	'content-type': 'multipart/form-data'}
		        }
		        axios.post(result.url, formData, config)
		            .then((response) => {
		              this.givePublicAccess(targetDir,filename);
		            })
		            .catch((error) => {
		               console.error(error);
									 this.setState({uploading_default_img:false});
		            });
		      },
		      (error) => {
		      }
		    )
		}

		givePublicAccess(target_dir,filename) {
		  var url = ETVConstants.getServerBase()+"/gcs_upload?action=public_access&target_dir="+target_dir+"&filename="+filename;
		  ETVConstants.sendCMDToServerListener(url,this.rxPublicAccessConfirm);
		}

		rxPublicAccessConfirm( val) {
			//console.log("-----------> rxPublicAccessConfirm, val="+val);
			this.setState({uploading_default_img:false});
			this.loadDefaultImage();
		}

    render() {
      	var excludedIDs = this.getIds();
		var filtered = this.filterItems(this.state.items);
	    return(
			<div>
	        	<div>
					<Card>
						<CardContent>
						<Typography component={'span'} gutterBottom variant="h4">
							{ETVConstants.trans("channel")}  &nbsp; {this.props.channel} &nbsp; <Button size='small' variant="contained" color="primary" onClick={() => this.addFromLibrary(false)}>{ETVConstants.trans("add_content")}</Button>&nbsp;&nbsp;
											{(this.props.is_device_owner && this.props.group_id==-1 && this.props.sw_version>=91?<Button size='small' variant="contained" color="primary" onClick={() => this.showAddDefaultImage()}>{ETVConstants.trans("default_image")}</Button>:<span/>)}&nbsp;&nbsp;
							{(this.props.marketaccess && !ETVConstants.ON_PREMISE_VERSION)?<Button size='small' variant="contained" color="primary" onClick={() => this.addFromLibrary(true)}>{ETVConstants.trans("add_from_marketplace")}</Button>:<span/>} &nbsp; {this.getActiveSelection()}
						</Typography>
						<TableContainer>
							<Table size="small" aria-label="playlist tabel">
							<colgroup>
								<col width="15" />
								<col width="15" />
								<col width="250" />
								<col width="20" />
								<col width="20" />
								<col width="20" />
								<col width="20" />
								<col width="10" />
							</colgroup>
							<TableHead>
								<TableRow>
									<TableCell alight="right"></TableCell>
									<TableCell alight="right"></TableCell>
									<TableCell align="left">{ETVConstants.trans("filename")}</TableCell>
									<TableCell align="left">{ETVConstants.trans("showing_time_s")}</TableCell>
									<TableCell align="left">{ETVConstants.trans("settings")}</TableCell>
									<TableCell align="left">{ETVConstants.trans("screens")}</TableCell>
									<TableCell align="left">{ETVConstants.trans("active")}</TableCell>
									<TableCell align="left">{ETVConstants.trans("remove")}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filtered.map((row,index) => (
									<TableRow key={index+"_"+row.library_element.content_id}>
										<TableCell align="left">{this.getRankIconUp(index, row.library_element)}</TableCell>
										<TableCell align="left">{this.getRankIconDown(index, row.library_element)}</TableCell>
										<TableCell align="left">{this.showFilename(row.library_element)}</TableCell>
										<TableCell align="left">{this.showPlayingTime(row.library_element)}</TableCell>
										<TableCell align="left">{this.getSettingsButton(row.library_element)}</TableCell>
										<TableCell align="left">{this.getScreensButton(row.library_element)}</TableCell>
										<TableCell align="left">{this.getActiveIcon(row.library_element)}</TableCell>
										<TableCell align="left">{this.getDeleteIcon(row.library_element)}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
								</TableRow>
							</TableFooter>
							</Table>
						</TableContainer>
						</CardContent>
					</Card>
		   		</div>

				<Dialog
					open={this.state.window==='add_from_library'}
					maxWidth='lg'
					fullWidth={false}
					onClose={()=>{this.closeLibrary()}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("add_from_library")}</DialogTitle>
					<DialogContent>
						{this.state.window==='add_from_library'?<ContentLibrary visible={this.state.window==='add_from_library'} children={this.props.children} marketplace={false} user_id={this.props.user_id} channel={this.props.channel} group_id={this.props.group_id} device_id={this.props.device_id} excluded_ids={excludedIDs} addedToChannelF={this.externalUpdatedF} />:<span/>}
					</DialogContent>
					<DialogActions>
					<Button onClick={()=>{this.closeLibrary()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='add_default_image'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("default_image")}</DialogTitle>
					<DialogContent>
						<Typography variant="body1" style={{marginTop:10}}>
								{ETVConstants.trans("default_image_explanation")}
						</Typography>
						{this.state.default_img_link || this.state.uploading_default_img?<span/>:
								<div style={{width:200,height:'auto'}}>
										<Dropzone
												onDrop={this.onDrop}
												disabled={false}
												accept="image/jpeg"
												multiple={false}
												minSize={1024}
												maxSize={5242880}
												style={{width:'100%',height:'100%'}}>
										{({ getRootProps, getInputProps }) => (
											<div {...getRootProps({ className: "dropzone" })}>
													<input {...getInputProps()} />
													<p>{ETVConstants.trans("click_to_upload_files_max_5mb")}</p>
											</div>
										)}
										</Dropzone>
								</div>
						}
						{this.state.uploading_default_img?<CircularProgress style={{marginTop:10}} size={50} />:<span/>}
						{this.state.default_img_link?<Box component="img" sx={{width: '100%',height: 'auto',objectFit: 'cover',borderRadius: 2,}} src={ETVConstants.ON_PREMISE_VERSION?ETVConstants.makeContentURLRelative(this.state.default_img_link):this.state.default_img_link} />:<span/>}
						{this.state.default_img_link?<Button onClick={()=>this.deleteDefaultContents()} style={{marginTop:5}} size="small" variant="contained" color="secondary">{ETVConstants.trans("delete")}</Button>:<span/>}
					</DialogContent>
	            <DialogActions>
    	          <Button onClick={()=>{this.showWindow('',false)}} color="primary">{ETVConstants.trans("close")}</Button>
            	</DialogActions>
          	</Dialog>

          <Dialog
            open={this.state.window==='add_from_marketplace'}
            maxWidth='lg'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("add_from_marketplace")}</DialogTitle>
            <DialogContent>
                {this.state.window==='add_from_marketplace'?<ContentLibrary visible={this.state.window==='add_from_marketplace'} children={this.props.children} marketplace={true} user_id={this.props.user_id} channel={this.props.channel} group_id={this.props.group_id} device_id={this.props.device_id} excluded_ids={excludedIDs} addedToChannelF={this.externalUpdatedF} />:<span/>}
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeLibrary()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.window==='content_settings_div'}
            maxWidth='md'
            fullWidth={true}
            onClose={()=>{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("content_settings")}</DialogTitle>
            <DialogContent>
                {this.state.library_element?
                    <ContentSettings closeF={this.closeSettings} user_id={this.props.user_id} editable={this.canEdit(this.state.library_element.owner_id)} library_element={this.state.library_element} />
                  :<span/>
                }
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeSettings()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.window==='show_screens_div'}
            maxWidth='lg'
            fullWidth={false}
            onClose={()=>{this.closeScreens()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{ETVConstants.trans("select_screens")}</DialogTitle>
            <DialogContent>
                {this.state.library_element?
                    <ScreenSelection channel={this.props.channel} children={this.props.children} user_id={this.props.user_id} content_id={this.state.library_element.content_id} library_element={this.state.library_element} updatedHook={this.externalUpdatedF} />
                    :
                    <span/>
                }
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeScreens()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>
				</div>
			);
	}
}

export default PlaylistChannel
