import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as ETVConstants from '../ETVConstants';

class MessageDialog extends React.Component {
    getIcon(type) {
        switch (type) {
            case 'info':
                return <InfoIcon color="info" fontSize="large" />;
            case 'error':
                return <ErrorIcon color="error" fontSize="large" />;
            case 'alert':
                return <WarningAmberIcon color="warning" fontSize="large" />;
            default:
                return null;
        }
    }

    getAlertSeverity(type) {
        switch (type) {
            case 'info':
                return 'info';
            case 'error':
                return 'error';
            case 'warning':
                return 'warning';
            case 'success':
                return 'success';
            default:
                return 'info';
        }
    }

    render() {
        const { open, onClose, type, title, message } = this.props;

        return (
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Alert severity={this.getAlertSeverity(type)} sx={{ mb: 1, px: 1, py: 1 }}>
                    <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: '5px', p: 0 }}>
                        <Typography variant="h6">{title}</Typography>
                    </DialogTitle>
                </Alert>
                <DialogContent>
                    <Typography variant="body1" id="alert-dialog-description">
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        onClick={onClose}
                        color="primary"
                        variant="text"
                    >
                        {ETVConstants.trans("close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default MessageDialog;



