import React from 'react';
import './index.css';
import Login from './Login.js';
import {Button,Card,CardActions,CardContent} from '@mui/material';
import Portal from './Portal.js';
import InfoMessage2 from './tools/InfoMessage2.js';
import { FormattedMessage, IntlProvider} from 'react-intl';
import en from "./intl/en.json";
import de from "./intl/de.json";
import jp from "./intl/jp.json";

class ElevatorTV extends React.Component{

  constructor(props) {
    super(props);

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.langCallback = this.langCallback.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.loggedInFunc = this.loggedInFunc.bind(this);
    this.setTimeout();
    var lang = this.getUserLanguage();
    this.state.lang = lang;
  }

  getUserLanguage() {
      var userLang = navigator.language || navigator.userLanguage;
      //console.log("userLang="+userLang);
      if(userLang.indexOf("de")>=0) return "de";
      return "en";
  }

  state = {
    lang:'en',
    loggedin:false,
    user_id:undefined,
    roles: undefined,
    children:[],
    show_overlay: false,
    window: undefined,
    info_message_title: undefined,
    info_message: undefined,
  }

  langCallback( lang) {
    this.setState({lang:lang});
  }

  setTimeout() {
    if(this.state.loggedin) {
      this.warnTimeout = setTimeout(this.warn, 300 * 1000);
      this.logoutTimeout = setTimeout(this.logout, 360 * 1000);
    }
  }

  clearTimeout() {
     if (this.warnTimeout) clearTimeout(this.warnTimeout);
     if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
   }

   logout() {
       // Send a logout request to the API
       //console.log("Sending a logout request.");
       this.closeInfoMessage();
       this.setState({ loggedin: false });
       // this.destroy(); // Cleanup
   }

   resetTimeout() {
      this.clearTimeout();
      this.setTimeout();
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   warn() {
     this.showInfoMessage(this.trans('automatic_logout'),this.trans('inactive_session_you_will_be_logged_out_automatically_in_1_minute'));
   }

   showInfoMessage(title,text) {
     this.setState({info_message_title:title,info_message:text});
     this.showWindow('message_div',true);
   }

   closeInfoMessage() {
     this.setState({info_message:undefined});
     this.showWindow('message_div',false);
   }

   showWindow(divID, show) {
     //console.log("show window="+divID+" show="+show);
     this.setState({show_overlay:show});
     show?this.setState({window:divID}):this.setState({window:''});
   }

   destroy() {
      this.clearTimeout();
      for (var i in this.events) {
        window.removeEventListener(this.events[i], this.resetTimeout);
      }
  }

  loggedInFunc(success, userID, username, roles, children, status, lang) {
    this.setState({
        loggedin: success,
        user_id: userID,
        username: username,
        roles: roles,
        children:children,
        status: status,
        lang:lang,
      });
  }

  clickNotLogout() {
    this.closeInfoMessage();
    this.resetTimeout();
  }

  render() {
      const messages = {en,de,jp};
      return(
        <IntlProvider locale={this.state.lang && this.state.lang.length>0?this.state.lang:"en"} messages={messages[this.state.lang?this.state.lang:'en']}>
        <div>
          {this.state.loggedin?
              <div><Portal lang={this.state.lang} langCallback={this.langCallback} loggedInCallback={this.loggedInFunc} user_id={this.state.user_id} username={this.state.username} roles={this.state.roles} children={this.state.children} status={this.state.status} /></div>
              :
              <div><Login lang={this.state.lang} loggedInCallback={this.loggedInFunc} /></div>
          }
          <div id='overlay_gray' style={{display: this.state.show_overlay === true ? 'block' : 'none'}}>
              <div id='message_div' style={{display:this.state.window==='message_div'?'block':'none'}}>
  						 		<Card>
  									<CardContent>
  									     <InfoMessage2 title={this.state.info_message_title} message={this.state.info_message} />
  									</CardContent>
  									<CardActions>
  										<Button size='small' color="primary" onClick={(e)=>{this.clickNotLogout()}}>{this.trans("cancel")}</Button>
  										<Button size='small' color="primary" onClick={(e)=>{this.logout()}}>{this.trans("logout")}</Button>
  									</CardActions>
  								</Card>
               </div>
            </div>
          </div>
          </IntlProvider>
      )
  }
}

export default ElevatorTV