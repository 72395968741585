import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AutoAwesome, Block, CheckCircle, FiberNew, Info, Verified } from '@mui/icons-material';
import * as ETVConstants from '../ETVConstants.js';
import { Chip, IconButton, Tooltip } from '@mui/material';
import BasicTable2 from '../tools/BasicTable2.js';
import { Table,TableBody,TableRow,TableCell } from '@mui/material';
import LoadingDialog from '../tools/LoadingDialog.js';
import MessageDialog from '../tools/MessageDialog.js';

class ExistingApprovals extends React.Component {

	constructor(props) {
      	super(props);
		this.closeInfoMessage = this.closeInfoMessage.bind(this);
    }

    state = {
      	isLoaded: false,
		isLoading: false,
		cur_ad:{},
		list:[],
		categories:{},
		categories_color:{},
		selected_image:'',
		selected_image_filename:'',
		window:'',
    }

    componentDidMount() {
		this.loadCategories();
		this.loadData();
    }

	componentDidUpdate() {
		if(this.state.isLoading) {
			return;
		}
		if(!this.state.isLoaded) {
			this.loadData();
		}
	}

	showWindow(divID, show) {
		show?this.setState({window:divID}):this.setState({window:''});
	}

    loadCategories() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/advertising_screen_owner?action=get_categories";
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
				categories: this.hashCategories(result.list),
				categories_color: this.hashCategoriesColor(result.list),
            });
          },
          (error) => {
            this.setState({
				error
            });
          }
        )
    }

	hashCategoriesColor( list) {
		var res = {};
		for(var i=0; i<list.length; i++) {
			res[list[i].id]=this.getColor(list[i].category);
		}
		return res;
	}

	getColor( col) {
		//as proposed by chatgpt
		if(col=='products') return "#FF7F50";
		if(col=='services') return "#4682B4";
		if(col=='retail') return "#FF4500";
		if(col=='real estate') return "#2E8B57";
		if(col=='financial') return "#008080";
		if(col=='entertainment') return "#FFD700";
		if(col=='food & beverage') return "#D2691E";
		if(col=='health & wellness') return "#6B8E23";
		if(col=='lifestyle & leisure') return "#DA70D6";
		if(col=='B2B') return "#2F4F4F";
		return "#505050";
	}

	hashCategories( list) {
		var res = {};
		for(var i=0; i<list.length; i++) {
			res[list[i].id]=list[i].item;
		}
		return res;
	}


    loadData() {
		this.setState({isLoading:true});
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/advertising_approvals?action=get_approvals&user_id="+this.props.user_id;
		//console.log("========>urlSttr="+urlStr);
      	fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
				list: this.filterList(result.list,[1,2,3]), 
              	isLoaded: true,
				isLoading:false,
            });
          },
          (error) => {
            this.setState({
				isLoaded: true,
				isLoading:false,
              	error
            });
          }
        )
    }

	filterList(list, statusArr) {
		if (!Array.isArray(list) || !Array.isArray(statusArr)) {
			throw new Error("Both list and statusArr must be arrays");
		}
		const res = [];
		for (let i = 0; i<list.length; i++) {
			if (statusArr.includes(list[i].status)) {
				res.push(list[i]); // Correctly push the current object
			}
		}
		return res;
	}

	sendCMDToServer(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				this.setState({
					isLoaded: false
				})
			})
			.catch((error) => {
				console.error(error);
			});
    }

	showInfoMessage(title,msg) {
		this.setState({window:'message_div',info_type:'info',info_title:title,info_message:msg});
	}

  	showSuccessMessage(title,msg) {
	  	this.setState({window:'message_div',info_type:'success',info_title:title,info_message:msg});
	}

	showErrorMessage(title,msg) {
	  	this.setState({window:'message_div',info_type:'error',info_title:title,info_message:msg});
	}

  	closeInfoMessage() {
	 	this.setState({window:'',info_type:'',info_title:'',info_message:undefined});
  	}

	getImage(thumbUrl, url, filename) {
		//console.log("url===>"+url);
		return(
			<img 
				src={thumbUrl} 
				style={{width:'250px',height:'auto'}}
				alt={filename}
				onClick={()=>this.setState({selected_image:url,window:'show_full_image'})}
			/>
		);
	}

	getCategory( label) {
		var color = this.state.categories_color[label];
		return(
			<Chip
				label={this.state.categories[label]}
				sx={{
					backgroundColor:color, 
					color: '#fff'
				}}
			/>
		)
	}

	printStatus( status) {
		if(status==0) {
			//new need approval
			return <Tooltip title={ETVConstants.trans("new")}><FiberNew fontSize='large' color='primary'/><AutoAwesome fontSize='large' style={{color:'#ebc034'}} /></Tooltip>

		}else if(status==1) {
			// approved
			return <Tooltip><CheckCircle fontSize='large' color='success'/></Tooltip>

		}else if(status==2) {
			//rejected
			return <Tooltip title={ETVConstants.trans("rejected")}><Block fontSize='large' color='error'/></Tooltip>

		}else if(status==3) {
			//auto approved
			return <Tooltip title={ETVConstants.trans("auto_approved")}><Verified fontSize='large'color='success'/></Tooltip>
		}
	}

	printAction( status, contentID) {
		if(status==0) {
			return 
				<span>
					<Button size="small" variant="contained" color="primary" onClick={()=>{this.approve(contentID)}}>{ETVConstants.trans("approve")}</Button>
					<Button style={{marginTop:10}} size="small" variant="contained" color="primary" onClick={()=>{this.reject(contentID)}}>{ETVConstants.trans("reject")}</Button>
				</span>

		}else if(status==1) {
			//approved, can reject
			return <Button size="small" variant="contained" color="primary" onClick={()=>{this.reject(contentID)}}>{ETVConstants.trans("stop")}</Button>
					
		}else if(status==2) {
			//rejected, can approved now
			return <Button size="small" variant="contained" color="primary" onClick={()=>{this.approve(contentID)}}>{ETVConstants.trans("re-approve")}</Button>

		}else if(status==3) {			
			//auto approved, can reject
			return <Button size="small" variant="contained" color="primary" onClick={()=>{this.reject(contentID)}}>{ETVConstants.trans("stop")}</Button>
		}
	}

	approve( contentID) {
		var url = ETVConstants.getServerBase()+"/advertising_approvals?action=approve&content_id="+contentID+"&user_id="+this.props.user_id;
		//console.log("approveUrl="+url);
		this.showInfoMessage("approving...");
		this.sendCMDToServer(url);
	}

	reject( contentID) {
		this.showInfoMessage("rejecting...");
		var url = ETVConstants.getServerBase()+"/advertising_approvals?action=reject&content_id="+contentID+"&user_id="+this.props.user_id;
		this.sendCMDToServer(url);
	}

	showAdInfo( content) {
		this.setState({cur_ad:content,window:'ad_info_div'});
	}

    render() {
		if(this.state.isLoading) {
			return <LoadingDialog open={this.state.isLoading} message={ETVConstants.trans("loading")}/>
		}

	    const columnsData= [
			{ title: 'image', field: '', render: rowData => this.getImage(rowData.thumbnail,rowData.url, rowData.filename) },
			{ title: 'category', field: 'category', render: rowData => this.getCategory(rowData.category) },
			{ title: 'info', field: '', render: rowData => <IconButton onClick={()=>{this.showAdInfo(rowData)}}><Info  fontSize='large' color='primary'/></IconButton> },
			{ title: 'cpm', field: 'cpm'  },
			{ title: 'total_hits', field: 'total_hits'  },
			{ title: 'current_hits', field: 'current_hits'  },
			{ title: 'status', field: 'status', render: rowData => this.printStatus(rowData.status)  },
			{ title: 'actions', field: 'status', render: rowData => this.printAction(rowData.status, rowData.content_id) },
	  	];

		var tableTitle = "";
      	return(
			<span>
				<BasicTable2 data={this.state.list} columns={columnsData} title={tableTitle} pageSize={10} allow_search={true} />

				<Dialog
					open={this.state.window==='ad_info_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{ETVConstants.trans("ad_info")}</DialogTitle>
					<DialogContent>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>{ETVConstants.trans("publisher")}</TableCell>
									<TableCell>{this.state.cur_ad['firstname']+" "+this.state.cur_ad['lastname']}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{ETVConstants.trans("email_contact")}</TableCell>
									<TableCell>{this.state.cur_ad['user_name']}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{ETVConstants.trans("date")}</TableCell>
									<TableCell>{this.state.cur_ad['from_date']+" until "+this.state.cur_ad['to_date']}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{ETVConstants.trans("time")}</TableCell>
									<TableCell>{this.state.cur_ad['from_time']+" until "+this.state.cur_ad['to_time']}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{ETVConstants.trans("showing_time")}</TableCell>
									<TableCell>{this.state.cur_ad['showing_time']+"s"}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<MessageDialog onClose={this.closeInfoMessage} title={this.state.info_title} message={this.state.info_message} type={this.state.info_type} />

				<Dialog
					open={this.state.window==='show_full_image'}
					maxWidth='lg'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}>
					<DialogTitle id="message div">{this.state.selected_image_filename}</DialogTitle>
					<DialogContent>
						<img src={this.state.selected_image} />
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.setState({window:''})}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

			</span>);
	}
}

export default ExistingApprovals
