// CanvaPopup.jsx (class-based, condensed example)
import React, { Component } from 'react';
import axios from 'axios';
import { generatePKCEPair } from './pkceUtils';
import * as ETVConstants from '../ETVConstants.js';

//important!!! otherwise it will mount twice (in dev mode)
//for testing: need to test on 127.0.0.1 (localhost would fail)
let hasMounted = false; 

class CanvaPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'initializing', // "initializing" | "exchanging" | "done" | "error"
      error: null,
      codeVerifier:'',
      isAuthInitiated: false,
      isExchanging: false,
    };
  }

  async componentDidMount() {
    if (hasMounted) {
      //console.log("CanvaPopup already mounted. Skipping...");
      return;
    }
    hasMounted = true; 
    if (this.state.isAuthInitiated) {
      //console.log("Auth process already initiated. Skipping...");
      return;
    }
    this.setState({ isAuthInitiated: true });
    //console.log("--------> component did mount");
  
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
  
    if (!code || code.length === 0 || code === 'undefined') {
      try {
        const { codeVerifier, codeChallenge } = await generatePKCEPair();
        localStorage.setItem('canvaCodeVerifier', codeVerifier);
        localStorage.setItem('canvaCode', codeChallenge);
        //alert("startCanvaAuth");
        this.startCanvaOAuth();
      } catch (err) {
        //console.error("Error generating PKCE pair:", err);
        this.setState({ step: 'error', error: `Failed to generate PKCE pair: ${err.message}` });
      }
    } else {
      this.setState({ step: 'exchanging' });
      this.exchangeCodeForTokens(code);
    }
  }

  startCanvaOAuth() {
    try {
      var codeChallenge = localStorage.getItem('canvaCode');
      //alert("code sent: "+codeChallenge);
      // Build the auth URL
      const clientId = 'OC-AZQmWnVLXM7L';
      var authUrl = "https://www.canva.com/api/oauth/authorize";
      authUrl+="?code_challenge="+codeChallenge;
      authUrl+="&code_challenge_method=s256";
      authUrl+="&scope="+encodeURIComponent("design:meta:read folder:read asset:read asset:write design:content:read");
      authUrl+="&response_type=code";
      authUrl+="&client_id="+clientId;
      authUrl+="&state="+this.state.step;
      //authUrl+="&redirect_uri="+encodeURIComponent('http://127.0.0.1:3000/canva-popup/');
      //console.log("authURL="+authUrl);

      // Redirect this popup to Canva
      window.location.href = authUrl;
    } catch (err) {
      this.setState({
        step: 'error',
        error: `Failed to start OAuth flow: ${err.message}`
      });
    }
  }

  exchangeCodeForTokens(code) {
    if (this.state.isExchanging) {
      //console.log("Exchange process already initiated. Skipping...");
      return;
    }
    this.setState({ isExchanging: true }); // Set flag
    const codeVerifier = localStorage.getItem('canvaCodeVerifier');
    //alert("exchangeCodeForTokens="+code+" verifier="+codeVerifier);
    //console.log("Code verifier:", codeVerifier);
    if (!codeVerifier || codeVerifier.length === 0 || codeVerifier === 'undefined') {
      this.setState({
        step: 'error',
        error: `Missing code_verifier in popup sessionStorage! Please close this window and try again without refreshing.`,
        isExchanging: false, // Reset flag
      });
      return;
    }
    //console.log("Exchange URL:", ETVConstants.getServerBase() + `/canva/exchange?code=${code}&code_verifier=${codeVerifier}`);
    //if(true) return;
    axios.post(ETVConstants.getServerBase() + '/canva/exchange', { "etv_source":"elevator.tv" ,"code":code, "code_verifier":codeVerifier })
      .then((res) => {
        //alert(JSON.stringify(res));
        //console.log("Token exchange successful:", res.data);
        if (window.opener) {
          //alert("posting message: "+res.data);
          window.opener.postMessage({ tokenData: res.data }, '*');
        }
        hasMounted = false;
        window.close();
      })
      .catch((err) => {
        //console.error("Token exchange failed:", err);
        this.setState({
          step: 'error',
          error: `Token exchange failed: ${err.message}`,
          isExchanging: false, // Reset flag
        });
      });
  }

  render() {
    const { step, error } = this.state;

    if (step === 'error') {
      return (
        <div style={{ color: 'red' }}>
          <h3>OAuth Error</h3>
          <p>{error}</p>
        </div>
      );
    }
    if (step === 'exchanging') {
      return <div>Exchanging code for tokens…</div>;
    }
    return <div>Initializing…</div>; // default
  }
}

export default CanvaPopup;
