import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import * as ETVConstants from '../ETVConstants';
import LoadingDialog from '../tools/LoadingDialog';

class AuditLog extends React.Component {
  state = {
    isLoading: false,
    isLoaded: false,
    list: [],
    filteredList: [],
    selectedUser: '',
    selectedDate: '',
    searchLog: '',
    uniqueUsers: [],
    uniqueDates: [],
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ isLoading: true });
    const accessToken = localStorage.getItem('etv.access_token');
    const reqUserID = localStorage.getItem('etv.user_id');
    const url = `${ETVConstants.getServerBase()}/audit_log?user_id=${this.props.user_id}`;
    fetch(url, { headers: { user_id: reqUserID, access_token: accessToken } })
      .then((response) => response.json())
      .then((logs) => {
        const list = logs.list;

        // Extract unique users and dates
        const uniqueUsers = Array.from(new Set(list.map((item) => item.user_name)));
        const uniqueDates = Array.from(
          new Set(list.map((item) => new Date(item.date_time).toLocaleDateString()))
        );     

        this.setState({
          list,
          filteredList: list,
          uniqueUsers,
          uniqueDates,
          isLoaded: true,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleFilterChange = () => {
    const { list, selectedUser, selectedDate, searchLog } = this.state;

    const filteredList = list.filter((item) => {
      const matchesUser = selectedUser ? item.user_name === selectedUser : true;
      const matchesDate = selectedDate
        ? new Date(item.date_time).toLocaleDateString() === selectedDate
        : true;
      const matchesLog = searchLog
        ? item.log.toLowerCase().includes(searchLog.toLowerCase())
        : true;

      return matchesUser && matchesDate && matchesLog;
    });

    this.setState({ filteredList });
  };

  handleUserChange = (e) => {
    this.setState({ selectedUser: e.target.value }, this.handleFilterChange);
  };

  handleDateChange = (e) => {
    this.setState({ selectedDate: e.target.value }, this.handleFilterChange);
  };

  handleLogSearchChange = (e) => {
    this.setState({ searchLog: e.target.value }, this.handleFilterChange);
  };

  showDate(dateStr) {
    const d = new Date(dateStr);
    return <Typography variant='body2' sx={{ fontStyle: 'italic' }}>{d.toLocaleString()}</Typography>;
  }

  createTable() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} size="small" aria-label="log table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6">{ETVConstants.trans('date_time')}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">{ETVConstants.trans('user')}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6">{ETVConstants.trans('log')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filteredList.map((row) => (
              <TableRow key={row.audit_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{this.showDate(row.date_time)}</TableCell>
                <TableCell component="th" scope="row">
                  {row.user_name}
                </TableCell>
                <TableCell align="left">{row.log}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { isLoading, uniqueUsers, uniqueDates, selectedUser, selectedDate, searchLog } = this.state;

    if (isLoading) {
      return <LoadingDialog open={isLoading} message={ETVConstants.trans('loading')} />;
    }

    return (
      <span>
        <Card sx={{ minHeight: '60vh', overflow: 'auto', padding: '20px' }}>
          <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            {/* User Dropdown */}
            <TextField
              select
              value={selectedUser}
              onChange={this.handleUserChange}
              label={ETVConstants.trans('filter_by_user')}
              variant="outlined"
              size="small"
              style={{ minWidth: '200px' }}
            >
              <MenuItem value="">{ETVConstants.trans('all_users')}</MenuItem>
              {uniqueUsers.map((user) => (
                <MenuItem key={user} value={user}>
                  {user}
                </MenuItem>
              ))}
            </TextField>

            {/* Date Dropdown */}
            <TextField
              select
              value={selectedDate}
              onChange={this.handleDateChange}
              label={ETVConstants.trans('filter_by_date')}
              variant="outlined"
              size="small"
              style={{ minWidth: '200px' }}
            >
              <MenuItem value="">{ETVConstants.trans('all_dates')}</MenuItem>
              {uniqueDates.map((date) => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </TextField>

            {/* Log Search */}
            <TextField
              value={searchLog}
              onChange={this.handleLogSearchChange}
              label={ETVConstants.trans('search_log')}
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          {this.createTable()}
        </Card>

      </span>
    );
  }
}

export default AuditLog;
