import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ElevatorTV from './ElevatorTV.js';
import CanvaPopup from './canva/CanvaPopup.js';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ElevatorTV />} />
        <Route path="/canva-popup" element={<CanvaPopup />} />
      </Routes>
    </BrowserRouter>
  );
}