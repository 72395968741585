import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

class LoadingDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                maxWidth="xs"
                fullWidth={false}
                onClose={this.props.onClose}
                aria-labelledby="progress-dialog-title"
                aria-describedby="progress-dialog-description"
            >
                <DialogContent
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <div>
                        <CircularProgress size={80} />
                    </div>
                    <div style={{ width: 150, marginTop: '20px' }}>
                        <Typography variant="body1">
                            {this.props.message}
                        </Typography>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default LoadingDialog;

