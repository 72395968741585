import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as ETVConstants from '../ETVConstants';
import { Edit, Save } from '@mui/icons-material';
import MessageDialog from '../tools/MessageDialog';
import LoadingDialog from '../tools/LoadingDialog';

class EditUserAccount extends React.Component {

	constructor(props) {
	    super(props);
		this.closeInfoMessage = this.closeInfoMessage.bind(this);
		this.state = {
			isLoading:false,
			isLoaded: false,
			isSaving: false,
			firstname:'',
			lastname:'',
			company:'',
			address1:'',
			address2:'',
			zip:'',
			city:'',
			country:'',
			phone:'',
			url:'',
			edit:false,
			otp_phone:'',
			otp_phone_helper:'',
			otp_enabled: false,
			user:'',
			info_title:'',
			info_message:'',
			myUsername: this.props.username,
		}
	}

    componentDidMount() {
		this.loadData();
    }

	componentDidUpdate() {
		if(!this.state.isLoaded && !this.state.isLoading) {
			this.loadData();
		}
	}

	loadData() {
		this.setState({isLoading:true});
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/useradmin?action=get_user&user_id="+this.props.user_id;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
	    .then((response) => response.json())
      	.then((user) => {
        this.setState({
            parent_email: user.user_name,
			parent_firstname: user.firstname,
			parent_lastname: user.lastname,
			firstname:user.firstname,
			lastname:user.lastname,
			company:user.company,
			address1:user.address_1,
			address2:user.address_2,
			zip:user.zip,
			city:user.city,
			country:user.country,
			phone:user.phone,
			url: user.url,
			edit:false,
			otp_phone:user.phone,
			otp_phone_helper_ok:true,
			otp_enabled: user.use_2_factor_authentication,
			user:user,
			isLoaded:true,
			isUsernameEditing:false,
			isLoading:false,
         })
      })
      .catch((error) => {
      });
    }

	showWindow(divID, show) {
		this.setState({show_overlay:show});
		{ show?this.setState({window:divID}):this.setState({window:''}) };
	}

	showInfoMessage(title,msg) {
		this.setState({window:'message_div',info_type:'info',info_title:title,info_message:msg});
  	}

  	showSuccessMessage(title,msg) {
	  this.setState({window:'message_div',info_type:'success',info_title:title,info_message:msg});
	}

	showErrorMessage(title,msg) {
	  this.setState({window:'message_div',info_type:'error',info_title:title,info_message:msg});
	}

  	closeInfoMessage() {
		this.setState({window:'',info_type:'',info_title:'',info_message:''});
  	}

	sendCMDToServer(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
				if(responseJson['result']) {
					this.setState({edit:false, isLoaded:false, isSaving:false});
				}else{
					this.showInfoMessage(ETVConstants.trans("error"),"Failed to save, reason: "+responseJson['reason']);
				}
			})
			.catch((error) => {
					console.error(error);
			});
	}

	saveValue( val) {
		if(!val) return "";
		if(val==='undefined') return "";
		if(val===null) return "";
		if(val==="null") return "";
		return val;
	}

	updateInfo() {
		this.setState({isSaving:true});
		// validate info
		if(!this.isPhone(this.state.phone)) {
			this.setState({otp_phone_helper_ok:false});
			return;
		}
		var url = ETVConstants.getServerBase()+"/useradmin?action=update_user_info&user_id="+this.props.user_id
		url += "&firstname="+encodeURIComponent(this.saveValue(this.state.firstname));
		url += "&lastname="+encodeURIComponent(this.saveValue(this.state.lastname));
		url += "&company="+encodeURIComponent(this.saveValue(this.state.company));
		url += "&address_1="+encodeURIComponent(this.saveValue(this.state.address1));
		url += "&address_2="+encodeURIComponent(this.saveValue(this.state.address2));
		url += "&zip="+encodeURIComponent(this.saveValue(this.state.zip));
		url += "&city="+encodeURIComponent(this.saveValue(this.state.city));
		url += "&country="+encodeURIComponent(this.saveValue(this.state.country));
		url += "&phone="+encodeURIComponent(this.saveValue(this.state.phone));
		url += "&url="+encodeURIComponent(this.saveValue(this.state.url));
		this.sendCMDToServer(url);
	}

	saveOTP() {
		var phone = this.state.otp_phone.trim();
		if(this.state.otp_enabled) {
			if(!this.isPhone(phone)) {
				this.setState({otp_phone_helper_ok:false});
				return;
			}
		}else{
			// otp not enabled --> phone === 0 or then correct
			if(phone.trim().length>0 && !this.isPhone(phone)) {
				this.setState({otp_phone_helper_ok:false});
				return;
			}
		}
		phone = phone.replace(/\s/g, '');
		var url = ETVConstants.getServerBase()+"/useradmin?action=update_2_factor_authentication&phone="+encodeURIComponent(phone)+"&user_id="+this.state.selected_user+"&use_2_factor_authentication="+this.state.otp_enabled;
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		//console.log(url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((result) => {
				if(result["result"]!==true) {
					this.showInfoMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_2_factor_authentication"));
					this.setState({isLoaded:false});
				}else{
					this.setState({isLoaded:false, otp_enabled:false, otp_phone_helper_ok:true, otp_phone:'', window:''});
				}
		})
		.catch((error) => {
				//console.log(error);
				//this.showInfoMessage("Error",error);
		});
	}

	isPremiumUser() {
		return this.props.status==="premium" || this.props.status==="enterprise";
	}

	isPhone( phone) {
		// remarks: ^abc$ --> means exact match
		// "/" start and "/" end tags
		// \s* as many whitespace as wishes
		// {7,} at least 7 numbers
		// \+ must start with +
		const PHONE_REGEX =  /^\+\s*[0-9\s*]{7,}$/;
		//console.log("testing phone="+PHONE_REGEX.test(phone));
		return PHONE_REGEX.test(phone);
	}

	show2FactorAuth(user) {
		//console.log(JSON.stringify(user));
		this.setState({selected_user:user.user_id, otp_phone:user.phone, otp_enabled:user.use_2_factor_authentication});
		this.showWindow('2_fac_auth_div',true);
	}

	toggleEdit = () => {
        this.setState((prevState) => ({
            isUsernameEditing: !prevState.isUsernameEditing,
        }));
    };

	submitNewUserName() {
		var newUserID = this.state.myUsername;
		if(newUserID===this.props.username) {
			//same username as before....
			this.setState({isUsernameEditing:false});
			return;
		}
		if(!newUserID || !ETVConstants.isEmail(newUserID)) {
			this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("invalid_email"));
			return;
		}
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+'/useradmin?action=change_user_id&user_id='+reqUserID+"&new_user_id="+encodeURIComponent(newUserID);
		//console.log("url="+url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then((response) => response.json())
		.then((responseJson) => {
			console.log(JSON.stringify(responseJson));
			if(responseJson["result"]===true) {
				this.setState({change_userid_value:'',isLoaded:false, isUsernameEditing:false});
				this.showSuccessMessage(ETVConstants.trans("success"),ETVConstants.trans("user_id_successfully_changed"));
			}else if(responseJson['reason']==='user_already_exists') {
				this.setState({change_userid_value:''});
				this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("user_exists_already"));
			}else if(responseJson['reason']==='invalid_email') {
				this.setState({change_userid_value:''});
				this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("invalid_email"));
			}else{
				this.setState({change_userid_value:''});
				this.showErrorMessage(ETVConstants.trans("error"),ETVConstants.trans("could_not_update_user_id"));
			}
		})
		.catch((error) => {
				//console.log("Error="+error);
		});
	}


    render() {
		if(!this.state.isLoaded) {
			return <LoadingDialog />
		}

		var isCloudUser = this.isPremiumUser();
		var isUsernameEditing = this.state.isUsernameEditing;

      	return(
			<div>
				<div style={{width:500, display:'flex', alignItems:'center', justifyContent:'center', flex:'1', margin:'0 auto' }}>
					<Card>
						<CardContent>
							<Typography variant='h6'>
								{ETVConstants.trans("my_details")}
							</Typography>

							<div style={{ marginTop: 10 }}>
								<TextField
									size="small"
									style={{ marginTop: 10 }}
									key="name"
									disabled={!isUsernameEditing}
									label="Username"
									value={this.state.myUsername}
									onChange={(e)=>{this.setState({myUsername:e.target.value})}}
								/>
								{isUsernameEditing?
									<Button	variant="contained"	onClick={(e)=>{this.submitNewUserName()}} size="small" style={{ marginLeft: 10 }}>{ETVConstants.trans("save")}</Button>
								:
									<Button	variant="contained"	onClick={(e)=>{this.setState({isUsernameEditing:!isUsernameEditing})}} size="small" style={{ marginLeft: 10 }}>{ETVConstants.trans("edit")}</Button>
								}
							</div>							

							<Divider style={{marginTop:20}}/>
							<TextField style={{marginTop:10}} key='fn' disabled={!this.state.edit} label={ETVConstants.trans("firstname")} fullWidth={true} value={this.state.firstname} onChange={(event)=>{this.setState({firstname:event.target.value})}} />
							<TextField style={{marginTop:10}} key='ln' disabled={!this.state.edit} label={ETVConstants.trans("lastname")} fullWidth={true} value={this.state.lastname} onChange={(event)=>{this.setState({lastname:event.target.value})}} />
							<TextField style={{marginTop:10}} key='cp' disabled={!this.state.edit} label={ETVConstants.trans("company")} fullWidth={true} value={this.state.company} onChange={(event)=>{this.setState({company:event.target.value})}} />
							<TextField style={{marginTop:10}} key='address1' disabled={!this.state.edit} label={ETVConstants.trans("address_line_1")} fullWidth={true} value={this.state.address1} onChange={(event)=>{this.setState({address1:event.target.value})}} />
							<TextField style={{marginTop:10}} key='address2' disabled={!this.state.edit} label={ETVConstants.trans("address_line_2")} fullWidth={true} value={this.state.address2} onChange={(event)=>{this.setState({address2:event.target.value})}} />
							<TextField style={{marginTop:10}} key='zip' disabled={!this.state.edit} label={ETVConstants.trans("zip")} fullWidth={true} value={this.state.zip} onChange={(event)=>{this.setState({zip:event.target.value})}} />
							<TextField style={{marginTop:10}} key='city' disabled={!this.state.edit} label={ETVConstants.trans("city")} fullWidth={true} value={this.state.city} onChange={(event)=>{this.setState({city:event.target.value})}} />
							<TextField style={{marginTop:10}} key='country' disabled={!this.state.edit} label={ETVConstants.trans("country")} fullWidth={true} value={this.state.country} onChange={(event)=>{this.setState({country:event.target.value})}} />
							<TextField style={{marginTop:10}} error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} key='phone' disabled={!this.state.edit} label={ETVConstants.trans("phone")} fullWidth={true} value={this.state.phone} onChange={(event)=>{this.setState({phone:event.target.value})}} />
							<TextField style={{marginTop:10}} key='url' disabled={!this.state.edit} label={ETVConstants.trans("url_optional")} fullWidth={true} value={this.state.url} onChange={(event)=>{this.setState({url:event.target.value})}} />
						</CardContent>
						<CardActions>
							<Button size='small' disabled={this.state.edit} variant='contained' color='primary' startIcon={<Edit/>} onClick={() => this.setState({edit:true})}>{ETVConstants.trans("edit")}</Button>
							<Button size='small' disabled={!this.state.edit} variant='contained' color='primary' startIcon={<Save/>} onClick={() => this.updateInfo()}>{ETVConstants.trans("save")}</Button>
						</CardActions>
					</Card>
				</div>

				<Dialog
					open={this.state.window==='2_fac_auth_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="alert-dialog-title">{ETVConstants.trans("2_factor_authentication")}</DialogTitle>
					<DialogContent>
						<FormControlLabel control={<Checkbox checked={this.state.otp_enabled} onChange={()=>{this.setState({otp_enabled:!this.state.otp_enabled})}} />} label={ETVConstants.trans("2_factor_authentication_enabled")} />
						<TextField autoComplete='off' error={!this.state.otp_phone_helper_ok} helperText={this.state.otp_phone_helper_ok?"":ETVConstants.trans("invalid_phone_number")} style={{marginTop:10}} fullWidth={true} size="small" required id="otp_phone" label={ETVConstants.trans("phone")} value={this.state.otp_phone} onChange={(e)=>{this.setState({otp_phone:e.target.value})}} onInput={()=>{this.setState({otp_phone_helper_ok:true})}} />
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.showWindow('2_fac_auth_div',false)}} color="primary">{ETVConstants.trans("close")}</Button>
						<Button disabled={ (this.state.otp_phone.trim().length===0 && this.state.otp_enabled) || this.state.otp_phone_helper.length>0} onClick={()=>{this.saveOTP()}} color="secondary" variant='contained'>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<MessageDialog type={this.state.info_type} open={this.state.window==='message_div'} onClose={this.closeInfoMessage} title={this.state.info_title} message={this.state.info_message} />

	   		</div>);
	}
}

export default EditUserAccount
