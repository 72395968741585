import React from 'react';
import {Button, CircularProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import BasicTable2 from "../tools/BasicTable2";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BuildIcon from '@mui/icons-material/Build';
import EditIO from './EditIO';
import EditFloorNames from './EditFloorNames';
import FloorSounds from './FloorSounds';
import FloorImages from './FloorImages';
import SettingsIcon from '@mui/icons-material/Settings';
import SensorIcon from '@mui/icons-material/Iso';
import GeneralElevatorConfig from './GeneralElevatorConfig';
import SelectAvailableScreensNew from '../contentmgmt/SelectAvailableScreensNew';
import DeviceAddress from './DeviceAddress';
import SkillsDefinition from './SkillsDefinition';
import ErrorHandling from './ErrorHandling';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DraginoConfig from './DraginoConfig';
import * as ETVConstants from '../ETVConstants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Elevator, Save } from '@mui/icons-material';
import LoadingDialog from '../tools/LoadingDialog';

class ElevatorMgmt extends React.Component {

	constructor(props) {
      	super(props);
		this.closeHook = this.closeHook.bind(this);
		this.selectedScreensHook = this.selectedScreensHook.bind(this);
		this.closeAddressFunction = this.closeAddressFunction.bind(this);
    }

    state = {
      	screen_items:[],
		elevator_items:[],
		dragino_items:[],
      	isLoaded: false,
		device_id: undefined,
		device_name:'',
		sw_version: 0,
		window:undefined,
		info_message:'',
		device_skills:[],
		available_skills:[],
		device_skills_changed:false,
		device_config:{},
		sensor_data:{},
		selected_screens:[],
    }


	componentDidUpdate() {
	    if(!this.state.isLoaded) {
        	this.loadItems();
      	}
	}

    componentDidMount() {
      	this.loadItems();
		this.loadAvailableSkills();
    }

    loadItems() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		//var url = ETVConstants.getServerBase()+"/device_mgmt?action=list&owner_id="+this.props.user_id;
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=list_all_devices&owner_id="+this.props.user_id+"&device_type=0&device_type=1&device_type=2";
		//console.log("url="+url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log("items="+result.list.length);
            this.setState({
              	screen_items: this.filterDeviceType(result.list,0),
				elevator_items: this.filterDeviceType(result.list,2),
				dragino_items: this.filterDeviceType(result.list,1),
              	isLoaded: true,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              	screen_items:[],
				elevator_items:[],
				dragino_items:[],
              	isLoaded: false,
              	error
            });
          }
        )
    }

	filterDeviceType( items, deviceType) {
		var result = [];
		for(var i=0; i<items.length; i++) {
			if(items[i].device_type===deviceType) {
				result.push(items[i]);
			}
		}
		return result;
	}

    showWindow(divID, show) {
      show?this.setState({window:divID}):this.setState({window:''});
    }

	showInfoMessage(msg) {
		this.setState({window:'message_div',info_message:msg});
	}

	closeInfoMessage(msg) {
		this.setState({window:'',info_message:''});
	}

    getItem( deviceID) {
      for(var i=0; i<this.state.screen_items.length; i++) {
        if(this.state.screen_items[i].id===deviceID) {
          return this.state.screen_items[i];
        }
      }
      return undefined;
    }

	editIO( deviceID, swVersion) {
		this.showWindow("edit_io_div",true);
		this.setState({device_id:deviceID, sw_version:swVersion});
	}

	editFloorNames( deviceID, swVersion) {
		this.showWindow("floor_name_div",true);
		this.setState({device_id:deviceID, sw_version:swVersion});
	}

	editSounds(deviceName, deviceID, swVersion) {
		this.showWindow("floor_sounds_div",true);
		this.setState({device_name:deviceName, device_id:deviceID, sw_version:swVersion});
	}

	editFloorImages( deviceID, swVersion) {
		this.showWindow("floor_images_div",true);
		this.setState({device_id:deviceID, sw_version:swVersion});
	}

	editGeneralConfig( deviceID, swVersion) {
		this.showWindow("general_elevator_config_div",true);
		this.setState({device_id:deviceID, sw_version:swVersion});
	}

	copyConfigView(deviceID) {
		this.showWindow("copy_config_view",true);
		this.setState({device_id:deviceID,selected_screens:[]});
	}

	closeHook() {
		this.showWindow("copy_config_view",false);
	}

	selectedScreensHook( screens) {
		if(!screens || screens.length===0) {
			this.setState({selected_screens:[]})
			return;
		}
		this.setState({selected_screens:screens});
		//console.log("selected_screens="+screens);
		this.showWindow("copy_config_view",false);
		this.showInfoMessage('copy config ... ');
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/elevator_config?action=copy_config&device_id="+this.state.device_id;
		for(var i=0; i<screens.length; i++) {
			url += "&target_device="+screens[i];
		}
		//console.log(url);
	    fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
        .then(res => res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result));
						this.showInfoMessage("success");
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
						this.showInfoMessage("error");
						//console.log("error");
          }
        )
    }

	deleteDevice( deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=delete_device&device_id="+deviceID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						isLoaded: false,
						showDeleteConfirmDialog: false,
						deleteDeviceID: '',
					});
				},
				(error) => {
				}
			)
	}

	checkCanSave() {
		//console.log("check can save");
		if(!this.state.new_device_id || this.state.new_device_id.length===0) {
			this.setState({canSave:false,txt_error_info:'device id required'});
			return false;
		}
		if(!this.state.new_device_name || this.state.new_device_name.length===0) {
			this.setState({canSave:false,txt_error_info:'device name required'});
			return false;
		}
		this.setState({canSave:true,txt_error_info:''});
		return true;
	}

	closeAddressFunction(needReload) {
		this.showWindow("xxx",false);
		if(needReload) {
			this.setState({isLoaded:false,selected_device:''});
		}
	}

	editElevator( dev) {
		this.setState({selected_device:dev});
		this.showWindow('add_offline_elevator_div',true);
	}

	editDragino( dev) {
		this.setState({selected_device:dev});
		this.showWindow('add_dragino_div',true);
	}

////////////////////////////////////////////////////////////////////////////////
// load skills
////////////////////////////////////////////////////////////////////////////////

	loadAvailableSkills() {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var urlStr = ETVConstants.getServerBase()+"/useradmin?action=get_skills&user_id="+this.props.user_id;
		fetch(urlStr,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
		.then(res => res.json())
		.then(
			(result) => {
				//console.log("userID="+this.props.user_id+" skills available="+JSON.stringify(result));
				this.setState({
					available_skills: result.skills,
				});
			},
			(error) => {
				this.setState({
					available_skills: [],
					error
				});
			}
		)
	}

	loadDeviceSkills(deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=get_skills&device_id="+deviceID;
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(JSON.stringify(result));
					this.setState({device_skills:result.skills});
				},
				(error) => {
					//console.log(error);
				}
			)
	}

	showRequiredDeviceSkills( deviceID) {
		this.setState({device_id:deviceID,device_skills_changed:false});
		this.showWindow("device_skills_div",true);
		this.loadDeviceSkills(deviceID);
	}

	showSensorData( deviceID) {
		this.setState({device_id:deviceID});
		this.showWindow("sensor_data_div",true);
		this.loadSensorData(deviceID);
	}

	formatLocalDate( time) {
		if(!time) return ""; //happens at startup
		var d = new Date(time.replace(/-/g, "/"));
		return d.toLocaleString();
	}

	createTableRow(key,value) {
		//console.log("key="+key+" val="+value);
		return (<TableRow>
						<TableCell><Typography color='primary'>{key}</Typography></TableCell>
						<TableCell><Typography color='primary'>{value}</Typography></TableCell>
						</TableRow>)
	}

	printSensorData() {
		if(!this.state.sensor_data) return (<span>no data available</span>);
		//var deviceID = this.state.sensor_data["device_id"];
		var dateStr = this.formatLocalDate(this.state.sensor_data["last_update"]);
		var data = this.state.sensor_data["data"];
		if(!data) return (<span>no data available</span>)
		return(
			<div>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 60 }}>
						<TableBody>
							<TableRow>
								<TableCell><Typography color='secondary'>last update</Typography></TableCell>
								<TableCell><Typography color='secondary'>{dateStr}</Typography></TableCell>
							</TableRow>
							{Object.entries(data).map( ([key,value]) => { return( this.createTableRow(key,value)) })}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		)
	}

	loadSensorData(deviceID) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		var url = ETVConstants.getServerBase()+"/dragino?action=get_sensor_data&device_id="+deviceID;
		//console.log(url);
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then(res => res.json())
			.then(
				(result) => {
					//console.log(JSON.stringify(result));
					this.setState({sensor_data:result});
				},
				(error) => {
					//console.log(error);
				}
			)
	}

	showDeviceConfig( deviceID) {
		this.setState({device_id:deviceID});
		this.showWindow("device_config_div",true);
	}

	handleCheckBoxChange( item ) {
		var curSkills = this.state.device_skills;
		if(curSkills.includes(item)) {
				var index = curSkills.indexOf(item);
				curSkills.splice(index,1);
		}else{
			curSkills.push(item);
		}
		this.setState({device_skills_changed:true,device_skills:curSkills});
	}

	printRequiredDeviceSkills() {
		if(!this.state.device_id) return <span/>;
		if(!this.state.device_skills) return <span>no skills</span>;
		return (
			this.state.available_skills.map( (item,index)=> {return <FormControlLabel id={index} key={index} control={<Checkbox checked={this.hasSkill(item)} onClick={()=>this.handleCheckBoxChange(item)}>{item}</Checkbox>} name={item} label={item} />})
		)
	}

	hasSkill( skill) {
		return this.state.device_skills.includes(skill);
	}

	saveDeviceSkills() {
		var url = ETVConstants.getServerBase()+"/device_mgmt?action=set_skills&device_id="+this.state.device_id;
		for(var i=0; i<this.state.device_skills.length; i++) {
			url += "&skill="+encodeURIComponent(this.state.device_skills[i]);
		}
		this.sendCMDToServer(url);
		this.setState({device_skills_changed:false});
	}

	sendCMDToServer(url) {
		var accessToken = localStorage.getItem("etv.access_token");
		var reqUserID = localStorage.getItem("etv.user_id");
		fetch(url,{ headers: { 'user_id':reqUserID, 'access_token': accessToken }})
			.then((response) => response.json())
			.then((responseJson) => {
					this.setState({
						isLoaded: false
					})
			})
			.catch((error) => {
					console.error(error);
			});
	}

////////////////////////////////////////////////////////////////////////////////
// error handling
////////////////////////////////////////////////////////////////////////////////

	showErrorHandling( deviceID) {
		this.setState({device_id:deviceID});
		this.showWindow("error_handling_div",true);
	}


////////////////////////////////////////////////////////////////////////////////
//
////////////////////////////////////////////////////////////////////////////////

    render() {

		if(!this.state.isLoaded) {
			return <LoadingDialog open={!this.state.isLoaded} message={ETVConstants.trans("loading")}/>
		}

	    const columnsDataScreen= [
		  	{ title: 'Last connect', field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
          	{ title: 'Device Name', field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>) },
			{ title: 'General Config', field: 'config', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editGeneralConfig(rowData.id, rowData.sw_version)}><SettingsIcon/></IconButton>},
			{ title: 'I/O Config', field: 'define_io', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editIO(rowData.id, rowData.sw_version )}><SettingsInputComponentIcon/></IconButton>},
			{ title: 'Floor Mapping', field: 'define_floors', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editFloorNames(rowData.id, rowData.sw_version)}><ApartmentIcon/></IconButton>},
			{ title: 'Sounds Config', field: 'define_sounds', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editSounds(rowData.device_name, rowData.id, rowData.sw_version)}><VolumeDownIcon/></IconButton>},
			{ title: 'Floor Images', field: 'define_floor_img', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editFloorImages(rowData.id, rowData.sw_version)}><ImageIcon/></IconButton>},
			{ title: 'skills', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showRequiredDeviceSkills(rowData.id)}><BuildIcon/></IconButton>},
			{ title: 'error handling', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showErrorHandling(rowData.id)}><EditIcon/></IconButton>},
			{ title: 'copy', field: '', render: rowData => <Button variant='contained' color='primary' onClick={()=>this.copyConfigView(rowData.id)}>copy</Button>},
        ];

		const columnsDataDragino= [
			{ title: 'Last connect', field: 'last_connected', render: rowData => (<div style={{width: "15px", maxWidth:"30px", paddingLeft: "3px"}}> {ETVConstants.getLastConnectSymbol(rowData.last_connected,rowData.startup_datetime,rowData.status)} </div>) },
			{ title: 'Device_EUI', field: 'id'},
			{ title: 'Device Name', field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>) },
			{ title: 'Building', field: 'location_name'},
			{ title: 'Config', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showDeviceConfig(rowData.id)}><SettingsIcon/></IconButton>},
			{ title: 'sensor', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showSensorData(rowData.id)}><SensorIcon/></IconButton>},
			{ title: 'skills', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showRequiredDeviceSkills(rowData.id)}><BuildIcon/></IconButton>},
			{ title: 'edit', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editDragino(rowData)}><EditIcon/></IconButton>},
			{ title: 'delete', field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.deleteDevice(rowData.id)}><DeleteIcon/></IconButton>}
		];

		const columnsDataElevator= [
			{ title: 'Device_ID', field: 'id'},
			{ title: 'Device Name', field: 'device_name', render: rowData => (<div style={{minWidth: "200px", paddingLeft: "10px"}}> {rowData.device_name} </div>) },
			{ title: 'Building', field: 'location_name'},
			{ title: 'Address', field: 'address'},
			{ title: 'Zip', field: 'zip'},
			{ title: 'City', field: 'city'},
			{ title: 'skills', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.showRequiredDeviceSkills(rowData.id)}><BuildIcon/></IconButton>},
			{ title: 'edit', field: '', render: rowData => <IconButton variant='contained' color='primary' onClick={()=>this.editElevator(rowData)}><EditIcon/></IconButton>},
			{ title: 'delete', field: '', render: rowData => <IconButton variant='contained' color='secondary' onClick={()=>this.deleteDevice(rowData.id)}><DeleteIcon/></IconButton>}
		];


      return(
			<div>
				<Button style={{marginTop:20}} variant='contained' startIcon={<BuildIcon />} color='primary' onClick={()=>this.showWindow('define_skills',true)}>Define Skills</Button>&nbsp;&nbsp;&nbsp;
				<Button style={{marginTop:20}} variant='contained' color='primary' onClick={()=>this.showWindow('add_dragino_div',true)}>Add Dragino LT-22222-L</Button>&nbsp;&nbsp;&nbsp;
				<Button style={{marginTop:20}} variant='contained' startIcon={<Elevator/>} color='primary' onClick={()=>this.showWindow('add_offline_elevator_div',true)}>Add Elevator</Button>&nbsp;&nbsp;&nbsp;
				<Divider style={{marginTop:20}}/>
				<div style={{marginTop:20}}>
					<BasicTable2 data={this.state.screen_items} columns={columnsDataScreen} title={'Elevator screens'} pageSize={20} allow_search={true} />
				</div>

				<div style={{marginTop:20}}>
					<BasicTable2 data={this.state.dragino_items} columns={columnsDataDragino} title={'Dragino LT-22222-L'} pageSize={5} allow_search={true} />
				</div>

				<div style={{marginTop:20}}>
					<BasicTable2 data={this.state.elevator_items} columns={columnsDataElevator} title={'Offline Elevators'} pageSize={5} allow_search={true} />
				</div>


				<Dialog
					open={this.state.window==='add_dragino_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={() => this.showWindow('add_dragino_div',false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{this.state.selected_device?"Edit Dragino":"Add new Dragino LT-22222-L"}</DialogTitle>
					<DialogContent>
						<DeviceAddress device={this.state.selected_device} closeF={this.closeAddressFunction} device_type={1} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('add_dragino_div',false)} color="primary">close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='add_offline_elevator_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={() => this.showWindow('add_offline_elevator_div',false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{this.state.selected_device?"edit elevator address":"add offline elevator"}</DialogTitle>
					<DialogContent>
									<DeviceAddress device={this.state.selected_device} closeF={this.closeAddressFunction} device_type={2} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('add_offline_elevator_div',false)} color="primary">close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='edit_io_div'}
					maxWidth='sm'
					fullWidth={true}
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Edit IO</DialogTitle>
					<DialogContent>
						{this.state.window==='edit_io_div'?<EditIO device_id={this.state.device_id} sw_version={this.state.sw_version} />:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('edit_io_div',false)} color="primary">Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='copy_config_view'}
					maxWidth='lg'
					fullWidth={false}
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Copy Configuration</DialogTitle>
					<DialogContent>
							{(this.state.window==='copy_config_view')?<SelectAvailableScreensNew isOpen={this.state.window==='copy_config_view'} user_id={this.props.user_id} closeHook={this.closeHook} excluded_screen_ids={[this.state.device_id]} selectedScreens={this.selectedScreensHook} selected={this.state.selected_screens?this.state.selected_screens:[]}/>:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('copy_config_view',false)} color="primary">Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='floor_name_div'}
					maxWidth='sm'
					fullWidth
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Edit Floor Names</DialogTitle>
					<DialogContent>
							{this.state.window==='floor_name_div'?<EditFloorNames device_id={this.state.device_id} />:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('floor_name_div',false)} color="primary">Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='general_elevator_config_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle>{ETVConstants.trans("configuration")}</DialogTitle>
					<DialogContent>
							{this.state.window==='general_elevator_config_div'?<GeneralElevatorConfig device_id={this.state.device_id} sw_version={this.state.sw_version} />:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('general_elevator_config_div',false)} color="primary">Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='floor_sounds_div'}
					maxWidth='lg'
					fullWidth={false}
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Manage Floor Sounds</DialogTitle>
					<DialogContent>
							{this.state.window==='floor_sounds_div'?<FloorSounds device_name={this.state.device_name} device_id={this.state.device_id} />:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('floor_sounds_div',false)} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='floor_images_div'}
					maxWidth='lg'
					fullWidth={false}
					onClose={() => {}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Manage Floor Images</DialogTitle>
					<DialogContent>
							{this.state.window==='floor_images_div'?<FloorImages device_id={this.state.device_id} />:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.showWindow('floor_images_div',false)} color="primary">Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='message_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{this.setState({window:''})}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title"></DialogTitle>
					<DialogContent>
							<Typography variant="body1">{this.state.info_message}</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='define_skills'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="Skillsdefinition">{ETVConstants.trans("define_skills")}</DialogTitle>
					<DialogContent>
						<SkillsDefinition user_id={this.props.user_id} />
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('define_skills',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='sensor_data_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="sensor_data_id">SensorData</DialogTitle>
					<DialogContent>
								{this.printSensorData()}
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('sensor_data_div',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='device_skills_div'}
					maxWidth='xs'
					fullWidth
					onClose={()=>{}}>
					<DialogTitle id="skills">{ETVConstants.trans("required_device_skillset")}</DialogTitle>
					<DialogContent>
						{this.printRequiredDeviceSkills()}
					</DialogContent>
					<DialogActions>
						<Button size="small" variant='text' color="primary" onClick={() => this.showWindow('skills_div',false)}>{ETVConstants.trans("cancel")}</Button>
						<Button disabled={!this.state.device_skills_changed} size="small" variant="contained" color="primary" startIcon={<Save/>} onClick={() => this.saveDeviceSkills()}>{ETVConstants.trans("save")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='device_config_div'}
					maxWidth='sm'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="skills">{ETVConstants.trans("alert_configuration")}</DialogTitle>
					<DialogContent>
						<DraginoConfig device_id={this.state.device_id} />
					</DialogContent>
					<DialogActions>
						<Button size="small" variant='outlined' color="primary" onClick={() => this.showWindow('device_config_div',false)}>{ETVConstants.trans("cancel")}</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={this.state.window==='error_handling_div'}
					maxWidth='md'
					fullWidth={true}
					onClose={()=>{}}>
					<DialogTitle id="Error_handling_div">{ETVConstants.trans("error_handling")}</DialogTitle>
					<DialogContent>
						<ErrorHandling device_id={this.state.device_id} user_id={this.props.user_id} />
					</DialogContent>
					<DialogActions>
						<Button size="small" color="primary" onClick={() => this.showWindow('error_handling_div',false)}>{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

	</div>
	);
	}
}

export default ElevatorMgmt
