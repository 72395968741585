import React, { Component } from 'react';
import axios from 'axios';
import {
  List,
  Grid,
  CircularProgress,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import * as ETVConstants from '../ETVConstants.js';
import { Download } from '@mui/icons-material';

let hasMounted = false;

class CanvaDesignsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: props.accessToken, // Access token passed as a prop
      designs: [],
      loading: true,
      error: null,
      downloading: false, // Track if a download is in progress
      selected_filename: '',
      selected_filetype:'',
      selected_content_ratio:'',
      window:'',
      progress_message:null,
    };
  }

  componentDidMount() {
    if (hasMounted) {
      return;
    }
    hasMounted = true;
    this.fetchDesigns();
  }

  async fetchDesigns() {
    this.showProgress("loading designs...");
    const { accessToken } = this.state;
    try {
        const response = await axios.get( ETVConstants.getServerBase() + '/canva-designs',{headers: {Authorization: `Bearer ${accessToken}`, },});
        const loadedDesigns = response.data.items;
        //console.log(JSON.stringify(loadedDesigns));
        this.setState({
          designs: loadedDesigns,
          loading: false,
          window:'',
        });
    } catch (error) {
        this.showInfoMessage("error","error: "+error);
        this.setState({
          error: error.response?.data || error.message || 'Failed to fetch designs',
          loading: false,
        });
    }
  }

  createSafeFilename(unsafe, filetype) {
    if (!unsafe || typeof unsafe !== 'string') {
      throw new Error('Invalid input: A string is required.');
    }
    let safeFilename = unsafe.trim();
    safeFilename = safeFilename.replace(/[\\/:*?"<>| ]+/g, '_');
    safeFilename = safeFilename.replace(/[._]+$/, '');
    if (!safeFilename) {
      throw new Error('Invalid input: Filename cannot be empty after cleaning.');
    }
    return safeFilename + '.' + filetype;
  }

  successDownload(url, thumbnailUrl) {
    this.setState({ downloading: false });
    this.props.exportSuccess(url, thumbnailUrl, this.state.selected_filename, this.state.selected_filetype, this.state.selected_content_ratio);
  }

  failedDownload(reason) {
    this.setState({ downloading: false });
    this.props.exportFailed(reason);
  }

  calculateAspectRatio(width, height) {
    if (width <= 0 || height <= 0) {
      return 0;
    }
    const ratio = width / height;
    return Math.round(ratio * 100) / 100;
  }

  downloadDesign(design, filetype) {
    this.showProgress("downloading design");
    this.setState({
      downloading: true,
      selected_filename: this.createSafeFilename(design.title, filetype),
      selected_filetype: filetype,
      selected_content_ratio: this.calculateAspectRatio(design.thumbnail.width,design.thumbnail.height),
    });

    const reqUserID = localStorage.getItem('etv.user_id');
    const baseUrl = ETVConstants.getServerBase();
    const accessToken = this.state.accessToken;
    const urlStr = `${baseUrl}/canva-download-design?token=${accessToken}&design_id=${design.id}&export_format=${filetype}`;
    //console.log('Initiating design export job: ', urlStr);

    fetch(urlStr, { headers: { user_id: reqUserID } })
      .then((res) => res.json())
      .then((result) => {
        if (result.job) {
          const jobId = result.job.id;
          const checkJobStatus = setInterval(() => {
            const statusUrl = `${baseUrl}/canva-download-design?export_id=${jobId}&token=${accessToken}`;
            this.showProgress("getting status ");
            fetch(statusUrl, { headers: { user_id: reqUserID } })
              .then((res) => res.json())
              .then((statusResult) => {
                if (statusResult.job) {
                  const jobStatus = statusResult.job.status;

                  if (jobStatus === 'success') {
                    const downloadUrl = statusResult.job.urls[0];
                    clearInterval(checkJobStatus);
                    hasMounted = false;
                    this.successDownload(downloadUrl, design.thumbnail?design.thumbnail.url:null);
                  } else if (jobStatus === 'failed') {
                    clearInterval(checkJobStatus);
                    hasMounted = false;
                    this.failedDownload(statusResult.job.error);
                  }
                }
              })
              .catch((err) => {
                this.showInfoMessage("error","failed to download: "+err);
                clearInterval(checkJobStatus);
              });
          }, 1000);
        }
      })
      .catch((error) => {
        this.setState({ downloading: false });
        //console.error('Error initiating export job: ', error);
      });
  }

  closeWindow() {
    hasMounted = false;
    this.props.closedCallback();
  }

  showProgress( message) {
		this.state.window==='progress_dialog'? this.setState({progress_message:message}):
		this.setState({window:'progress_dialog', progress_message:message});
	}

  showInfoMessage( title, message) {
    this.setState({window:'message_div',info_title:title, info_message:message});
  }

  render() {
    const { designs, loading, error, downloading } = this.state;
    if(loading) {
      return <span/>
    }

    if (error) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleRetry}>
            Retry
          </Button>
        </div>
      );
    }

    return (
        <div style={{ position: 'relative', minHeight: '100vh', paddingBottom: '80px', display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={0} style={{ rowGap: '15px', flex: '1 0 auto' }}>
            {designs.map((design) => (
              <Grid item xs={12} sm={5} md={4} key={design.id}>
                <Card style={{ width: '350px', margin: 'auto' }}>
                  <CardMedia
                    component="img"
                    style={{ width: '100%', height: 'auto' }}
                    image={design.thumbnail ? design.thumbnail.url : ""}
                    alt={design.title || 'Design Thumbnail'}
                  />
                  <CardContent>
                    <Typography variant="h6"  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>{design.title || 'Unnamed Design'}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created at: {new Date(design.created_at * 1000).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Updated at: {new Date(design.updated_at * 1000).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Pages: {design.page_count}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      startIcon={<Download />}
                      size="small"
                      color="primary"
                      onClick={() => this.downloadDesign(design, 'jpg')}
                    >
                      JPG
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Download />}
                      size="small"
                      color="primary"
                      onClick={() => this.downloadDesign(design, 'png')}
                    >
                      PNG
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Download />}
                      size="small"
                      color="primary"
                      onClick={() => this.downloadDesign(design, 'mp4')}
                    >
                      MP4
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
    
          {/* Closing List Button */}
          <div style={{ alignSelf: 'flex-end', marginRight: '20px' }}>
            <Button size="large" variant="text" color="primary" onClick={()=>{this.closeWindow()}}>{ETVConstants.trans("close")}</Button>
          </div>

          <Dialog
              open={this.state.window==='progress_dialog'}
              maxWidth='xs'
              fullWidth={false}
              onClose={() => {} }
              aria-labelledby="progress-dialog-title"
              aria-describedby="progress-dialog-description">
              <DialogContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  textAlign: 'center', // Align text properly
                }}
              >
              <div>
                <CircularProgress size={80} />
              </div>
              <div style={{ width:150, marginTop: '20px' }}>
                <Typography variant="body1">
                {this.state.progress_message || "Loading..."}
                </Typography>
              </div>
              </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.window==='message_div'}
            maxWidth='xs'
            fullWidth={true}
            onClose={()=>{this.setState({window:''})}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{this.state.info_message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.closeInfoMessage()}} color="primary">{ETVConstants.trans("close")}</Button>
            </DialogActions>
          </Dialog>

        </div>
     );
    }

}

export default CanvaDesignsList;
