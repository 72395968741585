// pkceUtils.js
export async function generatePKCEPair() {
  const codeVerifier = generateRandomCodeVerifier(); // e.g., 96 characters
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  return { codeVerifier, codeChallenge };
}

async function generateCodeChallenge(codeVerifier) {
  // TextEncoder to turn the string into bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);

  try {
    // 1. SHA-256 hash (returns a Promise)
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);

    // 2. Convert ArrayBuffer to a byte array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // 3. Convert bytes to base64
    let base64 = btoa(String.fromCharCode(...hashArray));

    // 4. Turn base64 into base64url by replacing +, /, and removing =
    base64 = base64
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    return base64;
  } catch (error) {
    //alert(error);
    console.error("Error generating code challenge:", error);
    throw error;
  }
}

function generateRandomCodeVerifier(length = 96) {
  // Create a typed array of `length` bytes
  const array = new Uint8Array(length);
  // Fill it with random values from the browser's crypto
  window.crypto.getRandomValues(array);

  // Convert each byte to a character from [A-Za-z0-9-._~].
  // Or you can convert to base64url if you prefer.
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  let codeVerifier = "";
  for (let i = 0; i < length; i++) {
    codeVerifier += allowedChars[array[i] % allowedChars.length];
  }

  return codeVerifier;
}

export function isValidCodeVerifier(codeVerifier) {
  // Length check
  if (codeVerifier.length < 43 || codeVerifier.length > 128) {
      return false;
  }
  
  // Character set check
  const pattern = /^[A-Za-z0-9\-._~]+$/;
  return pattern.test(codeVerifier);
}

